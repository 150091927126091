import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

interface LoadingSkeletonProps {
  count: number;
}

const LoadingSkeleton: React.FC<LoadingSkeletonProps> = ({ count }) => {
  return (
    <div className="space-y-2">
      {Array.from({ length: count }).map((_, index) => (
        <div key={index} className="flex items-center justify-between p-4 bg-white border border-gray-200 rounded-lg">
          <div className="flex items-center space-x-4">
            <Skeleton circle={true} height={20} width={20} />
            <Skeleton width={150} />
          </div>
          <Skeleton width={100} />
          <div className="flex items-center space-x-2">
            <Skeleton width={80} />
            <Skeleton circle={true} height={24} width={24} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default LoadingSkeleton;