import { supabase } from '../supabase';

export const invokeInviteRPC = async (inviteCode: string, userId: string) => {
  const { data, error } = await supabase.rpc('use_invite', {
    invite_code: inviteCode,
    user_id: userId
  });

  if (error) {
    throw new Error(error.message);
  }

  return data;
};

export const fetchInvites = async (groupId: string) => {
  const { data, error } = await supabase
    .from('invites')
    .select('*')
    .eq('organization_id', groupId);

  if (error) {
    throw new Error(error.message);
  }

  return data;
};

export const deleteInvite = async (inviteCode: string) => {
  const { data, error } = await supabase.rpc('delete_invite', {
    invite_code: inviteCode
  });

  if (error) {
    throw new Error(error.message);
  }

  return data;
};

export const createOrganization = async (userId: string, orgName: string) => {
  try {
    const { data, error } = await supabase
      .rpc('create_organization', {
        p_user_id: userId,
        p_org_name: orgName
      });

    if (error) throw error;

    console.log('New organization created with ID:', data);
    return data;
  } catch (error) {
    console.error('Error creating organization:', error);
    throw error;
  }
}