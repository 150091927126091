import React, { useState, useRef, useEffect } from 'react';
import { moveApplicantsStageBulk, deleteApplicants } from '../../../Contexts/supabaseRecruitment';
import { BiEdit, BiInfoCircle } from 'react-icons/bi';
import { FaEllipsisV } from 'react-icons/fa';
import ReviewPopup from './ReviewPopup'; 
import ApplicantDetailPopup from './ApplicantDetailPopup';

interface Applicant {
  id: number;
  full_name: string;
  major?: string;
  stage: string;
}

interface ListViewProps {
  applicants: Applicant[];
  onApplicantsUpdated: () => void;
  reviewType?: 'applicant' | 'interview';
  reviewerId: string;
}

const ListView: React.FC<ListViewProps> = ({ applicants, onApplicantsUpdated, reviewType, reviewerId }) => {
  const [selectedApplicants, setSelectedApplicants] = useState<number[]>([]);
  const [isCheckdownOpen, setIsCheckdownOpen] = useState(false);
  const [isReviewPopupOpen, setIsReviewPopupOpen] = useState(false);
  const [selectedApplicantId, setSelectedApplicantId] = useState<number | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isDetailPopupOpen, setIsDetailPopupOpen] = useState(false);
  const [selectedDetailApplicantId, setSelectedDetailApplicantId] = useState<number | null>(null);

  const handleSelectAll = () => {
    setSelectedApplicants(applicants.map(applicant => applicant.id));
  };

  const handleDeselectAll = () => {
    setSelectedApplicants([]);
  };

  const handleInvertSelection = () => {
    setSelectedApplicants(prevSelected => 
      applicants
        .filter(applicant => !prevSelected.includes(applicant.id))
        .map(applicant => applicant.id)
    );
  };

  const handleSelectApplicant = (applicantId: number) => {
    setSelectedApplicants(prevSelected => 
      prevSelected.includes(applicantId)
        ? prevSelected.filter(id => id !== applicantId)
        : [...prevSelected, applicantId]
    );
  };

  const toggleCheckdown = () => {
    setIsCheckdownOpen(!isCheckdownOpen);
  };

  const handleReviewClick = (applicantId: number) => {
    setSelectedApplicantId(applicantId);
    setIsReviewPopupOpen(true);
  };

  const closeReviewPopup = () => {
    setIsReviewPopupOpen(false);
    setSelectedApplicantId(null);
  };

  const handleMoveApplicants = async (newStage: string) => {
    try {
      await moveApplicantsStageBulk(selectedApplicants, newStage);
      onApplicantsUpdated();
      setSelectedApplicants([]);
    } catch (error) {
      console.error('Error moving applicants:', error);
    }
  };

  const handleDeleteApplicants = async () => {
    if (window.confirm('Are you sure you want to delete the selected applicants?')) {
      try {
        await deleteApplicants(selectedApplicants);
        onApplicantsUpdated();
        setSelectedApplicants([]);
      } catch (error) {
        console.error('Error deleting applicants:', error);
      }
    }
  };

  const handleApplicantClick = (applicantId: number) => {
    setSelectedDetailApplicantId(applicantId);
    setIsDetailPopupOpen(true);
  };

  const closeDetailPopup = () => {
    setIsDetailPopupOpen(false);
    setSelectedDetailApplicantId(null);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsCheckdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="space-y-4">
      <div className="grid grid-cols-12 gap-4 px-4 py-2 text-sm font-medium text-gray-600 bg-gray-200 rounded-md">
        <div className="relative flex items-center col-span-1 space-x-2">
          <input
            type="checkbox"
            className="w-5 h-5 text-blue-600 form-checkbox"
            checked={selectedApplicants.length === applicants.length}
            onChange={(e) => e.target.checked ? handleSelectAll() : handleDeselectAll()}
          />
          <button
            onClick={toggleCheckdown}
            className="p-1 transition-colors rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <FaEllipsisV />
          </button>
          {isCheckdownOpen && (
            <div ref={dropdownRef} className="absolute left-0 z-10 w-48 mt-1 bg-white rounded-md shadow-lg top-full">
              <button onClick={handleSelectAll} className="w-full px-4 py-2 text-left transition-colors hover:bg-gray-100">Select All</button>
              <button onClick={handleDeselectAll} className="w-full px-4 py-2 text-left transition-colors hover:bg-gray-100">Deselect All</button>
              <button onClick={handleInvertSelection} className="w-full px-4 py-2 text-left transition-colors hover:bg-gray-100">Invert Selection</button>
              <hr className="my-2 border-gray-200" />
              <button onClick={() => handleMoveApplicants('interest')} className="w-full px-4 py-2 text-left transition-colors hover:bg-gray-100">Move to Interest</button>
              <button onClick={() => handleMoveApplicants('applicant')} className="w-full px-4 py-2 text-left transition-colors hover:bg-gray-100">Move to Applicant</button>
              <button onClick={() => handleMoveApplicants('interview')} className="w-full px-4 py-2 text-left transition-colors hover:bg-gray-100">Move to Interview</button>
              <button onClick={() => handleMoveApplicants('decision')} className="w-full px-4 py-2 text-left transition-colors hover:bg-gray-100">Move to Decision</button>
              <button onClick={() => handleMoveApplicants('accepted')} className="w-full px-4 py-2 text-left transition-colors hover:bg-gray-100">Move to Accepted</button>
              <hr className="my-2 border-gray-200" />
              <button onClick={handleDeleteApplicants} className="w-full px-4 py-2 text-left transition-colors hover:bg-gray-100">Delete Selected</button>
            </div>
          )}
        </div>
        <div className="col-span-4">Name</div>
        <div className="col-span-3">Major</div>
        <div className="col-span-2">Stage</div>
        <div className="col-span-2 text-center">Actions</div>
      </div>
      {applicants.map((applicant) => (
        <div key={applicant.id} className="grid items-center grid-cols-12 gap-4 px-4 py-3 transition-colors duration-200 bg-white border-b border-gray-200 hover:bg-gray-50">
          <div className="col-span-1" onClick={(e) => e.stopPropagation()}>
            <input
              type="checkbox"
              className="w-5 h-5 text-blue-600 rounded form-checkbox"
              checked={selectedApplicants.includes(applicant.id)}
              onChange={() => handleSelectApplicant(applicant.id)}
            />
          </div>
          <div className="col-span-4 font-medium">{applicant.full_name}</div>
          <div className="col-span-3">{applicant.major}</div>
          <div className="col-span-2">
            <span className="px-2 py-1 text-sm font-medium text-green-800 bg-green-100 rounded-md">
              {applicant.stage}
            </span></div>
          <div className="flex justify-center col-span-2 space-x-2">
            {reviewType && (
              <button
                className="p-1 transition-colors duration-200 rounded-full hover:bg-gray-100"
                onClick={(e) => {
                  e.stopPropagation();
                  handleReviewClick(applicant.id);
                }}
              >
                <BiEdit className="text-lg" />
              </button>
            )}
            <button
              className="p-1 transition-colors duration-200 rounded-full hover:bg-white"
              onClick={(e) => {
                e.stopPropagation();
                handleApplicantClick(applicant.id);
              }}
            >
              <BiInfoCircle className="text-lg" />
            </button>
          </div>
        </div>
      ))}
      {selectedApplicantId && reviewType && (
        <ReviewPopup
          isOpen={isReviewPopupOpen}
          onClose={closeReviewPopup}
          applicantId={selectedApplicantId}
          reviewerId={reviewerId}
          reviewType={reviewType}
        />
      )}
      {selectedDetailApplicantId && (
        <ApplicantDetailPopup
          isOpen={isDetailPopupOpen}
          onClose={closeDetailPopup}
          applicantId={selectedDetailApplicantId}
        />
      )}
    </div>
  );
};

export default ListView;