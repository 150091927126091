import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../Contexts/AuthContext';
import { supabase } from '../../../supabase';
import { useParams } from 'react-router-dom';
import { postData } from '../../../Contexts/edgeApi';
import BillingInfo from './OrganizationSettings/BillingInfo';

const BillingAccounts: React.FC = () => {
  const { user } = useAuth();
  const { groupId } = useParams<{ groupId: string }>();
  const [onboardingCompleted, setOnboardingCompleted] = useState(false);
  const [providerAccountId, setProviderAccountId] = useState('');
  const [billingAccounts, setBillingAccounts] = useState<any[]>([]);

  useEffect(() => {
    const fetchBillingAccounts = async () => {
        if (!user) return;
  
        try {
          const { data: accounts, error: accountError } = await supabase
            .from('billingaccounts')
            .select('*')
            .eq('organization_id', groupId);
  
          if (accountError) {
            throw accountError;
          }
  
          if (accounts.length > 0) {
            setBillingAccounts(accounts);
            const stripeAccount = accounts.find(account => account.provider === 'Stripe');
            if (stripeAccount) {
              setOnboardingCompleted(stripeAccount.onboarding_completed);
              setProviderAccountId(stripeAccount.provider_account_id);
            }
          }
        } catch (error: any) {
          console.error('Error fetching billing accounts:', error.message);
          alert('Failed to fetch billing accounts.');
        }
      };
      fetchBillingAccounts();
  }, [user, groupId]);

  const checkStripeOnboarding = async (providerAccountId: string) => {
    try {
      const data = await postData('stripe-onboard-check', { stripeAccountId: providerAccountId });
      return data.onboardingCompleted;
    } catch (error: any) {
      console.error('Error checking Stripe onboarding:', error);
      alert('Failed to check Stripe onboarding.');
      return false;
    }
  };

  const launchOnboardingFlow = async (providerAccountId: string) => {
    try {
      const data = await postData('create-stripe-login-link', { stripeAccountId: providerAccountId });
      window.location.href = data.url;
    } catch (error: any) {
      console.error('Error launching onboarding flow:', error);
      alert('Failed to launch onboarding flow.');
    }
  };

  const createStripeAccount = async () => {
    if (!user) {
      alert('You must be logged in to create a Stripe account.');
      return;
    }

    try {
      const data = await postData('create-stripe-account', { groupId });
      window.location.href = data.url;
    } catch (error: any) {
      console.error('Error creating Stripe account:', error);
      alert(`There was an error creating your Stripe account. ${error.message}`);
    }
  };

  const finishOnboarding = async () => {
    try {
      const onboardingStatus = await checkStripeOnboarding(providerAccountId);
      if (!onboardingStatus) {
        launchOnboardingFlow(providerAccountId);
      } else {
        setOnboardingCompleted(true);
      }
    } catch (error: any) {
      console.error('Error finishing onboarding:', error);
      alert('Failed to finish onboarding.');
    }
  };

  const handleLoginLink = async (providerAccountId: string) => {
    try {
      const data = await postData('create-stripe-login-link', { stripeAccountId: providerAccountId });
      window.location.href = data.url;
    } catch (error: any) {
      console.error('Error creating login link:', error);
      alert('Failed to create login link.');
    }
  };

  return (
    <BillingInfo
        onboardingCompleted={onboardingCompleted}
        providerAccountId={providerAccountId}
        billingAccounts={billingAccounts}
        finishOnboarding={finishOnboarding}
        createStripeAccount={createStripeAccount}
        handleLoginLink={handleLoginLink}
      />
  );
};

export default BillingAccounts;
