import React, { useState, useEffect } from 'react';
import { FaCopy, FaDownload } from 'react-icons/fa';
import { supabase } from '../../supabase';
import { useParams } from 'react-router-dom';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

interface Monitor {
  name: string;
  studentId: string;
}

interface OrganizationRoster {
  organization_name: string;
  roster: { fullName: string }[];
}

interface OrganizationSocialInfo {
  organization_id: string;
  organization_name: string;
  contact_name: string;
  contact_email: string;
  event_location: string;
  venue_contact_name: string;
  venue_contact_phone: string;
  venue_contact_email: string;
  guest_list_file_path: string;
  roster: string;
  cohost_email_address: string;
}

interface CohostOption {
  organization_id: string;
  name: string;
}

interface CohostInfo {
  organization_name: string;
  cohost_email_address: string;
}

interface RosterAccess {
  organization_id: string;
  organization_name: string;
}

interface SocialRequestPermission {
    requested_organization_id: string;
    organizations?: {
        organization_id: string;
        name: string;
    };
}
  
const CopyButton: React.FC<{ text: string }> = ({ text }) => (
  <button
    onClick={() => navigator.clipboard.writeText(text)}
    className="ml-2 p-1 text-gray-500 hover:text-gray-700 focus:outline-none"
  >
    <FaCopy />
  </button>
);

const FieldWithCopy: React.FC<{ label: string; value: string }> = ({ label, value }) => (
  <div className="flex items-center justify-between py-2 border-b border-gray-200">
    <span className="text-sm font-medium text-gray-700">{label}</span>
    <div className="flex items-center">
      <span className="text-sm text-gray-900">{value}</span>
      <CopyButton text={value} />
    </div>
  </div>
);

const EventDetailsComponent: React.FC = () => {
  const { groupId } = useParams<{ groupId: string }>();
  const [organizationInfo, setOrganizationInfo] = useState<OrganizationSocialInfo | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedCohost, setSelectedCohost] = useState<string | null>(null);
  const [cohostOptions, setCohostOptions] = useState<CohostOption[]>([]);
  const [cohostInfo, setCohostInfo] = useState<CohostInfo | null>(null);
  const [rosterAccessOrgs, setRosterAccessOrgs] = useState<RosterAccess[]>([]);
  const [selectedRosterOrgs, setSelectedRosterOrgs] = useState<string[]>([]);
  const [monitors, setMonitors] = useState<Monitor[]>([]);
  const [selectedMonitors, setSelectedMonitors] = useState<Monitor[]>([]);

  useEffect(() => {
    fetchOrganizationInfo();
    fetchCohostOptions();
    fetchRosterAccessOrgs();
  }, [groupId]);

  useEffect(() => {
    if (organizationInfo) {
      parseMonitors(organizationInfo.roster);
    }
  }, [organizationInfo]);

  const fetchOtherOrganizationsRosters = async (orgIds: string[]): Promise<OrganizationRoster[]> => {
    const rosters: OrganizationRoster[] = [];
    for (const orgId of orgIds) {
      const { data, error } = await supabase
        .from('organization_social_info')
        .select('organization_name, roster')
        .eq('organization_id', orgId)
        .single();

      if (error) {
        console.error(`Error fetching roster for organization ${orgId}:`, error);
        continue;
      }

      if (data) {
        const parsedRoster = JSON.parse(data.roster);
        rosters.push({
          organization_name: data.organization_name,
          roster: parsedRoster.map((entry: any) => ({ fullName: entry.fullName }))
        });
      }
    }
    return rosters;
  };

  const downloadGuestList = async () => {
    const doc = new jsPDF();
    doc.setFontSize(18);
    doc.text('Guest List', 14, 22);

    let yOffset = 30;

    if (organizationInfo) {
      doc.setFontSize(14);
      doc.text(`${organizationInfo.organization_name}`, 14, yOffset);
      yOffset += 10;

      const currentOrgRoster = JSON.parse(organizationInfo.roster);
      (doc as any).autoTable({
        head: [['Name']],
        body: currentOrgRoster.map((entry: any) => [entry.fullName]),
        startY: yOffset,
        theme: 'grid',
        styles: { fontSize: 10, cellPadding: 5 },
        headStyles: { fillColor: [66, 135, 245], textColor: 255 },
        alternateRowStyles: { fillColor: [240, 240, 240] },
      });

      yOffset = (doc as any).lastAutoTable.finalY + 20;
    }

    if (selectedRosterOrgs.length > 0) {
      const otherRosters = await fetchOtherOrganizationsRosters(selectedRosterOrgs);
      for (const org of otherRosters) {
        doc.setFontSize(14);
        doc.text(`${org.organization_name}`, 14, yOffset);
        yOffset += 10;

        (doc as any).autoTable({
          head: [['Name']],
          body: org.roster.map(entry => [entry.fullName]),
          startY: yOffset,
          theme: 'grid',
          styles: { fontSize: 10, cellPadding: 5 },
          headStyles: { fillColor: [66, 135, 245], textColor: 255 },
          alternateRowStyles: { fillColor: [240, 240, 240] },
        });

        yOffset = (doc as any).lastAutoTable.finalY + 20;
      }
    }

    doc.save('guest_list.pdf');
  };

  const parseMonitors = (rosterString: string) => {
    try {
      const parsedRoster = JSON.parse(rosterString);
      if (Array.isArray(parsedRoster)) {
        setMonitors(parsedRoster.map(entry => ({
          name: entry.fullName,
          studentId: entry.studentId || 'N/A'
        })));
      } else {
        console.warn('Roster data is not an array:', parsedRoster);
        setMonitors([]);
      }
    } catch (error) {
      console.error('Error parsing roster JSON:', error);
      setMonitors([]);
    }
  };

  const handleMonitorSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedName = e.target.value;
    const selectedMonitor = monitors.find(m => m.name === selectedName);
    if (selectedMonitor && !selectedMonitors.some(m => m.name === selectedName)) {
      setSelectedMonitors([...selectedMonitors, selectedMonitor]);
    }
  };

  const removeSelectedMonitor = (monitorToRemove: Monitor) => {
    setSelectedMonitors(selectedMonitors.filter(m => m.name !== monitorToRemove.name));
  };

  const downloadMonitorsList = () => {
    const doc = new jsPDF();

    doc.setFontSize(18);
    doc.text('Monitor List', 14, 22);

    doc.setFontSize(12);
    doc.text(`Organization: ${organizationInfo?.organization_name}`, 14, 32);
    doc.text(`Event Location: ${organizationInfo?.event_location}`, 14, 40);
    
    const tableData = selectedMonitors.map(m => [m.name, m.studentId]);

    (doc as any).autoTable({
      head: [['Name', 'Student ID']],
      body: tableData,
      startY: 50,
      theme: 'grid',
      styles: { fontSize: 10, cellPadding: 5 },
      headStyles: { fillColor: [66, 135, 245], textColor: 255 },
      alternateRowStyles: { fillColor: [240, 240, 240] },
    });

    doc.save('monitors_list.pdf');
  };

  const fetchOrganizationInfo = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('organization_social_info')
        .select('*')
        .eq('organization_id', groupId)
        .single();

      if (error) throw error;
      setOrganizationInfo(data);
    } catch (err) {
      console.error('Error fetching organization info:', err);
      setError('Failed to fetch organization information');
    } finally {
      setLoading(false);
    }
  };

  const fetchCohostOptions = async () => {
    try {
      const { data, error } = await supabase
        .from('social_request_permissions')
        .select(`
          requested_organization_id,
          organizations!social_request_permissions_requested_organization_id_fkey (
            organization_id,
            name
          )
        `)
        .eq('initiator_organization_id', groupId)
        .eq('contact_status', 'Approved')
        .eq('organizations.is_greek', true)
        .eq('organizations.college_id', 1);
  
      if (error) throw error;
  
      if (data) {
        const formattedOptions: CohostOption[] = (data as unknown as SocialRequestPermission[]).map(item => ({
          organization_id: item.requested_organization_id,
          name: item.organizations?.name ?? 'Unknown Organization'
        }));
        
        setCohostOptions(formattedOptions);
      }
    } catch (err) {
      console.error('Error fetching cohost options:', err);
    }
  };

  const fetchCohostInfo = async (cohostOrgId: string) => {
    try {
      const { data, error } = await supabase
        .from('organization_social_info')
        .select('organization_name, cohost_email_address')
        .eq('organization_id', cohostOrgId)
        .single();
      
      setCohostInfo(data);
    } catch (err) {
      console.error('Error fetching cohost info:', err);
      setCohostInfo(null);
    }
  };

  const fetchRosterAccessOrgs = async () => {
    try {
      const { data, error } = await supabase
        .from('social_request_permissions')
        .select(`
          requested_organization_id,
          organizations!social_request_permissions_requested_organization_id_fkey (
            organization_id,
            name
          )
        `)
        .eq('initiator_organization_id', groupId)
        .eq('roster_status', 'Approved');

      if (error) throw error;

      if (data) {
        const formattedData: RosterAccess[] = (data as unknown as SocialRequestPermission[]).map(item => ({
          organization_id: item.requested_organization_id,
          organization_name: item.organizations?.name ?? 'Unknown Organization'
        }));
        setRosterAccessOrgs(formattedData);
      }
    } catch (err) {
      console.error('Error fetching roster access organizations:', err);
    }
  };

  const handleCohostChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = e.target.value;
    setSelectedCohost(selectedId);
    if (selectedId) {
      fetchCohostInfo(selectedId);
    } else {
      setCohostInfo(null);
    }
  };

  const handleRosterOrgSelection = (orgId: string) => {
    setSelectedRosterOrgs(prev => 
      prev.includes(orgId) ? prev.filter(id => id !== orgId) : [...prev, orgId]
    );
  };

  const getRosterDownloadText = () => {
    const selectedOrgNames = rosterAccessOrgs
      .filter(org => selectedRosterOrgs.includes(org.organization_id))
      .map(org => org.organization_name);

    if (selectedOrgNames.length === 0) {
      return `The roster download will only include your organization (${organizationInfo?.organization_name}).`;
    } else {
      return `The roster download will include your organization (${organizationInfo?.organization_name}) and ${selectedOrgNames.join(', ')}.`;
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!organizationInfo) return <div>No organization information found.</div>;

  return (
    <div className="max-w-3xl mx-auto p-6 bg-white rounded-lg shadow">
      <h2 className="text-2xl font-semibold text-gray-800 mb-6">Create Event</h2>
      
      <div className="space-y-4">
        <FieldWithCopy label="Organization Name" value={organizationInfo.organization_name} />
        <FieldWithCopy label="Contact Name" value={organizationInfo.contact_name} />
        <FieldWithCopy label="Email Address" value={organizationInfo.contact_email} />

        <div className="py-2 border-b border-gray-200">
          <label className="block text-sm font-medium text-gray-700 mb-1">Co-Hosting</label>
          <select
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            onChange={handleCohostChange}
            value={selectedCohost || ''}
          >
            <option value="">Select Co-Host</option>
            {cohostOptions.map((option) => (
              <option key={option.organization_id} value={option.organization_id}>{option.name}</option>
            ))}
          </select>
        </div>

        {selectedCohost && (
          <div className="pl-4 border-l-2 border-indigo-200">
            {cohostInfo ? (
              <>
                <FieldWithCopy 
                  label="Co-Host Organization Name" 
                  value={cohostInfo.organization_name}
                />
                <FieldWithCopy 
                  label="Co-Host Email Address" 
                  value={cohostInfo.cohost_email_address || ''}
                />
              </>
            ) : (
              <div className="text-sm text-red-500">
                This organization has not saved their information. Please contact them to do so.
              </div>
            )}
          </div>
        )}

        <FieldWithCopy label="Location of Event" value={organizationInfo.event_location} />
        <FieldWithCopy label="Venue Contact Person Name" value={organizationInfo.venue_contact_name} />
        <FieldWithCopy label="Phone Number of Venue Contact Person" value={organizationInfo.venue_contact_phone} />
        <FieldWithCopy label="Email Address of Venue Contact Person" value={organizationInfo.venue_contact_email} />

        <div className="py-2 border-b border-gray-200">
          <label className="block text-sm font-medium text-gray-700 mb-1">Monitors</label>
          <select 
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            onChange={handleMonitorSelection}
            value=""
          >
            <option value="">Select a monitor</option>
            {monitors.map((monitor) => (
              <option key={monitor.name} value={monitor.name}>{monitor.name}</option>
            ))}
          </select>
        </div>

        {selectedMonitors.length > 0 && (
          <div className="mt-4 space-y-2">
            <h3 className="text-sm font-medium text-gray-700">Selected Monitors:</h3>
            <div className="flex flex-wrap gap-2">
              {selectedMonitors.map((monitor) => (
                <div key={monitor.name} className="flex items-center bg-gray-100 rounded-md p-2">
                  <span className="text-sm">{monitor.name}</span>
                  <button
                    onClick={() => removeSelectedMonitor(monitor)}
                    className="ml-2 text-red-500 hover:text-red-700 focus:outline-none"
                  >
                    ×
                  </button>
                </div>
              ))}
            </div>
            <button
              onClick={downloadMonitorsList}
              className="flex items-center px-3 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <FaDownload className="mr-2" />
              Download Monitors List
            </button>
          </div>
        )}

        <div className="py-2 border-b border-gray-200">
          <p className="text-sm text-gray-700 mb-2">{getRosterDownloadText()}</p>
          <div className="space-y-2">
            {rosterAccessOrgs.map((org) => (
              <label key={org.organization_id} className="flex items-center">
                <input
                  type="checkbox"
                  checked={selectedRosterOrgs.includes(org.organization_id)}
                  onChange={() => handleRosterOrgSelection(org.organization_id)}
                  className="mr-2"
                />
                <span className="text-sm text-gray-700">{org.organization_name}</span>
              </label>
            ))}
          </div>
        </div>

        <div className="flex items-center justify-between py-2">
          <span className="text-sm font-medium text-gray-700">Guest List</span>
          <button
            onClick={downloadGuestList}
            className="flex items-center px-3 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <FaDownload className="mr-2" />
            Download Guest List
          </button>
        </div>
      </div>
    </div>
  );
};

export default EventDetailsComponent;