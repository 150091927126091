import React from 'react';
import { Link } from 'react-router-dom';
import AgoraLogo from '../../Assets/AgoraLogo.svg';


const Header = () => {
    return (
      <header className="">
        <div className="container mx-auto flex justify-between items-center py-4 px-6">
          <div className='flex flex-row'>
            <Link to="/">
                <img src={AgoraLogo} alt="Agora Logo" className="h-8" />
            </Link>
            <h1 className="text-3xl font-bold">gora</h1>
          </div>
          <div>
            <Link to="/" className="mr-4 text-blue-500 border border-blue-500 rounded px-4 py-2 hover:bg-blue-500 hover:text-white transition duration-300">Back To Home</Link>
          </div>
        </div>
      </header>
    );
  };

const PrivacyPolicy = () => {
  return (
    <div className="container mx-auto p-8">
      <Header />
      <h1 className="text-4xl font-bold mb-8">Privacy Policy</h1>

      <h2 className="text-2xl font-bold mb-4">Introduction</h2>
      <p>Agora Processing, Inc. ("Agora") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our platform.</p>

      <h2 className="text-2xl font-bold mt-8 mb-4">Information We Collect</h2>
      <ul className="list-disc ml-6">
        <li><strong>Personal Information</strong>: Name, email address, billing information, phone number, school information.</li>
        <li><strong>Financial Information</strong>: Payment information processed through Stripe.</li>
        <li><strong>Site Activity</strong>: User activity on our platform, including actions performed and data changes for auditing purposes.</li>
      </ul>

      <h2 className="text-2xl font-bold mt-8 mb-4">How We Use Your Information</h2>
      <ul className="list-disc ml-6">
        <li><strong>To Provide and Improve Our Services</strong>: We use your information to deliver and enhance the functionality of our platform.</li>
        <li><strong>To Process Payments</strong>: We use Stripe to process payments. Your financial information is stored with Stripe.</li>
        <li><strong>To Ensure Security</strong>: We track user activity to maintain the security and integrity of our platform.</li>
        <li><strong>To Communicate with You</strong>: We may use your contact information to send you updates, notifications, and other communications related to our services.</li>
      </ul>

      <h2 className="text-2xl font-bold mt-8 mb-4">Data Sharing and Disclosure</h2>
      <ul className="list-disc ml-6">
        <li><strong>With Service Providers</strong>: We share your information with third-party service providers, such as Stripe, to process payments and provide other services on our behalf.</li>
        <li><strong>For Legal Reasons</strong>: We may disclose your information if required by law or in response to valid requests by public authorities.</li>
      </ul>

      <h2 className="text-2xl font-bold mt-8 mb-4">Data Retention</h2>
      <ul className="list-disc ml-6">
        <li>We retain personal information as long as the account is active.</li>
        <li>Financial information and related activities are retained indefinitely for auditing and reference purposes.</li>
      </ul>

      <h2 className="text-2xl font-bold mt-8 mb-4">Data Security</h2>
      <p>We implement industry-standard security measures to protect your personal information. However, no security system is impenetrable, and we cannot guarantee the security of our systems 100%.</p>

      <h2 className="text-2xl font-bold mt-8 mb-4">User Rights</h2>
      <ul className="list-disc ml-6">
        <li><strong>Access</strong>: You can request access to the personal information we hold about you.</li>
        <li><strong>Correction</strong>: You can request correction of any incorrect or incomplete information.</li>
        <li><strong>Deletion</strong>: You can request deletion of your personal information, subject to certain exceptions.</li>
        <li><strong>Restriction</strong>: You can request the restriction of processing of your personal information.</li>
        <li><strong>Portability</strong>: You can request a copy of your personal information in a structured, machine-readable format.</li>
      </ul>

      <h2 className="text-2xl font-bold mt-8 mb-4">Cookies and Tracking Technologies</h2>
      <p>We use cookies and similar tracking technologies to track activity on our platform and hold certain information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.</p>

      <h2 className="text-2xl font-bold mt-8 mb-4">Changes to This Privacy Policy</h2>
      <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our platform. Your continued use of the services after any such changes constitutes your acceptance of the new Privacy Policy.</p>

      <h2 className="text-2xl font-bold mt-8 mb-4">Acceptance of Privacy Policy</h2>
      <p>By creating an account, you acknowledge that you have read, understood, and agree to be bound by this Privacy Policy.</p>

      <h2 className="text-2xl font-bold mt-8 mb-4">Contact Information</h2>
      <p>If you have any questions about this Privacy Policy, please contact us at privacy@agoraprocessing.com.</p>
    </div>
  );
};

export default PrivacyPolicy;
