import React from 'react';

interface PaymentItemProps {
  productName: string;
  amount: number;
  dueDate: string;
}

const PaymentItem: React.FC<PaymentItemProps> = ({ productName, amount, dueDate }) => {
  const formattedAmount = (amount / 100).toFixed(2);

  return (
    <div className="grid grid-cols-3 gap-4 items-center p-4 rounded-md bg-[#EFF0F7] border border-[#CDCBE5] w-full">
      <span className="font-medium">{productName}</span>
      <span className="text-center text-gray-600">{dueDate}</span>
      <span className="text-right font-semibold">${formattedAmount}</span>
    </div>
  );
};

export default PaymentItem;