import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../Contexts/AuthContext';
import { supabase } from '../../../supabase';
import { useParams } from 'react-router-dom';
import GeneralSettings from './OrganizationSettings/GeneralSettings';
import { OrgDetails } from '../../../types/types';

const OrganizationSettings: React.FC = () => {
  const { user } = useAuth();
  const { groupId } = useParams<{ groupId: string }>();
  const [loading, setLoading] = useState(true);
  const [orgDetails, setOrgDetails] = useState<OrgDetails>({
    is_public: false,
    contact_info: { name: '', email: '', phone: '' },
    social_info: { instagram: '' },
    recruitment_info: '', 
    show_college_name: false,
    build_page: []
  });

  useEffect(() => {
    const fetchDetails = async () => {
      const { data, error } = await supabase
        .from('organization_pages')
        .select('*')
        .eq('organization_id', groupId)
        .single();
      if (error && error.code !== 'PGRST116') {
        console.error('Error fetching organization details:', error);
      } else if (data) {
        const details = {
          ...data,
          is_public: data.is_public ?? false,
        };
        setOrgDetails(details);
      }
      setLoading(false);
    };

    fetchDetails();
  }, [user, groupId]);

  
  if (loading) return <div>Loading...</div>;

  return (
    <div className="bg-white p-8 rounded-lg shadow">
      <h2 className="text-2xl font-semibold mb-4">Organization Settings</h2>
      <GeneralSettings
        orgDetails={orgDetails}
        setOrgDetails={setOrgDetails}
      />
    </div>
  );
};

export default OrganizationSettings;