import React, { useState, useEffect } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import {
  fetchOrganizationCommittees,
  fetchCommitteeMembers
} from '../../Contexts/supabaseRecruitment';

interface Committee {
  committee_id: string;
  committee_name: string;
}

interface Member {
  user_id: string;
  first_name: string;
  last_name: string;
}

interface ExpandedCommittee {
  committeeId: string;
  committeeMembers: Member[];
}

const ViewCommittees: React.FC = () => {
  const [committees, setCommittees] = useState<Committee[]>([]);
  const [expandedCommittee, setExpandedCommittee] = useState<ExpandedCommittee | null>(null);
  const { groupId } = useParams<{ groupId: string }>();

  useEffect(() => {
    if (!groupId) return;

    const fetchData = async () => {
      try {
        const committeesData = await fetchOrganizationCommittees(groupId);
        setCommittees(committeesData);
      } catch (error) {
        console.error('Error fetching committees:', error);
      }
    };
    fetchData();
  }, [groupId]);

  const toggleExpandCommittee = async (committeeId: string) => {
    if (expandedCommittee && expandedCommittee.committeeId === committeeId) {
      setExpandedCommittee(null);
    } else {
      try {
        const committeeMembers = await fetchCommitteeMembers(committeeId);
        setExpandedCommittee({ committeeId, committeeMembers });
      } catch (error) {
        console.error('Error fetching committee details:', error);
      }
    }
  };

  if (!groupId) {
    return <Navigate to="/some-error-page" />;
  }

  return (
    <div className="p-6 bg-gray-100 rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold mb-4">Existing Committees</h2>
      <div>
        {committees.map((committee) => (
          <div key={committee.committee_id} className="p-4 bg-white rounded-md shadow-sm mb-4">
            <h3 className="text-lg font-semibold cursor-pointer" onClick={() => toggleExpandCommittee(committee.committee_id)}>
              {committee.committee_name}
            </h3>
            {expandedCommittee && expandedCommittee.committeeId === committee.committee_id && (
              <div className="mt-4">
                <h4 className="text-md font-semibold">Members:</h4>
                <ul>
                  {expandedCommittee.committeeMembers.map(member => (
                    <li key={member.user_id}>{member.first_name} {member.last_name}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ViewCommittees;