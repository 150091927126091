import React from 'react';

interface Campaign {
  id: string;
  name: string;
}

interface DropdownProps {
  isOpen: boolean;
  onClose: () => void;
  campaigns: Campaign[];
  selectedCampaign: string;
  onSelectCampaign: (id: string) => void;
}

const Dropdown: React.FC<DropdownProps> = ({ isOpen, onClose, campaigns, selectedCampaign, onSelectCampaign }) => {
  if (!isOpen) return null;

  return (
    <div className="absolute top-full right-0 mt-1 bg-white rounded-md shadow-lg z-10 w-64">
      {campaigns.map((campaign) => (
        <button
          key={campaign.id}
          className={`w-full text-left px-4 py-2 hover:bg-gray-100 transition-colors ${
            campaign.id === selectedCampaign ? 'bg-blue-100' : ''
          }`}
          onClick={() => {
            onSelectCampaign(campaign.id);
            onClose();
          }}
        >
          {campaign.name}
        </button>
      ))}
    </div>
  );
};

export default Dropdown;