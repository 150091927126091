import { useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../Contexts/AuthContext';
import { showToast } from '../../Toast';

export default function Signup() {
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const firstNameRef = useRef<HTMLInputElement>(null);
  const lastNameRef = useRef<HTMLInputElement>(null);
  const { signUp } = useAuth();
  const navigate = useNavigate();
  const [acceptedTerms, setAcceptedTerms] = useState(false);

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const email = emailRef.current?.value;
    const password = passwordRef.current?.value;
    const firstName = firstNameRef.current?.value;
    const lastName = lastNameRef.current?.value;

    if (!email || !password || !firstName || !lastName) {
      alert('All fields are required');
      return;
    }

    try {
      const { error } = await signUp({ email, password, firstName, lastName });

      if (error) {
        if ((error as any).status === 400) {
          alert('Email already exists');
        } else if ((error as any).status === 422) {
          alert('Password must be at least 6 characters long');
        } else {
          alert(`Error signing up: ${error.message}`);
        }
      } else {
        showToast('Account Created! Check Your Email :)');
        navigate('/login');
      }
    } catch (error: any) {
      alert(`Error signing up: ${error.message}`);
    }
  }

  return (
    <div className="flex items-center justify-center min-h-screen p-4 bg-[#E4E6F2]">
      <div className="w-full max-w-md p-6 border rounded-lg bg-white/40 ">
        <h2 className="mb-6 text-2xl font-bold text-center text-primaryButtonBg">Sign Up</h2>
        <form onSubmit={handleSubmit} className="flex flex-col space-y-4">
          <div className="flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:space-x-3">
            <div className="flex-1">
              <input
                id="first-name"
                type="text"
                ref={firstNameRef}
                placeholder="First Name"
                className="w-full px-4 py-2 border rounded border-inputBorder focus:border-inputFocusBorder"
              />
            </div>
            <div className="flex-1">
              <input
                id="last-name"
                type="text"
                ref={lastNameRef}
                placeholder="Last Name"
                className="w-full px-4 py-2 border rounded border-inputBorder focus:border-inputFocusBorder"
              />
            </div>
          </div>
          <input
            id="email"
            type="email"
            ref={emailRef}
            placeholder="Email"
            className="w-full px-4 py-2 border rounded border-inputBorder focus:border-inputFocusBorder"
          />
          <input
            id="password"
            type="password"
            ref={passwordRef}
            placeholder="Password"
            className="w-full px-4 py-2 border rounded border-inputBorder focus:border-inputFocusBorder"
          />
          <div className="flex items-start space-x-2">
            <input
              type="checkbox"
              id="terms"
              className="mt-1"
              checked={acceptedTerms}
              onChange={() => setAcceptedTerms(!acceptedTerms)}
            />
            <label htmlFor="terms" className="text-sm text-componentText">
              I agree to the <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">Terms and Conditions</a> and <a href="/privacy-policy" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">Privacy Policy</a>
            </label>
          </div>
          <button
            type="submit"
            className={`bg-primaryButtonBg text-primaryButtonText px-4 py-2 rounded hover:bg-secondaryButtonBg w-full ${!acceptedTerms ? 'opacity-50 cursor-not-allowed' : ''}`}
            disabled={!acceptedTerms}
          >
            Sign Up
          </button>
        </form>
        <p className="mt-6 text-center text-componentText">
          Already have an account? <Link to="/login" className="text-linkText hover:underline">Log In</Link>
        </p>
      </div>
    </div>
  );
}
