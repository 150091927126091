import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface CustomToastProps {
  message: string;
  isError: boolean;
}

const CustomToast: React.FC<CustomToastProps> = ({ message, isError }) => (
  <div className={`flex items-center justify-between w-full ${isError ? 'text-red-800' : 'text-green-800'}`}>
    <p className="text-sm font-medium">{message}</p>
  </div>
);

export const showToast = (message: string, isError = false) => {
  toast(
    <CustomToast message={message} isError={isError} />,
    {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      type: isError ? 'error' : 'success',
    }
  );
};

const Toast: React.FC = () => (
  <ToastContainer
    position="bottom-right"
    autoClose={5000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    theme="light"
    toastClassName="bg-white border border-white rounded-md shadow-lg"
    bodyClassName="text-gray-800"
    progressClassName="bg-blue-500"
  />
);

export default Toast;
