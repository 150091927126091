import React, { useEffect, useState } from 'react';
import { fetchUserOrderHistory, fetchPaymentInformation } from '../../../Contexts/supabaseService';
import InfoIcon from '../../../Assets/Info.svg';

interface Order {
  payment_id: string;
  amount: number;
  item_ids: string;
  created_at: string;
}

interface NamedEntity {
  name: string;
}

interface OrderDetail {
  item_id: string;
  quantity: number;
  amount: number;
  organization_id: string;
  organizations: NamedEntity[] | NamedEntity;
  products: NamedEntity[] | NamedEntity;
  organization?: NamedEntity;
  product?: NamedEntity;
}

const ITEMS_PER_PAGE = 10;

const OrderHistory: React.FC = () => {
  const [userOrders, setUserOrders] = useState<Order[]>([]);
  const [selectedOrderDetails, setSelectedOrderDetails] = useState<OrderDetail[] | null>(null);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const userId = localStorage.getItem('userId');

  useEffect(() => {
    if (userId) {
      fetchOrders(currentPage);
    }
  }, [userId, currentPage]);

  const fetchOrders = async (page: number) => {
    setLoading(true);
    try {
      const { orders, totalCount } = await fetchUserOrderHistory(userId!, page, ITEMS_PER_PAGE);
      setUserOrders(orders);
      setTotalCount(totalCount);
    } catch (error) {
      console.error('Error fetching order history:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleManage = async (order: Order) => {
    setSelectedOrder(order);
    setLoadingDetails(true);
    setSelectedOrderDetails(null);
    try {
      const details = await fetchPaymentInformation(order.item_ids);
      console.log('details', details);
      if (details.length === 0) {
        console.warn('No details found for order:', order.payment_id);
      }
      setSelectedOrderDetails(details);
    } catch (error) {
      console.error('Error fetching payment information:', error);
    } finally {
      setLoadingDetails(false);
    }
  };

  const handleClose = () => {
    setSelectedOrder(null);
    setSelectedOrderDetails(null);
  };

  const formatDate = (dateString: string): string => {
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const formatAmount = (amount: number): string => {
    return (amount / 100).toFixed(2);
  };

  const getNameFromEntity = (entity: NamedEntity[] | NamedEntity | undefined): string => {
    if (Array.isArray(entity) && entity.length > 0) {
      return entity[0].name;
    } else if (entity && 'name' in entity) {
      return entity.name;
    }
    return 'N/A';
  };

  const getProductName = (detail: OrderDetail): string => {
    return getNameFromEntity(detail.products) || getNameFromEntity(detail.product);
  };

  const getOrganizationName = (detail: OrderDetail): string => {
    return getNameFromEntity(detail.organizations) || getNameFromEntity(detail.organization);
  };

  const totalPages = Math.ceil(totalCount / ITEMS_PER_PAGE);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Your order history</h1>
      <div className="flex">
        <div className={`w-full ${selectedOrder ? 'lg:w-2/3' : ''} transition-all duration-300`}>
          <div className="bg-white shadow-md rounded-lg overflow-hidden">
            {loading ? (
              <p className="p-4">Loading orders...</p>
            ) : (
              <>
                <table className="w-full">
                  <thead>
                    <tr className="bg-gray-100">
                      <th className="px-4 py-2 text-left">ORDER</th>
                      <th className="px-4 py-2 text-left">AMOUNT</th>
                      <th className="px-4 py-2 text-left">DATE</th>
                      <th className="px-4 py-2 text-left"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {userOrders.map((order) => (
                      <tr key={order.payment_id} className="border-b">
                        <td className="px-4 py-2 flex items-center">
                          Order {order.payment_id.slice(0, 8)}
                        </td>
                        <td className="px-4 py-2">${formatAmount(order.amount)}</td>
                        <td className="px-4 py-2">{formatDate(order.created_at)}</td>
                        <td className="px-4 py-2">
                          <button
                            onClick={() => handleManage(order)}
                            className="text-blue-600 hover:text-blue-800"
                          >
                            Manage
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="px-4 py-3 border-t flex justify-between items-center">
                  <span>Showing {(currentPage - 1) * ITEMS_PER_PAGE + 1} - {Math.min(currentPage * ITEMS_PER_PAGE, totalCount)} of {totalCount}</span>
                  <div>
                    <button 
                      onClick={() => handlePageChange(currentPage - 1)} 
                      disabled={currentPage === 1}
                      className="px-2 py-1 border rounded-md mr-2 disabled:opacity-50"
                    >
                      &lt;
                    </button>
                    <button 
                      onClick={() => handlePageChange(currentPage + 1)} 
                      disabled={currentPage === totalPages}
                      className="px-2 py-1 border rounded-md disabled:opacity-50"
                    >
                      &gt;
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        {selectedOrder && (
        <div className="w-full lg:w-1/3 pl-4">
          <div className="bg-white shadow-md rounded-lg p-4 relative">
            <button
              onClick={handleClose}
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 text-xl font-bold"
            >
              ×
            </button>
            <h2 className="text-xl font-semibold mb-4">Order Details</h2>
            {loadingDetails ? (
              <p>Loading details...</p>
            ) : selectedOrderDetails && selectedOrderDetails.length > 0 ? (
              <>
                <table className="w-full text-sm">
                  <thead>
                    <tr className="border-b">
                      <th className="text-left font-semibold pb-2">Bill</th>
                      <th className="text-left font-semibold pb-2">Qty</th>
                      <th className="text-left font-semibold pb-2">Org</th>
                      <th className="text-right font-semibold pb-2">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedOrderDetails.map((detail, index) => (
                      <tr key={index} className="border-b last:border-b-0">
                        <td className="py-2">{getProductName(detail)}</td>
                        <td className="py-2">{detail.quantity}</td>
                        <td className="py-2">{getOrganizationName(detail)}</td>
                        <td className="py-2 text-right">${formatAmount(detail.amount)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="mt-4 text-right font-semibold">
                  Total: ${formatAmount(selectedOrderDetails.reduce((sum, detail) => sum + detail.amount, 0))}
                </div>
              </>
            ) : (
              <p>No details available for this order.</p>
            )}
          </div>
        </div>
      )}
      </div>
    </div>
  );
};

export default OrderHistory;