import React, { createContext, useState, useEffect, useContext, ReactNode } from 'react';
import { supabase } from '../supabase';
import { Session, User } from '@supabase/supabase-js';

interface AuthContextType {
  user: User | null;
  signUp: (data: SignUpData) => Promise<{ user: User | null; error: Error | null }>;
  signIn: (data: SignInData) => Promise<{ user: User | null; error: Error | null }>;
  signOut: () => Promise<void>;
  resetPassword: (email: string) => Promise<{ data: object | null; error: Error | null }>;
}

interface SignUpData {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  // college: string;
}

interface SignInData {
  email: string;
  password: string;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: { children: ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUser = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (session) {
        setUser(session.user);
        if (session.expires_at) {
          setSessionTimeout(session.expires_at);
        }
      }
      setLoading(false);
    };

    const { data: { subscription: listener } } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        if (event === 'SIGNED_IN' && session) {
          setUser(session.user);
          localStorage.setItem('userToken', session.access_token);
          localStorage.setItem('userId', session.user.id);
          if (session.expires_at) {
            setSessionTimeout(session.expires_at);
          }
        } else if (event === 'SIGNED_OUT') {
          setUser(null);
          localStorage.removeItem('userToken');
          localStorage.removeItem('userId');
          clearSessionTimeout();
        }
        setLoading(false);
      }
    );

    fetchUser();

    return () => {
      listener?.unsubscribe();
    };
  }, []);

  const setSessionTimeout = (expires_at: number) => {
    const currentTime = Math.floor(Date.now() / 1000);
    const expiresIn = expires_at - currentTime;

    if (expiresIn > 0) {
      (window as any).sessionTimeout = setTimeout(async () => {
        const { data: { session }, error } = await supabase.auth.refreshSession();
        if (session) {
          setUser(session.user);
          if (session.expires_at) {
            setSessionTimeout(session.expires_at);
          }
        } else {
          setUser(null);
        }
      }, expiresIn * 1000);
    }
  };

  const clearSessionTimeout = () => {
    if ((window as any).sessionTimeout) {
      clearTimeout((window as any).sessionTimeout);
    }
  };

  const signUp = async ({ email, password, firstName, lastName }: SignUpData) => {
    const { data, error } = await supabase.auth.signUp({
      email,
      password,
      options: {
        data: {
          first_name: firstName,
          last_name: lastName,
          // college_id: college,
        },
      },
    });

    if (error) {
      // console.error('Error signing up:', error.message);
      return { user: null, error };
    }

    return { user: data.user, error: null };
  };

  const signIn = async ({ email, password }: SignInData) => {
    const { data, error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });

    if (error) {
      const { error } = await supabase.auth.resend({
        type: 'signup',
        email,
        options: {
          emailRedirectTo: 'https://agoraprocessing.com/login'
        }
      })
      
      return { user: null, error };
    }

    return { user: data.user, error: null };
  };

  const signOut = async () => {
    const { error } = await supabase.auth.signOut();

    if (error) {
      // console.error('Error signing out:', error.message);
    }
  };

  const resetPassword = async (email: string) => {
    const { data, error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: 'https://agoraprocessing.com/reset-password',
    });

    if (error) {
      // console.error('Error resetting password:', error.message);
    }

    return { data, error };
  };

  const value = {
    user,
    signUp,
    signIn,
    signOut,
    resetPassword,
  };

  return (
    <AuthContext.Provider value={value}>
      {loading ? <div>Loading...</div> : children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}
