import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../Contexts/AuthContext';
import { FaTrash, FaTimes } from 'react-icons/fa';
import { fetchPendingBills, deleteOrganizationItem } from './supabaseAdmin';

interface OrganizationItem {
  item_id: number;
  amount: number;
  organization_id: string;
  user_id: string;
  product_id: string;
  quantity: number;
  currency: string;
  status: string;
  due_date: string;
  created_at: string;
  updated_at: string;
  billing_account_id: number;
  product_name: string;
  user_name: string;
  billing_account_name: string;
}

const ViewPendingBills: React.FC = () => {
  const { groupId } = useParams<{ groupId: string }>();
  const { user } = useAuth();
  const [pendingBills, setPendingBills] = useState<OrganizationItem[]>([]);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedBill, setSelectedBill] = useState<OrganizationItem | null>(null);

  useEffect(() => {
    const getPendingBills = async () => {
      if (groupId) {
        const bills = await fetchPendingBills(groupId);
        setPendingBills(bills);
      }
    };
    getPendingBills();
  }, [groupId]);

  const handleDeleteClick = (bill: OrganizationItem) => {
    setSelectedBill(bill);
    setShowPopup(true);
  };

  const handleConfirmDelete = async () => {
    if (selectedBill) {
      await deleteOrganizationItem(selectedBill.item_id);
      setPendingBills(pendingBills.filter(bill => bill.item_id !== selectedBill.item_id));
      handleClosePopup();
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setSelectedBill(null);
  };

  return (
    <div className="container p-6 mx-auto">
      <h1 className="mb-6 text-3xl font-bold text-gray-800">Pending Bills</h1>
      <div className="overflow-hidden bg-white rounded-lg shadow-md">
        <table className="min-w-full bg-white">
          <thead>
            <tr className="text-sm leading-normal text-gray-600 uppercase bg-gray-200">
              <th className="px-6 py-3 text-left">Due Date</th>
              <th className="px-6 py-3 text-left">User</th>
              <th className="px-6 py-3 text-left">Product Name</th>
              <th className="px-6 py-3 text-left">Billing Account</th>
              <th className="px-6 py-3 text-right">Amount</th>
              <th className="px-6 py-3 text-center">Delete</th>
            </tr>
          </thead>
          <tbody className="text-sm font-light text-gray-600">
            {pendingBills.map((bill) => (
              <tr key={bill.item_id} className="border-b border-gray-200 hover:bg-gray-100">
                <td className="px-6 py-3 text-left whitespace-nowrap">
                  <span className="font-medium">{new Date(bill.due_date).toLocaleDateString()}</span>
                </td>
                <td className="px-6 py-3 text-left">{bill.user_name}</td>
                <td className="px-6 py-3 text-left">{bill.product_name}</td>
                <td className="px-6 py-3 text-left">{bill.billing_account_name}</td>
                <td className="px-6 py-3 text-right">
                  ${(bill.amount / 100).toFixed(2)} {bill.currency}
                </td>
                <td className="px-6 py-3 text-center">
                  <div
                    className="inline-block p-2 bg-red-100 rounded-md cursor-pointer hover:bg-red-200"
                    onClick={() => handleDeleteClick(bill)}
                  >
                    <FaTrash className="text-red-500" />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {showPopup && selectedBill && (
        <div 
          className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50"
          onClick={handleClosePopup}
        >
          <div 
            className="relative w-full max-w-md p-8 bg-white rounded-lg shadow-lg"
            onClick={(e) => e.stopPropagation()}
          >
            <button 
              className="absolute text-gray-400 transition-colors duration-200 top-4 right-4 hover:text-gray-600" 
              onClick={handleClosePopup}
            >
              <FaTimes className="text-xl" />
            </button>
            <h2 className="mb-4 text-2xl font-bold text-gray-800">Confirm Delete</h2>
            <p className="mb-4 text-gray-600">
              Are you sure you want to delete the following bill?
            </p>
            <div className="p-4 mb-4 bg-gray-100 rounded-md">
              <p><strong>Product:</strong> {selectedBill.product_name}</p>
              <p><strong>User:</strong> {selectedBill.user_name}</p>
              <p><strong>Due Date:</strong> {new Date(selectedBill.due_date).toLocaleDateString()}</p>
              <p><strong>Amount:</strong> ${(selectedBill.amount / 100).toFixed(2)} {selectedBill.currency}</p>
            </div>
            <p className="mb-6 text-red-500">This action will not delete any existing subscriptions, just this bill.</p>
            <div className="flex justify-end space-x-4">
              <button 
                className="px-4 py-2 text-gray-800 transition-colors duration-200 bg-gray-200 rounded-md hover:bg-gray-300"
                onClick={handleClosePopup}
              >
                Cancel
              </button>
              <button 
                className="px-4 py-2 text-white transition-colors duration-200 bg-red-500 rounded-md hover:bg-red-600"
                onClick={handleConfirmDelete}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewPendingBills;