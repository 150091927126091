import React, { useState, useEffect } from 'react';
import { 
  fetchSubscriptionPlans, 
  fetchAssignments, 
  SubscriptionPlan, 
  Assignment,
  deleteSubscriptionPlan,
  deleteSubscriptionAssignment
} from './supabaseAdmin';


const ViewSubscriptions: React.FC = () => {
  const [subscriptionPlans, setSubscriptionPlans] = useState<SubscriptionPlan[]>([]);
  const [selectedPlan, setSelectedPlan] = useState<SubscriptionPlan | null>(null);
  const [assignments, setAssignments] = useState<Assignment[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    loadSubscriptionPlans();
  }, []);

  useEffect(() => {
    if (isDialogOpen) {
      setIsAnimating(true);
    }
  }, [isDialogOpen]);

  const loadSubscriptionPlans = async () => {
    const plans = await fetchSubscriptionPlans();
    setSubscriptionPlans(plans);
  };

  const handlePlanClick = async (plan: SubscriptionPlan) => {
    setSelectedPlan(plan);
    const planAssignments = await fetchAssignments(plan.plan_id);
    setAssignments(planAssignments);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsAnimating(false);
    setTimeout(() => {
      setIsDialogOpen(false);
      setSelectedPlan(null);
      setAssignments([]);
    }, 100); 
  };

  const handleBackgroundClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      handleCloseDialog();
    }
  };

  const handleDeletePlan = async () => {
    if (!selectedPlan) return;

    setIsDeleting(true);
    try {
      await deleteSubscriptionPlan(selectedPlan.plan_id);
      await loadSubscriptionPlans(); 
      handleCloseDialog(); 
    } catch (error) {
      console.error('Error deleting subscription plan:', error);
    }
    setIsDeleting(false);
  };

  const handleDeleteAssignment = async (assignmentId: string) => {
    try {
      await deleteSubscriptionAssignment(assignmentId);
      if (selectedPlan) {
        const updatedAssignments = await fetchAssignments(selectedPlan.plan_id);
        setAssignments(updatedAssignments);
      }
      await loadSubscriptionPlans();
    } catch (error) {
      console.error('Error deleting assignment:', error);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold mb-6">Subscription Plans</h1>
      <div className="overflow-x-auto bg-white shadow-md rounded-lg">
        <table className="min-w-full leading-normal">
          <thead>
            <tr className="bg-gray-100">
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Subscription Name
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Price
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Billing Cycle
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Members
              </th>
            </tr>
          </thead>
          <tbody>
            {subscriptionPlans.map((plan) => (
              <tr
                key={plan.plan_id}
                onClick={() => handlePlanClick(plan)}
                className="hover:bg-gray-50 cursor-pointer"
              >
                <td className="px-5 py-5 border-b border-gray-200 text-sm">
                  <p className="text-gray-900 whitespace-no-wrap">{plan.name}</p>
                </td>
                <td className="px-5 py-5 border-b border-gray-200 text-sm">
                  <p className="text-gray-900 whitespace-no-wrap">
                    {plan.amount / 100} {plan.currency}
                  </p>
                </td>
                <td className="px-5 py-5 border-b border-gray-200 text-sm">
                  <p className="text-gray-900 whitespace-no-wrap">{plan.billing_cycle}</p>
                </td>
                <td className="px-5 py-5 border-b border-gray-200 text-sm">
                  <p className="text-gray-900 whitespace-no-wrap">{plan.assignment_count}</p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {isDialogOpen && (
        <div 
          className={`fixed inset-0 bg-black transition-opacity duration-100 ease-in-out ${
            isAnimating ? 'bg-opacity-50' : 'bg-opacity-0'
          } flex justify-center items-center z-50`}
          onClick={handleBackgroundClick}
        >
          <div 
            className={`bg-white rounded-lg p-8 max-w-4xl w-full max-h-[80vh] overflow-auto relative transition-all duration-100 ease-in-out ${
              isAnimating ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'
            }`}
            onClick={e => e.stopPropagation()}
          >
            <button
              onClick={handleCloseDialog}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
            <h2 className="text-2xl font-bold mb-6">
              Plans For: {selectedPlan?.name}
            </h2>
            <div className="overflow-x-auto rounded-md mb-6">
              <table className="min-w-full leading-normal">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      User
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      Status
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      Cycles Remaining
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      Billing Account
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {assignments.map((assignment) => (
                    <tr key={assignment.assignment_id} className="hover:bg-gray-50">
                      <td className="px-5 py-5 border-b border-gray-200 text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {assignment.first_name} {assignment.last_name}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">{assignment.status}</p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">{assignment.cycles_remaining}</p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">{assignment.billing_account_name}</p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 text-sm">
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteAssignment(assignment.assignment_id);
                          }}
                          className="bg-red-100 text-red-600 hover:bg-red-200 p-2 rounded-md transition-colors duration-200"
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                          </svg>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="flex justify-between items-center">
              <p className="text-sm text-gray-500">
                {assignments.length === 0 
                  ? "This plan has no active assignments and can be safely deleted."
                  : "This plan has active assignments and cannot be deleted."}
              </p>
              <button
                onClick={handleDeletePlan}
                disabled={assignments.length > 0 || isDeleting}
                className={`bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded transition-opacity duration-100 ${
                  assignments.length > 0 || isDeleting ? 'opacity-50 cursor-not-allowed' : 'opacity-100'
                }`}
              >
                {isDeleting ? 'Deleting...' : 'Delete Subscription'}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewSubscriptions;