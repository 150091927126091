import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { fetchUserOrganizationItems, fetchStripeConnectedAccountId } from '../../../Contexts/supabaseService';
import { postData } from '../../../Contexts/edgeApi';

import Calendar from '../../../Assets/Calendar.svg';
import Balance from '../../../Assets/Balance.svg';

interface BalanceDetailProps {
  title: string;
  amount: string;
  icon: string;
}

const BalanceDetail: React.FC<BalanceDetailProps> = ({ title, amount, icon }) => (
  <div className="bg-white rounded-lg p-4 flex items-center space-x-4 w-full mb-1 md:mb-0 md:flex-1">
    <img src={icon} alt={title} className="w-10 h-10" />
    <div className="flex-1 flex justify-between items-center">
      <p className="text-lg font-semibold text-black">{title}</p>
      <p className="text-2xl font-bold">{amount}</p>
    </div>
  </div>
);

interface BillItemProps {
  item: any;
  onCheckboxChange: (itemId: string) => void;
  isChecked: boolean;
}

const BillItem: React.FC<BillItemProps> = ({ item, onCheckboxChange, isChecked }) => {
  const product = Array.isArray(item.products) ? item.products[0] : item.products;
  const formattedAmount = (item.amount / 100).toFixed(2);
  return (
    <tr className="border-t">
      <td className="py-3">
        <input
          type="checkbox"
          className="rounded text-blue-500 focus:ring-blue-500"
          onChange={() => onCheckboxChange(item.item_id)}
          checked={isChecked}
        />
      </td>
      <td className="py-3">{product?.name}</td>
      <td className="py-3 hidden md:table-cell">{item.due_date}</td>
      <td className="py-3 text-right">${formattedAmount}</td>
    </tr>
  );
};

const BillingComponent = () => {
  const { groupId } = useParams<{ groupId: string }>();
  const navigate = useNavigate();
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [organizationItems, setOrganizationItems] = useState<any[]>([]);
  const [currentBalance, setCurrentBalance] = useState<string>('0');
  const [dueDate, setDueDate] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchOrganizationItems = async () => {
      try {
        const userId = localStorage.getItem('userId');
        if (!userId || !groupId) return;

        const itemsData = await fetchUserOrganizationItems(userId, groupId);
        
        itemsData.sort((a: any, b: any) => new Date(a.due_date).getTime() - new Date(b.due_date).getTime());
        
        setOrganizationItems(itemsData);

        let balance = 0;
        let earliestDueDate = itemsData.length > 0 ? itemsData[0].due_date : '';
        itemsData.forEach((item: any) => {
            balance += item.amount;
        });
        setCurrentBalance((balance / 100).toFixed(2));
        setDueDate(earliestDueDate);
      } catch (error) {
        console.error('Error fetching organization items:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrganizationItems();
  }, [groupId]);

  const handleCheckboxChange = (itemId: string) => {
    setSelectedItems((prev) => {
      if (prev.includes(itemId)) {
        return prev.filter((item) => item !== itemId);
      } else {
        return [...prev, itemId];
      }
    });
  };

  const handleSelectAll = () => {
    if (selectedItems.length === organizationItems.length) {
      setSelectedItems([]);
    } else {
      const allItemIds = organizationItems.map((item) => item.item_id);
      setSelectedItems(allItemIds);
    }
  };

  const handlePaySelected = async () => {
    const selectedItemsData = organizationItems.filter((item) =>
      selectedItems.includes(item.item_id)
    );

    try {
      const lineItems = selectedItemsData.map((item) => ({
        productId: item.product_id,
        quantity: 1,
        item_id: item.item_id,
        group_id: groupId,
      }));

      const userId = localStorage.getItem('userId');
      if (!userId) return;

      const response = await postData('custom-total-checkout', {
        userId,
        items: lineItems,
      });

      navigate(`/checkout`, {
        state: {
          clientSecret: response.clientSecret,
          selectedItems: selectedItemsData,
        },
      });
    } catch (error) {
      console.error('Error creating PaymentIntent:', error);
    }
  };

  return (
    <div className="min-h-screen p-4 md:p-8 bg-gray-100">
      <div className="w-full mx-auto">
        <div className="flex flex-col md:flex-row md:justify-between mb-4 gap-4">
          <BalanceDetail title="Current Balance" amount={`$${currentBalance}`} icon={Balance} />
          <BalanceDetail title="Due Date" amount={dueDate} icon={Calendar} />
        </div>

        <div className="bg-white rounded-lg overflow-x-auto">
          <div className="p-4 border-b">
            <h2 className="text-lg font-semibold">Active Bills</h2>
          </div>
          <div className="p-4">
            {isLoading ? (
              <Skeleton count={5} height={50} className="mb-2" />
            ) : (
              <>
                <table className="w-full min-w-[300px]">
                  <thead>
                    <tr className="text-left text-black">
                      <th className="pb-2 font-normal w-8"></th>
                      <th className="pb-2 font-md">Bill Name</th>
                      <th className="pb-2 font-md hidden md:table-cell">Due Date</th>
                      <th className="pb-2 font-md text-right">Current Balance</th>
                    </tr>
                  </thead>
                  <tbody>
                    {organizationItems.length === 0 ? (
                      <tr>
                        <td colSpan={4} className="text-center py-4 text-gray-500">You are all caught up!</td>
                      </tr>
                    ) : (
                      organizationItems.map((item) => (
                        <BillItem
                          key={item.item_id}
                          item={item}
                          onCheckboxChange={handleCheckboxChange}
                          isChecked={selectedItems.includes(item.item_id)}
                        />
                      ))
                    )}
                  </tbody>
                </table>
                {organizationItems.length > 0 && (
                  <div className="mt-4 flex flex-col md:flex-row justify-between">
                    <button
                      onClick={handleSelectAll}
                      className="px-4 py-2 font-semibold rounded-lg text-white bg-[#4338CA] hover:bg-blue-600 mb-2 md:mb-0"
                    >
                      Select All Payments
                    </button>
                    {selectedItems.length > 0 && (
                      <button
                        onClick={handlePaySelected}
                        className="px-4 py-2 font-semibold rounded-lg text-white bg-[#4338CA] hover:bg-blue-600"
                      >
                        Go To Checkout
                      </button>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingComponent;