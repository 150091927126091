import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchOrganizationProducts, createOneTimePayment } from '../../../Contexts/billingApi';
import { fetchGroupUsers, fetchBillingAccounts } from '../../../Contexts/supabaseService';
import { showToast } from '../../../Toast';

interface OrganizationItem {
  product_id: string;
  name: string;
  price: number;
}

interface Customer {
  user_id: string;
  first_name: string;
  last_name: string;
}

interface BillingAccount {
  billing_account_id: string;
  name: string;
  provider: string;
}

const OneTimePayments: React.FC = () => {
  const [newOneTimePayment, setNewOneTimePayment] = useState({
    name: '',
    quantity: 0,
    amount: 0,
    formattedAmount: '',
    currency: 'USD',
    dueDate: '',
  });
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [selectedCustomers, setSelectedCustomers] = useState<string[]>([]);
  const [billingAccounts, setBillingAccounts] = useState<BillingAccount[]>([]);
  const [selectedBillingAccount, setSelectedBillingAccount] = useState<string | null>(null);
  const { groupId } = useParams<{ groupId: string }>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (groupId) {

          const groupUsers = await fetchGroupUsers(groupId);
          setCustomers(groupUsers || []);

          const billingAccountsData = await fetchBillingAccounts(groupId);
          setBillingAccounts(billingAccountsData || []);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        showToast('Error fetching data', true);
      }
    };

    fetchData();
  }, [groupId]);

  const handleCreateOneTimePayment = async () => {
    try {
      if (groupId && selectedBillingAccount) {
        setIsLoading(true);
        const result = await createOneTimePayment(
          groupId,
          { ...newOneTimePayment, amount: newOneTimePayment.amount },
          selectedCustomers,
          selectedBillingAccount
        );

        setIsLoading(false);

        if (result === 200) {
          showToast('Successfully Created One-Time Payment');

          setNewOneTimePayment({
            name: '',
            quantity: 0,
            amount: 0,
            formattedAmount: '',
            currency: 'USD',
            dueDate: '',
          });
          setSelectedCustomers([]);
          setSelectedBillingAccount(null);
        } else {
          showToast('Error creating one-time payment', true);
        }
      }
    } catch (error) {
      setIsLoading(false);
      showToast('Error creating one-time payment', true);
    }
  };

  const handleCustomerSelection = (userId: string) => {
    setSelectedCustomers((prev) =>
      prev.includes(userId) ? prev.filter((id) => id !== userId) : [...prev, userId]
    );
  };

  const handleAmountChange = (value: string) => {
    const cents = parseInt(value.replace(/[^0-9]/g, ''), 10) || 0;
    const formattedAmount = (cents / 100).toFixed(2);
    setNewOneTimePayment((prev) => ({ ...prev, amount: cents, formattedAmount }));
  };

  const handleBillingAccountSelection = (accountId: string) => {
    setSelectedBillingAccount(accountId);
  };

  const isFormValid = () => {
    return (
      newOneTimePayment.name &&
      newOneTimePayment.amount > 0 &&
      newOneTimePayment.dueDate &&
      selectedCustomers.length > 0 &&
      selectedBillingAccount
    );
  };

  return (
    <div>
      <div className="p-4 bg-componentBg border border-componentOutline rounded-lg shadow">
        <h4 className="mb-2 text-lg font-semibold text-primaryButtonBg">Create One-Time Payment</h4>
        <input
          type="text"
          placeholder="Payment Name"
          value={newOneTimePayment.name}
          onChange={(e) => setNewOneTimePayment({ ...newOneTimePayment, name: e.target.value })}
          className="w-full px-2 py-1 mb-2 border border-inputBorder rounded"
        />
        <input
          type="number"
          placeholder="Quantity"
          value={newOneTimePayment.quantity}
          onChange={(e) => setNewOneTimePayment({ ...newOneTimePayment, quantity: parseInt(e.target.value) })}
          className="w-full px-2 py-1 mb-2 text-right border border-inputBorder rounded"
        />
        <input
          type="text"
          placeholder="Amount"
          value={newOneTimePayment.formattedAmount}
          onChange={(e) => handleAmountChange(e.target.value)}
          className="w-full px-2 py-1 mb-2 text-right border border-inputBorder rounded"
        />
        <select
          value={newOneTimePayment.currency}
          onChange={(e) => setNewOneTimePayment({ ...newOneTimePayment, currency: e.target.value })}
          className="w-full px-2 py-1 mb-2 border border-inputBorder rounded"
        >
          <option value="USD">USD</option>
        </select>
        <input
          type="date"
          placeholder="Due Date"
          value={newOneTimePayment.dueDate}
          onChange={(e) => setNewOneTimePayment({ ...newOneTimePayment, dueDate: e.target.value })}
          className="w-full px-2 py-1 mb-2 border border-inputBorder rounded"
        />
        <div className="mb-4">
          <h4 className="mb-2 text-lg font-semibold text-primaryButtonBg">Select Customers</h4>
          {customers.map((customer) => (
            <div key={customer.user_id}>
              <input
                type="checkbox"
                id={`customer-${customer.user_id}`}
                checked={selectedCustomers.includes(customer.user_id)}
                onChange={() => handleCustomerSelection(customer.user_id)}
              />
              <label htmlFor={`customer-${customer.user_id}`} className="ml-2 text-componentText">
                {customer.first_name} {customer.last_name}
              </label>
            </div>
          ))}
        </div>
        <div className="mb-4">
          <h4 className="mb-2 text-lg font-semibold text-primaryButtonBg">Select Billing Account</h4>
          <div className="flex flex-wrap">
            {billingAccounts.map((account) => (
              <div
                key={account.billing_account_id}
                className={`p-4 m-2 border border-componentOutline rounded-lg shadow cursor-pointer ${
                  selectedBillingAccount === account.billing_account_id
                    ? 'bg-primaryButtonBg text-white'
                    : 'bg-componentBg'
                }`}
                onClick={() => handleBillingAccountSelection(account.billing_account_id)}
              >
                <h4 className="text-lg font-semibold">{account.name}</h4>
                <p>{account.provider}</p>
              </div>
            ))}
          </div>
        </div>
        <button
          onClick={handleCreateOneTimePayment}
          className="px-4 py-2 font-semibold text-primaryButtonText bg-primaryButtonBg rounded-lg hover:bg-secondaryButtonBg disabled:opacity-50 disabled:cursor-not-allowed"
          disabled={!isFormValid() || isLoading}
        >
          {isLoading ? (
            <>
              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white inline-block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              Creating...
            </>
          ) : (
            'Create One-Time Payment'
          )}
        </button>
      </div>
    </div>
  );
};

export default OneTimePayments;