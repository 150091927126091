import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { supabase } from '../../supabase';
import { showToast } from '../../Toast';

interface OrganizationSocialInfo {
  social_info_id?: number;
  organization_name: string;
  cohost_email_address: string;
  contact_name: string;
  contact_email: string;
  event_location: string;
  venue_contact_name: string;
  venue_contact_phone: string;
  venue_contact_email: string;
  roster: string;
}

interface RosterEntry {
  fullName: string;
  studentId: string | null;
}

const SettingsPage: React.FC = () => {
  const { groupId } = useParams<{ groupId: string }>();
  const [socialInfo, setSocialInfo] = useState<OrganizationSocialInfo>({
    organization_name: '',
    cohost_email_address: '',
    contact_name: '',
    contact_email: '',
    event_location: '',
    venue_contact_name: '',
    venue_contact_phone: '',
    venue_contact_email: '',
    roster: '',
  });
  const [rosterData, setRosterData] = useState<RosterEntry[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [newFullName, setNewFullName] = useState('');
  const [newStudentId, setNewStudentId] = useState('');

  useEffect(() => {
    fetchSocialInfo();
  }, [groupId]);

  const fetchSocialInfo = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('organization_social_info')
        .select('*')
        .eq('organization_id', groupId)
        .single();

      if (error && error.code !== 'PGRST116') throw error;

      if (data) {
        setSocialInfo(data);
        if (data.roster) {
          try {
            const parsedRoster = JSON.parse(data.roster);
            if (Array.isArray(parsedRoster)) {
              setRosterData(parsedRoster);
            } else {
              console.warn('Roster data is not an array:', parsedRoster);
              setRosterData([]);
            }
          } catch (jsonError) {
            console.error('Error parsing roster JSON:', jsonError);
            showToast('Error parsing roster data. Please re-upload your roster.', true);
            setRosterData([]);
          }
        } else {
          setRosterData([]);
        }
      }
    } catch (err) {
      setError('Failed to fetch social info');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setSocialInfo(prev => ({ ...prev, [name]: value }));
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const csv = event.target?.result as string;
        const parsedData = parseCSV(csv);
        if (validateRosterData(parsedData)) {
          setRosterData(parsedData);
          showToast('Roster uploaded and validated successfully!');
        } else {
          showToast('Invalid roster format. Please use the provided template.', true);
        }
      };
      reader.readAsText(file);
    }
  };

  const parseCSV = (csv: string): RosterEntry[] => {
    const lines = csv.split('\n');
    lines.shift();
    return lines
      .filter(line => line.trim() !== '')
      .map(line => {
        const [fullName, studentId] = line.split(',');
        return { fullName: fullName.trim(), studentId: studentId.trim() || null };
      });
  };

  const validateRosterData = (data: RosterEntry[]): boolean => {
    return data.every(entry => entry.fullName);
  };

  const handleSave = async () => {
    try {
      let rosterString = '';
      try {
        rosterString = JSON.stringify(rosterData);
      } catch (jsonError) {
        console.error('Error stringifying roster data:', jsonError);
        showToast('Error processing roster data. Please try again.', true);
        return;
      }

      const { data, error } = await supabase
        .from('organization_social_info')
        .upsert({
          organization_id: groupId,
          ...socialInfo,
          roster: rosterString
        }, {
          onConflict: 'organization_id'
        });

      if (error) throw error;

      showToast('Information Saved Successfully!');
    } catch (err) {
      console.error('Failed to save social info:', err);
      showToast('Failed to save social information. Please try again.', true);
    }
  };

  const handleAddEntry = () => {
    if (newFullName.trim()) {
      setRosterData([...rosterData, { fullName: newFullName.trim(), studentId: newStudentId.trim() || null }]);
      setNewFullName('');
      setNewStudentId('');
    } else {
      showToast('Full name is required.', true);
    }
  };

  const handleDeleteEntry = (index: number) => {
    setRosterData(rosterData.filter((_, i) => i !== index));
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="p-6 max-w-2xl mx-auto">
      <h2 className="text-2xl font-semibold text-gray-800 mb-6">Organization Settings</h2>
      <form className="space-y-4">
        <div>
          <label htmlFor="organization_name" className="block text-sm font-medium text-gray-700">Organization Name</label>
          <input
            type="text"
            id="organization_name"
            name="organization_name"
            value={socialInfo.organization_name}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>
        <div>
          <label htmlFor="cohost_email_address" className="block text-sm font-medium text-gray-700">Organization Name For Other Organizations</label>
          <input
            type="text"
            id="cohost_email_address"
            name="cohost_email_address"
            value={socialInfo.cohost_email_address}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>
        <div>
          <label htmlFor="contact_name" className="block text-sm font-medium text-gray-700">Contact Name</label>
          <input
            type="text"
            id="contact_name"
            name="contact_name"
            value={socialInfo.contact_name}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>
        <div>
          <label htmlFor="contact_email" className="block text-sm font-medium text-gray-700">Contact Email</label>
          <input
            type="email"
            id="contact_email"
            name="contact_email"
            value={socialInfo.contact_email}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>
        <div>
          <label htmlFor="event_location" className="block text-sm font-medium text-gray-700">Event Location</label>
          <input
            type="text"
            id="event_location"
            name="event_location"
            value={socialInfo.event_location}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>
        <div>
          <label htmlFor="venue_contact_name" className="block text-sm font-medium text-gray-700">Venue Contact Name</label>
          <input
            type="text"
            id="venue_contact_name"
            name="venue_contact_name"
            value={socialInfo.venue_contact_name}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>
        <div>
          <label htmlFor="venue_contact_phone" className="block text-sm font-medium text-gray-700">Venue Contact Phone</label>
          <input
            type="tel"
            id="venue_contact_phone"
            name="venue_contact_phone"
            value={socialInfo.venue_contact_phone}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>
        <div>
          <label htmlFor="venue_contact_email" className="block text-sm font-medium text-gray-700">Venue Contact Email</label>
          <input
            type="email"
            id="venue_contact_email"
            name="venue_contact_email"
            value={socialInfo.venue_contact_email}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>
        <div className="space-y-2">
          <h3 className="text-lg font-medium text-gray-700">Roster Management</h3>
          <p className="text-sm text-gray-600">
            Make a copy and then download the <a href="https://docs.google.com/spreadsheets/d/1abo7GZ31xpOIkoM9Du9-rSLKatKzxK9tNoOAUTnGDto/edit?usp=sharing" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">roster template</a> and fill it out before uploading.
          </p>
          <label htmlFor="roster-upload" className="block text-sm font-medium text-gray-700">Upload Roster (CSV)</label>
          <input
            type="file"
            id="roster-upload"
            accept=".csv"
            onChange={handleFileUpload}
            className="mt-1 block w-full text-sm text-gray-500
              file:mr-4 file:py-2 file:px-4
              file:rounded-md file:border-0
              file:text-sm file:font-semibold
              file:bg-indigo-50 file:text-indigo-700
              hover:file:bg-indigo-100"
          />
          <p className="text-sm text-gray-600 mt-1">
            Instructions: Upload a CSV file with columns for Full Name and Student ID.
          </p>
        </div>

        <div className="space-y-2">
          <h4 className="text-md font-medium text-gray-700">Add Entry Manually</h4>
          <div className="flex space-x-2">
            <input
              type="text"
              value={newFullName}
              onChange={(e) => setNewFullName(e.target.value)}
              placeholder="Full Name"
              className="flex-grow rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
            <input
              type="text"
              value={newStudentId}
              onChange={(e) => setNewStudentId(e.target.value)}
              placeholder="Student ID"
              className="flex-grow rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
            <button
              type="button"
              onClick={handleAddEntry}
              className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Add
            </button>
          </div>
        </div>

        {rosterData.length > 0 && (
          <div className="space-y-2">
            <h4 className="text-md font-medium text-gray-700">Current Roster</h4>
            <div className="grid grid-cols-3 gap-2 font-medium text-gray-700 bg-gray-100 p-2 rounded-md">
              <div>Full Name</div>
              <div>Student ID</div>
              <div>Action</div>
            </div>
            {rosterData.map((entry, index) => (
              <div key={index} className="grid grid-cols-3 gap-2 items-center p-2 border border-gray-200 rounded-md">
                <div>{entry.fullName}</div>
                <div>{entry.studentId || 'N/A'}</div>
                <div>
                  <button
                    type="button"
                    onClick={() => handleDeleteEntry(index)}
                    className="px-2 py-1 bg-red-600 text-white rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  >
                    Delete
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
        
        <div>
          <button
            type="button"
            onClick={handleSave}
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default SettingsPage;