import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { IoCloseOutline } from 'react-icons/io5';
import { ReactComponent as Home } from '../../Assets/Icons/home.svg';
import { ReactComponent as Settings } from '../../Assets/Icons/settings.svg';
import { ReactComponent as Support } from '../../Assets/Icons/support.svg';
import { ReactComponent as CustomGroupIcon } from '../../Assets/Icons/CustomGroupIcon.svg';
import Logo from '../../Assets/AgoraLogo.svg';
import { fetchDashOrgs } from '../../Contexts/supabaseService';
import { useAuth } from '../../Contexts/AuthContext';

interface NavLinkProps {
  to: string;
  children: React.ReactNode;
  icon: React.ReactElement;
}

const NavLink: React.FC<NavLinkProps> = ({ to, children, icon }) => {
  const location = useLocation();
  const isActive = location.pathname === to;

  return (
    <Link
      to={to}
      className={`flex items-center px-4 py-2 rounded-md my-2 text-[#5F6776] hover:bg-[#F2F2F7] ${
        isActive ? 'bg-[#F2F2F7]' : ''
      }`}
    >
      <div className="text-blue-600">{React.cloneElement(icon, { className: 'mr-2', size: 28 })}</div>
      <span className="text-lg">{children}</span>
    </Link>
  );
};

interface Group {
  organization_id: string;
  name: string;
  role: string;
}

interface SidebarProps {
  onClose: () => void;
  onJoinGroup: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ onClose, onJoinGroup }) => {
  const [groups, setGroups] = useState<Group[]>([]);
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        if (!user?.id) {
          console.error('User not authenticated');
          return;
        }
        const userGroups = await fetchDashOrgs(user.id);
        setGroups(userGroups);
      } catch (error) {
        console.error('Error fetching groups:', error);
      }
    };

    fetchGroups();
  }, [user]);

  const handleGroupClick = (group: Group) => {
    navigate(`/dashboard/${group.organization_id}`);
  };

  return (
    <div className="flex flex-col w-64 h-screen px-4 py-8 bg-white border-r border-gray-200" style={{ background: 'linear-gradient(to bottom right, #FFFFFF, #F7F7FB)' }}>
      <div className="flex items-center justify-between mb-8">
        <div className="flex items-center">
          <img src={Logo} alt="Agora Logo" className="w-12 h-auto" />
          <span className="ml-2 text-2xl font-bold">Agora</span>
        </div>
        <button onClick={onClose} className="lg:hidden">
          <IoCloseOutline size={24} />
        </button>
      </div>
      <div className="flex-grow">
        <NavLink to="/dashboard" icon={<Home />}>Dashboard</NavLink>
        <NavLink to="/settings" icon={<Settings />}>Settings</NavLink>
        <NavLink to="/feedback" icon={<Support />}>Support</NavLink>
        <div className="h-px my-4 bg-gray-200"></div>
        <div className="space-y-2">
          {groups.map((group) => (
            <button
              key={group.organization_id}
              className="relative flex items-center w-full px-4 py-2 text-sm font-medium text-left text-black bg-white rounded-md hover:bg-gray-100 group"
              onClick={() => handleGroupClick(group)}
            >
              <CustomGroupIcon className="mr-3" />
              <span className="truncate">{group.name}</span>
              <span className="absolute hidden p-2 ml-4 text-white bg-gray-800 rounded left-full group-hover:block">
                {group.name}
              </span>
            </button>
          ))}
        </div>
      </div>
      <button
        className="flex items-center justify-center w-full py-2 mt-4 text-sm font-medium text-blue-600 bg-white border rounded-md border-[#B1ABFF] hover:bg-blue-50"
        onClick={onJoinGroup}
      >
        <span className="mr-2 text-lg font-semibold">+</span>
        <span className="font-semibold text-black">Join Group</span>
      </button>
    </div>
  );
};

export default Sidebar;