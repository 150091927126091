import React, { useRef, FormEvent } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../Contexts/AuthContext';

export default function Login() {
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const { signIn } = useAuth();
  const navigate = useNavigate();

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();
    const email = emailRef.current?.value;
    const password = passwordRef.current?.value;

    if (!email || !password) {
      alert('Please fill in all fields.');
      return;
    }
    
    const { error } = await signIn({ email, password });

    if (error) {
      alert('Error logging in');
      console.log(error);
    } else {
      navigate('/dashboard');
    }
  }

  return (
    <div className="flex items-center justify-center min-h-screen bg-[#E4E6F2] p-4">
      <div className="w-full max-w-md p-6 rounded-lg shadow-sm backdrop-blur-md bg-white/40">
        <div className="flex flex-col justify-center w-full">
          <h2 className="mb-6 text-2xl font-bold text-center">Log In</h2>
          <form onSubmit={handleSubmit} className="flex flex-col space-y-4">
            <div>
              <label htmlFor="email" className="block mb-1 text-sm font-semibold">Email</label>
              <input
                id="email"
                type="email"
                ref={emailRef}
                placeholder="Enter your email"
                className="w-full px-4 py-2 border border-gray-300 rounded focus:ring focus:ring-blue-500 focus:outline-none"
              />
            </div>

            <div>
              <label htmlFor="password" className="block mb-1 text-sm font-semibold">Password</label>
              <input
                id="password"
                type="password"
                ref={passwordRef}
                placeholder="Enter your password"
                className="w-full px-4 py-2 border border-gray-300 rounded focus:ring focus:ring-blue-500 focus:outline-none"
              />
            </div>

            <div className="flex flex-col items-center justify-between mt-6 space-y-4 sm:flex-row sm:space-y-0">
              <Link to="/forgot-password" className="text-sm text-blue-600 hover:underline">Forgot password?</Link>
              <button
                type="submit"
                className="w-full px-6 py-2 text-white bg-blue-600 rounded-full sm:w-auto hover:bg-blue-700 focus:outline-none focus:ring focus:ring-blue-500"
              >
                Log In
              </button>
            </div>
          </form>
          <p className="mt-6 text-center">
            Don't have an account? <Link to="/signup" className="text-blue-600 hover:underline">Sign Up</Link>
          </p>
        </div>
      </div>
    </div>
  );
}