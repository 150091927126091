import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { fetchCampaignEvents, updateEventStatus } from '../../Contexts/supabaseService';
import { format } from 'date-fns';
import QRCode from 'react-qr-code';

interface Event {
  id: string; 
  name: string;
  date_time: string;
  event_over: boolean;
  track_attendance: boolean;
  campaigns: {
      id: string; 
      name: string;
  } | null;
}

interface PopupProps {
  event: Event;
  onClose: () => void;
  onToggleStatus: (eventId: string, newStatus: boolean) => void;
}

const EventPopup: React.FC<PopupProps> = ({ event, onClose, onToggleStatus }) => {
  const [copied, setCopied] = useState(false);
  const popupRef = useRef<HTMLDivElement>(null);
  const checkInUrl = `https://agoraprocessing.com/event/${event.id}/check-in`;

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(checkInUrl).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div ref={popupRef} className="w-full max-w-md p-8 bg-white rounded-lg shadow-2xl">
        <h2 className="mb-6 text-2xl font-bold text-gray-800">{event.name}</h2>
        <div className="flex justify-center mb-6">
          <QRCode value={checkInUrl} size={256} />
        </div>
        <p className="mb-6 text-center text-gray-600">Scan this QR code to check in to the event</p>
        <div className="flex items-center justify-between mb-6">
          <button
            onClick={() => onToggleStatus(event.id, !event.event_over)}
            className={`px-4 py-2 rounded-full font-semibold transition-colors duration-200 ${
              event.event_over 
                ? 'bg-green-500 hover:bg-green-600 text-white' 
                : 'bg-red-500 hover:bg-red-600 text-white'
            }`}
          >
            {event.event_over ? 'Activate Event' : 'End Event'}
          </button>
          <button
            onClick={copyToClipboard}
            className="px-4 py-2 font-semibold text-white transition-colors duration-200 bg-blue-500 rounded-full hover:bg-blue-600"
          >
            {copied ? 'Copied!' : 'Copy Link'}
          </button>
        </div>
        <button
          onClick={onClose}
          className="w-full px-4 py-2 font-semibold text-gray-800 transition-colors duration-200 bg-gray-200 rounded-full hover:bg-gray-300"
        >
          Close
        </button>
      </div>
    </div>
  );
};

const CampaignEvents: React.FC = () => {
  const [events, setEvents] = useState<Event[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);
  const { groupId } = useParams<{ groupId: string }>();

  useEffect(() => {
    const loadEvents = async () => {
      if (groupId) {
        try {
          const data = await fetchCampaignEvents(groupId);
          setEvents(data);
        } catch (error) {
          console.error('Failed to fetch events:', error);
        } finally {
          setLoading(false);
        }
      }
    };
  
    loadEvents();
  }, [groupId]);

  const handleEventClick = (event: Event) => {
    setSelectedEvent(event);
  };

  const handleClosePopup = () => {
    setSelectedEvent(null);
  };

  const handleToggleEventStatus = async (eventId: string, newStatus: boolean) => {
    try {
      await updateEventStatus(eventId, newStatus);
      setEvents(events.map(event => 
        event.id === eventId ? { ...event, event_over: newStatus } : event
      ));
      setSelectedEvent(prev => prev ? { ...prev, event_over: newStatus } : null);
    } catch (error) {
      console.error('Failed to update event status:', error);
    }
  };

  if (loading) {
    return <div className="flex items-center justify-center h-screen">Loading...</div>;
  }

  return (
    <div className="container px-4 py-8 mx-auto">
      <h1 className="mb-8 text-3xl font-bold">Campaign Events</h1>
      <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
        {events.map((event) => (
          <div 
            key={event.id} 
            className="overflow-hidden bg-white rounded-lg shadow-md cursor-pointer"
            onClick={() => handleEventClick(event)}
          >
            <div className="p-6">
              <h2 className="mb-2 text-xl font-semibold">{event.name}</h2>
              <p className="mb-4 text-gray-600">
                {format(new Date(event.date_time), 'MMMM d, yyyy h:mm a')}
              </p>
              <p className="mb-2 text-sm text-gray-500">
                Campaign: {event.campaigns?.name || 'N/A'}
              </p>
              <div className="flex items-center justify-between">
                <span className={`px-2 py-1 rounded-full text-xs ${
                  event.event_over ? 'bg-red-100 text-red-800' : 'bg-green-100 text-green-800'
                }`}>
                  {event.event_over ? 'Ended' : 'Active'}
                </span>
                {event.track_attendance && (
                  <span className="px-2 py-1 text-xs text-blue-800 bg-blue-100 rounded-full">
                    Attendance Tracked
                  </span>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      {selectedEvent && (
        <EventPopup 
          event={selectedEvent} 
          onClose={handleClosePopup}
          onToggleStatus={handleToggleEventStatus}
        />
      )}
    </div>
  );
};

export default CampaignEvents;