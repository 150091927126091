import React, { useRef, FormEvent, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../Contexts/AuthContext';

export default function ForgotPassword() {
  const emailRef = useRef<HTMLInputElement>(null);
  const { resetPassword } = useAuth();
  const [message, setMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();
    const email = emailRef.current?.value;

    if (!email) {
      setMessage('Please enter your email address.');
      return;
    }

    setIsLoading(true);
    setMessage('');

    try {
      const { error } = await resetPassword(email);
      if (error) {
        throw error;
      }
      setMessage('If an account for this email exists please check your inbox.');
    } catch (error) {
      setMessage('Error sending password reset email. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className="flex items-center justify-center h-screen bg-[#E4E6F2]">
      <div className="flex w-full max-w-4xl p-10 mx-auto my-auto space-x-10 rounded-lg shadow-sm backdrop-blur-md bg-white/40">
        <div className="hidden w-full md:block md:w-1/2">
        </div>
        <div className="flex flex-col justify-center w-1/2 p-6 mx-auto my-auto rounded-lg">
          <h2 className="mb-4 text-2xl font-bold">Forgot Password</h2>
          <form onSubmit={handleSubmit} className="flex flex-col space-y-4">
            <label htmlFor="email" className="text-sm font-semibold">Email</label>
            <input
              id="email"
              type="email"
              ref={emailRef}
              placeholder="Enter your email"
              className="px-4 py-2 border border-gray-300 rounded focus:ring focus:ring-blue-500 focus:outline-none"
            />

            <button
              type="submit"
              disabled={isLoading}
              className="px-6 py-2 text-white bg-blue-600 rounded-full hover:bg-blue-700 focus:outline-none focus:ring focus:ring-blue-500 disabled:opacity-50"
            >
              {isLoading ? 'Sending...' : 'Reset Password'}
            </button>
          </form>
          {message && <p className="mt-4 text-sm text-center">{message}</p>}
          <div className="flex items-center justify-between mt-4">
            <Link to="/login" className="text-sm text-blue-600 hover:underline">Back to Login</Link>
          </div>
        </div>
      </div>
    </div>
  );
}