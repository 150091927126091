import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../../Contexts/AuthContext';
import { invokeInviteRPC } from '../../Contexts/supabaseRPC';

interface JoinGroupModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const JoinGroupModal: React.FC<JoinGroupModalProps> = ({ isOpen, onClose }) => {
  const [inviteCode, setInviteCode] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const { user } = useAuth();
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  const handleJoinGroup = async () => {
    if (!user?.id) {
      console.error('User not authenticated');
      return;
    }

    try {
      const data = await invokeInviteRPC(inviteCode, user.id);
      if (data.status === 'success') {
        setMessage('Successfully joined the organization');
        onClose();
        window.location.reload();
      } else {
        setMessage(data.message);
      }
    } catch (error) {
      console.error('Error using invite:', error);
      setMessage('An error occurred while trying to join the group');
    }

    setInviteCode('');
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div ref={modalRef} className="w-full max-w-md p-6 bg-white rounded-lg shadow-xl sm:w-96">
        <h2 className="mb-4 text-2xl font-bold text-gray-800">Join Group</h2>
        <input
          type="text"
          placeholder="Enter invite code"
          value={inviteCode}
          onChange={(e) => setInviteCode(e.target.value)}
          className="w-full px-4 py-2 mb-4 text-gray-700 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        {message && <p className="mb-4 text-sm text-red-500">{message}</p>}
        <div className="flex justify-end space-x-2">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-200 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500"
          >
            Cancel
          </button>
          <button
            onClick={handleJoinGroup}
            className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Join
          </button>
        </div>
      </div>
    </div>
  );
};

export default JoinGroupModal;