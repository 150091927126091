import React from 'react';
import LandingFeature1 from '../../../../Assets/LandingFeature1.svg';

const Feature1: React.FC = () => {
  return (
    <section className="py-20">
      <div className="container mx-auto px-6">
        <div className="text-center md:w-2/3 mx-auto">
          <h2 className="text-3xl font-bold mb-4">Your Dashboard</h2>
          <p className="text-gray-600 mb-8">
            Manage your organization’s interest and applicants seamlessly after capturing their contact information above.
          </p>
        </div>
        <div className="flex justify-center">
          <div className="flex justify-center items-center relative p-2 rounded-lg">
            <img
              src={LandingFeature1}
              alt="Dashboard Preview"
              className="rounded-lg border-4 border-transparent animate-gradient-multi w-full sm:w-3/4 md:w-2/3 lg:w-70%"
              style={{ height: 'auto' }} 
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Feature1;
