import React from 'react';
import { useLocation } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';
import PaymentItem from './PaymentItem';
import OrderSummary from './OrderSummary';

const stripePromise = loadStripe('pk_live_51OjRt8JaLwIxLZCHSm4dwSRT8ZOTr9ZpTadvSGIYyqLaMK70R6Ncl81fItlysyIb7mOaMSXIDz3zbCaFQwtZQhxo00Nplr6fMb');

interface SelectedItem {
  products: {
    name: string;
  };
  amount: number;
  due_date: string;
}

interface LocationState {
  clientSecret: string;
  selectedItems: SelectedItem[];
}

const CheckoutPage: React.FC = () => {
  const location = useLocation();
  const state = location.state as LocationState;

  const options = state?.clientSecret
    ? {
        clientSecret: state.clientSecret,
        appearance: {
          theme: 'stripe' as 'stripe',
        },
      }
    : null;

  const paymentItems = state?.selectedItems.map((item: SelectedItem) => ({
    productName: item.products.name,
    amount: item.amount,
    dueDate: item.due_date,
  })) || [];

  const amounts = state?.selectedItems.map((item: SelectedItem) => item.amount) || [];

  return (
    <div className="min-h-screen p-8 bg-background">
      <div className="p-8 bg-white rounded-lg shadow-md">
        <h1 className="mb-4 text-2xl font-bold">Checkout</h1>
        <div className="flex flex-col justify-between p-4 space-x-0 md:flex-row md:space-x-4">
          <div className="w-full space-y-4">
            {paymentItems.map((item, index) => (
              <PaymentItem key={index} productName={item.productName} amount={item.amount} dueDate={item.dueDate} />
            ))}
          </div>
          <div className="flex justify-center w-full mt-4 md:mt-0 md:w-auto">
            <OrderSummary amounts={amounts} className="w-full md:w-auto" />
          </div>
        </div>
        {options ? (
          <Elements stripe={stripePromise} options={options}>
            <CheckoutForm />
          </Elements>
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </div>
  );
};

export default CheckoutPage;
