import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import { checkAddApplicantVisibility } from '../../../Contexts/supabaseRecruitment';
import { supabase } from '../../../supabase';
import { FiChevronDown } from 'react-icons/fi';

import AcceptedIcon from '../../../../src/Assets/Icons/acceptedrecruitmenticons.svg';
import DecisionsIcon from '../../../../src/Assets/Icons/decisionsrecruitmenticons.svg';
import InterviewIcon from '../../../../src/Assets/Icons/interviewrecruitmenticons.svg';
import ApplicantsIcon from '../../../../src/Assets/Icons/applicantrecruitmenticons.svg';
import InterestIcon from '../../../../src/Assets/Icons/interestrecruitmenticons.svg';

export interface RecruitTopHeaderProps {
  campaignDetails: {
    id: string;
    name: string;
  } | null;
  userInCommittee: boolean;
  stageAccessRoles: {
    [stage: string]: string[] | undefined;
  } | null;
}

interface StageIconProps {
  icon: string;
  isActive: boolean;
}

const StageIcon: React.FC<StageIconProps> = ({ icon, isActive }) => (
  <img 
    src={icon} 
    alt="Stage icon" 
    className={`w-5 h-5 ${isActive ? 'text-blue-500' : 'text-gray-400'}`}
  />
);

const RecruitTopHeader: React.FC<RecruitTopHeaderProps> = ({ campaignDetails, userInCommittee, stageAccessRoles }) => {
  const { groupId } = useParams<{ groupId: string }>();
  const location = useLocation();
  const user_id = localStorage.getItem('userId');
  const [hasInterestStage, setHasInterestStage] = useState<boolean>(false);
  const [userRoles, setUserRoles] = useState<string[]>([]);
  const [reviewAccess, setReviewAccess] = useState<string[]>([]);
  const [campaignTransparency, setCampaignTransparency] = useState<{
    interest_stage_visible: boolean;
    applicant_stage_visible: boolean;
    interview_stage_visible: boolean;
    decision_stage_visible: boolean;
  } | null>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedStage, setSelectedStage] = useState<string | null>(null);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const isActiveStage = useCallback((stage: string) => {
    return location.pathname.includes(`/${stage.toLowerCase()}`);
  }, [location.pathname]);

  const stages = [
    { name: 'Interest', icon: InterestIcon, visible: campaignTransparency?.interest_stage_visible && hasInterestStage },
    { name: 'Applicants', icon: ApplicantsIcon, visible: campaignTransparency?.applicant_stage_visible },
    { name: 'Interview', icon: InterviewIcon, visible: campaignTransparency?.interview_stage_visible },
    { name: 'Decisions', icon: DecisionsIcon, visible: campaignTransparency?.decision_stage_visible },
    { name: 'Accepted', icon: AcceptedIcon, visible: campaignTransparency?.decision_stage_visible }
  ];

  useEffect(() => {
    const fetchUserRolesAndTransparency = async () => {
      if (campaignDetails) {
        const { data: rolesData, error: rolesError } = await supabase
          .from('user_organization_roles')
          .select('role_id')
          .eq('group_id', groupId)
          .eq('user_id', user_id);

        if (rolesError) {
          console.error('Error fetching user roles:', rolesError);
        } else {
          setUserRoles(rolesData.map(role => role.role_id));
        }

        const { data: transparencyData, error: transparencyError } = await supabase
          .from('campaign_transparency')
          .select('*')
          .eq('campaign_id', campaignDetails.id)
          .single();
        
        if (transparencyError) {
          console.error('Error fetching campaign transparency:', transparencyError);
        } else {
          setCampaignTransparency(transparencyData);
        }

        const { data: reviewAccessData, error: reviewAccessError } = await supabase
          .from('review_access')
          .select('role_id')
          .eq('campaign_id', campaignDetails.id);

        if (reviewAccessError) {
          console.error('Error fetching review access:', reviewAccessError);
        } else {
          setReviewAccess(reviewAccessData.map(access => access.role_id));
        }

        const hasInterest = await checkAddApplicantVisibility(campaignDetails.id);
        setHasInterestStage(hasInterest);
      }
    };

    fetchUserRolesAndTransparency();
  }, [campaignDetails, groupId, user_id]);

  useEffect(() => {
    const currentStage = stages.find(stage => isActiveStage(stage.name));
    if (currentStage) {
      setSelectedStage(currentStage.name);
    }
  }, [location.pathname, stages, isActiveStage]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  if (!campaignDetails || !stageAccessRoles || !campaignTransparency) {
    return null;
  }

  const hasReviewAccess = userRoles.some(role => reviewAccess.includes(role));

  const hasAccessToStage = (stage: string) => {
    if (userInCommittee || hasReviewAccess) return true;
    if (!stageAccessRoles || !stageAccessRoles[stage]) return false;
    const stageRoles = stageAccessRoles[stage];
    return Array.isArray(stageRoles) && userRoles.some(role => stageRoles.includes(role));
  };

  const handleStageSelect = (stage: string) => {
    setSelectedStage(stage);
    setIsDropdownOpen(false);
  };

  return (
    <div className="w-full">
      <div className="hidden md:flex items-center px-4 py-2 space-x-4">
        {stages.map((stage) => {
          if (stage.visible && hasAccessToStage(stage.name)) {
            return (
              <Link 
                key={stage.name}
                to={`/dashboard/${groupId}/recruitment/${campaignDetails.id}/${stage.name.toLowerCase()}`}
                className={`flex items-center space-x-2 px-4 py-2 rounded-md transition-colors ${
                  isActiveStage(stage.name)
                    ? 'bg-white text-gray-900'
                    : 'text-gray-600 hover:text-gray-900'
                }`}
              >
                <StageIcon icon={stage.icon} isActive={isActiveStage(stage.name)} />
                <span className="text-sm font-medium">{stage.name}</span>
              </Link>
            );
          }
          return null;
        })}
      </div>

      <div className="md:hidden relative p-4" ref={dropdownRef}>
        <div className="relative"> 
          <button
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            className="flex items-center justify-between w-full px-4 py-3 bg-white border border-gray-300 rounded-md shadow-sm"
          >
            <span className="flex items-center space-x-2">
              <StageIcon icon={stages.find(s => s.name === selectedStage)?.icon || ''} isActive={true} />
              <span className="text-base font-medium">{selectedStage || 'Select Stage'}</span> 
            </span>
            <FiChevronDown className={`w-6 h-6 transition-transform ${isDropdownOpen ? 'transform rotate-180' : ''}`} />
          </button>
          {isDropdownOpen && (
            <div className="absolute z-10 w-full mt-2 bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-y-auto">
              {stages.map((stage) => {
                if (stage.visible && hasAccessToStage(stage.name)) {
                  return (
                    <Link
                      key={stage.name}
                      to={`/dashboard/${groupId}/recruitment/${campaignDetails.id}/${stage.name.toLowerCase()}`}
                      onClick={() => handleStageSelect(stage.name)}
                      className={`flex items-center space-x-3 px-4 py-3 hover:bg-gray-100 ${
                        isActiveStage(stage.name) ? 'bg-gray-100' : ''
                      }`}
                    >
                      <StageIcon icon={stage.icon} isActive={isActiveStage(stage.name)} />
                      <span className="text-base font-medium">{stage.name}</span> 
                    </Link>
                  );
                }
                return null;
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RecruitTopHeader;