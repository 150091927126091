import React, { useState, useEffect } from 'react';
import { FaCheck } from 'react-icons/fa';
import { Outlet, Link, useLocation, useParams } from 'react-router-dom';
import { supabase } from '../../supabase'; 

interface Deadline {
  id: string;
  organization_id: string;
  title: string;
  due_date: string;
  description: string; 
}

const PortalComponent: React.FC = () => {
  const [deadlines, setDeadlines] = useState<Deadline[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { groupId } = useParams();

  useEffect(() => {
    fetchDeadlines();
  }, []);

  const fetchDeadlines = async () => {
    if (!groupId) {
      setError('Group ID not found in local storage');
      setLoading(false);
      return;
    }

    try {
      const { data, error } = await supabase.rpc('get_org_pending_deadlines', {
        org_id: groupId
      });

      if (error) throw error;

      setDeadlines(data || []);
      setError(null);
    } catch (error) {
      console.error('Error fetching deadlines:', error);
      setError('Failed to fetch deadlines. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleApprove = async (deadlineId: string) => {
    const userId = localStorage.getItem('userId');
    if (!userId) {
      console.error('User ID not found in local storage');
      return;
    }

    try {
      const { error } = await supabase.rpc('approve_deadline', {
        deadline_id: deadlineId,
        user_id: userId
      });

      if (error) throw error;

      await fetchDeadlines();
    } catch (error) {
      console.error('Error approving deadline:', error);
    }
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const month = date.toLocaleString('default', { month: 'short' });
    const day = date.getDate();
    return { month, day };
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6 text-gray-800">Pending Deadlines</h1>
      {deadlines.length === 0 ? (
        <p>No pending deadlines at the moment.</p>
      ) : (
        <div className="space-y-6">
          {deadlines.map((deadline) => {
            const { month, day } = formatDate(deadline.due_date);
            return (
              <div key={deadline.id} className="bg-white shadow-md rounded-lg p-6 flex justify-between items-center">
                <div className="flex-grow">
                  <h2 className="text-xl font-bold text-gray-800">{deadline.title}</h2>
                  <p className="text-gray-600 mt-2">{deadline.description}</p> 
                </div>
                <div className="flex items-center space-x-4">
                  <div className="text-center">
                    <div className="text-sm font-semibold text-gray-500">{month}</div>
                    <div className="text-2xl font-bold text-gray-700">{day}</div>
                  </div>
                  <button 
                    onClick={() => handleApprove(deadline.id)}
                    className="bg-green-500 text-white p-2 rounded-md hover:bg-green-600 transition duration-300"
                  >
                    <FaCheck />
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default PortalComponent;
