import React, { useState, useEffect, useRef, useMemo } from 'react';
import { NavLink, Outlet, useLocation, Navigate } from 'react-router-dom';

const PortalManagement: React.FC = () => {
  const location = useLocation();
  const tabsRef = useRef<(HTMLAnchorElement | null)[]>([]);

  const tabs = useMemo(() => [
    { name: 'Pending Deadlines', path: 'pending' },
    { name: 'Done Deadlines', path: 'done' },
  ], []);

  const [activeTab, setActiveTab] = useState('');
  const [highlightStyle, setHighlightStyle] = useState({ left: 0, width: 0 });

  useEffect(() => {
    const currentPath = location.pathname.split('/').pop();
    const matchingTab = tabs.find(tab => tab.path === currentPath);
    const newActiveTab = matchingTab ? matchingTab.path : tabs[0]?.path || '';
    
    if (newActiveTab !== activeTab) {
      setActiveTab(newActiveTab);
    }
  }, [location.pathname, tabs, activeTab]);

  useEffect(() => {
    const updateHighlight = () => {
      const activeIndex = tabs.findIndex(tab => tab.path === activeTab);
      if (activeIndex !== -1 && tabsRef.current[activeIndex]) {
        const activeElement = tabsRef.current[activeIndex];
        if (activeElement) {
          setHighlightStyle({
            left: activeElement.offsetLeft,
            width: activeElement.offsetWidth,
          });
        }
      }
    };

    updateHighlight();
    window.addEventListener('resize', updateHighlight);
    return () => window.removeEventListener('resize', updateHighlight);
  }, [activeTab, tabs]);

  if (location.pathname === '/portal') {
    return <Navigate to="/portal/pending" replace />;
  }

  return (
    <div className="p-4">
      <div className="flex mb-4 space-x-4 bg-white rounded-lg shadow-sm border border-agoraOutline">
        {tabs.map((tab) => (
          <NavLink
            key={tab.path}
            to={tab.path}
            ref={el => tabsRef.current[tabs.findIndex(t => t.path === tab.path)] = el}
            className={({ isActive }) =>
              `px-4 py-2 ${
                isActive ? 'text-primaryButtonBg border-b-2 border-primaryButtonBg' : 'text-componentText'
              }`
            }
          >
            {tab.name}
          </NavLink>
        ))}
      </div>
      <Outlet />
    </div>
  );
};

export default PortalManagement;