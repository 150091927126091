import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { supabase } from '../../supabase';

interface Organization {
  id: string;
  name: string;
  rosterStatus: 'Request' | 'Approved' | 'Requested';
  contactStatus: 'Request' | 'Approved' | 'Requested';
}

type StatusType = 'Request' | 'Approved' | 'Requested';

const StatusButton: React.FC<{ 
  status: StatusType, 
  onClick: () => void 
}> = ({ status, onClick }) => {
  let bgColor = 'bg-gray-400';
  let isDisabled = true;
  let displayText: string = status;

  if (status === 'Request') {
    bgColor = 'bg-purple-600';
    isDisabled = false;
    displayText = 'Request';
  } else if (status === 'Approved') {
    bgColor = 'bg-green-500';
  } else if (status === 'Requested') {
    bgColor = 'bg-orange-500';
  }

  return (
    <button 
      onClick={onClick}
      disabled={isDisabled}
      className={`${bgColor} text-white text-xs font-semibold px-3 py-1 rounded-md ml-2 ${isDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
    >
      {displayText}
    </button>
  );
};

const OrganizationCards: React.FC = () => {
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { groupId } = useParams<{ groupId: string }>();

  useEffect(() => {
    fetchOrganizations();
  }, [groupId]);

  const fetchOrganizations = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('organizations')
        .select(`
          organization_id,
          name,
          social_request_permissions!social_request_permissions_requested_organization_id_fkey (
            roster_status,
            contact_status
          )
        `)
        .eq('is_greek', true)
        .eq('college_id', 1)
        .neq('organization_id', groupId);

      if (error) throw error;

      const formattedData: Organization[] = data.map((org: any) => ({
        id: org.organization_id,
        name: org.name,
        rosterStatus: org.social_request_permissions[0]?.roster_status || 'Request',
        contactStatus: org.social_request_permissions[0]?.contact_status || 'Request',
      }));

      setOrganizations(formattedData);
    } catch (err) {
      setError('Failed to fetch organizations');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const updateStatus = async (orgId: string, type: 'roster' | 'contact') => {
    try {
      const { data, error } = await supabase
        .from('social_request_permissions')
        .upsert(
          { 
            initiator_organization_id: groupId,
            requested_organization_id: orgId,
            [`${type}_status`]: 'Requested'
          },
          { onConflict: 'initiator_organization_id,requested_organization_id' }
        );

      if (error) throw error;

      setOrganizations(orgs => orgs.map(org => 
        org.id === orgId 
          ? { ...org, [`${type}Status`]: 'Requested' as StatusType }
          : org
      ));
    } catch (err) {
      console.error('Failed to update status:', err);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="p-6">
      <h2 className="text-2xl font-semibold text-gray-800 mb-6">Organizations</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {organizations.map((org) => (
          <div key={org.id} className="bg-white rounded-lg shadow-sm border border-gray-200 p-4">
            <h3 className="text-lg font-semibold text-gray-800 mb-2">{org.name}</h3>
            <div className="flex justify-between items-center mb-2">
              <span className="text-gray-600">Roster</span>
              <StatusButton 
                status={org.rosterStatus} 
                onClick={() => org.rosterStatus === 'Request' && updateStatus(org.id, 'roster')}
              />
            </div>
            <div className="flex justify-between items-center">
              <span className="text-gray-600">Contact</span>
              <StatusButton 
                status={org.contactStatus}
                onClick={() => org.contactStatus === 'Request' && updateStatus(org.id, 'contact')}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OrganizationCards;