import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { supabase } from '../../../supabase';
import AgoraLogo from '../../../Assets/AgoraLogo.svg';
import { FaShare } from 'react-icons/fa';
import ApplicationForm from './ApplicationPopup';

import { ReactComponent as EmailIcon } from '../../../Assets/Icons/mail.svg';
import { ReactComponent as PhoneIcon } from '../../../Assets/Icons/call.svg';
import { ReactComponent as PersonIcon } from '../../../Assets/Icons/user.svg';
import { ReactComponent as InstagramIcon } from '../../../Assets/Icons/ig.svg';

interface InformationBoxProps {
  title: string;
  subtext: string;
}

interface YouTubeVideoProps {
  url: string;
}

interface OrgDetails {
  is_public: boolean;
  show_college_name?: boolean;
  build_page: {
    type: string;
    title?: string;
    subtext?: string;
    url?: string;
  }[];
  contact_info: {
    email?: string;
    phone?: string;
    name?: string;
  };
  social_info: {
    instagram?: string;
  };
  organizations: {
    name: string;
    colleges: {
      descriptor: string;
    };
  };
  recruitment_info?: string | null;
  has_interest_stage: boolean;
}

const Header = () => (
  <header className="bg-white shadow-md">
    <div className="container flex items-center justify-between px-6 py-4 mx-auto">
      <div className='flex flex-row items-center'>
        <Link to="/">
          <img src={AgoraLogo} alt="Agora Logo" className="h-8" />
        </Link>
        <h1 className="ml-4 text-3xl font-bold">Agora</h1>
      </div>
      <div>
        <Link to="/dashboard" className="px-4 py-2 text-blue-500 transition duration-300 border border-blue-500 rounded hover:bg-blue-500 hover:text-white">Visit Home</Link>
      </div>
    </div>
  </header>
);

const InformationBox: React.FC<InformationBoxProps> = ({ title, subtext }) => (
  <div className="mb-8">
    <h2 className="mb-1 text-xl font-semibold">{title}</h2>
    <p className="text-base">{subtext}</p>
  </div>
);

const YouTubeVideo: React.FC<YouTubeVideoProps> = ({ url }) => {
  const getEmbeddableUrl = (url: string) => {
    const urlObj = new URL(url);
    let videoId = urlObj.searchParams.get("v");
    if (!videoId && urlObj.hostname === "youtu.be") {
      videoId = urlObj.pathname.slice(1);
    }
    return `https://www.youtube-nocookie.com/embed/${videoId}?rel=0`;
  };

  const embeddableUrl = getEmbeddableUrl(url);

  return (
    <div className="mb-8 aspect-w-16 aspect-h-9">
      <iframe
        className="w-full h-full rounded-xl"
        src={embeddableUrl}
        title="YouTube Video"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
};

const PublicPage: React.FC = () => {
  const { collegename, groupId } = useParams<{ collegename: string, groupId: string }>();
  const navigate = useNavigate();
  const [orgDetails, setOrgDetails] = useState<OrgDetails | null>(null);
  const [showApplicationForm, setShowApplicationForm] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const { data: organizationData, error: organizationError } = await supabase
          .from('organization_pages')
          .select(`
            *,
            organizations (
              college_id,
              name,
              colleges (
                descriptor
              )
            ),
            campaigns (
              has_interest_stage
            )
          `)
          .eq('organization_id', groupId)
          .single();

        if (organizationError) throw organizationError;

        if (!organizationData.is_public) {
          setOrgDetails({
            is_public: false,
            build_page: [],
            contact_info: {},
            social_info: {},
            organizations: { name: '', colleges: { descriptor: '' } },
            recruitment_info: null,
            has_interest_stage: false
          });
        } else if (organizationData.organizations.colleges.descriptor !== collegename) {
          navigate(`/${organizationData.organizations.colleges.descriptor}/${groupId}`);
        } else {
          setOrgDetails({
            ...organizationData,
            has_interest_stage: organizationData.campaigns?.has_interest_stage ?? false
          });
        }
      } catch (error) {
        console.error('Error fetching organization details:', error);
        setError('Failed to load organization details. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchDetails();
  }, [collegename, groupId, navigate]);

  if (isLoading) return <div className="flex items-center justify-center min-h-screen">Loading...</div>;
  if (error) return <div className="flex items-center justify-center min-h-screen text-red-500">{error}</div>;
  if (!orgDetails) return <div className="flex items-center justify-center min-h-screen">No organization details found.</div>;
  if (!orgDetails.is_public) return <div className="flex items-center justify-center min-h-screen">Contact organization owners to make their page public.</div>;

  return (
    <>
      <Header />
      <div className="flex flex-col items-center min-h-screen bg-gray-100">
        <div className="container flex flex-col p-4 mx-auto md:p-8 md:flex-row">
          <div className="w-full p-6 mb-8 bg-white rounded-lg shadow-lg md:w-4/5 md:p-8 md:mb-0 md:mr-8">
            <div className="flex flex-col items-start justify-between mb-8 md:flex-row md:items-center">
              <h1 className="mb-2 text-3xl font-bold text-gray-800 md:text-5xl md:mb-0">{orgDetails.organizations.name}</h1>
              {orgDetails.show_college_name && (
                <p className="text-lg text-gray-600 md:text-xl">@{collegename}</p>
              )}
            </div>
            {orgDetails.build_page.map((item, index) => (
              <div key={index} className="mb-8">
                {item.type === 'Information Box' && <InformationBox title={item.title || ''} subtext={item.subtext || ''} />}
                {item.type === 'YouTube Video' && <YouTubeVideo url={item.url || ''} />}
              </div>
            ))}
          </div>
          <div className="w-full md:w-1/3">
            <div className="p-6 mb-4 bg-white rounded-lg shadow-lg">
              <h2 className="mb-4 text-2xl font-bold text-black">Contact</h2>
              {orgDetails.contact_info.email && (
                <div className="flex items-center mb-2">
                  <EmailIcon className="w-5 h-5 mr-2"/>
                  <span className="font-semibold text-black">Email :</span>
                  <span className="ml-1 text-gray-600">{orgDetails.contact_info.email}</span>
                </div>
              )}
              {orgDetails.contact_info.phone && (
                <div className="flex items-center mb-2">
                  <PhoneIcon className="w-5 h-5 mr-2 "/>
                  <span className="font-semibold text-black">Phone :</span>
                  <span className="ml-1 text-gray-600">{orgDetails.contact_info.phone}</span>
                </div>
              )}
              {orgDetails.contact_info.name && (
                <div className="flex items-center">
                  <PersonIcon className="w-5 h-5 mr-2"/>
                  <span className="font-semibold text-black">Name :</span>
                  <span className="ml-1 text-gray-600">{orgDetails.contact_info.name}</span>
                </div>
              )}
            </div>
            <div className="p-6 mb-4 bg-white rounded-lg shadow-lg">
              <h2 className="mb-4 text-2xl font-bold">Socials</h2>
              {orgDetails.social_info.instagram && (
                <div className="flex items-center">
                  <InstagramIcon className="w-5 h-5 mr-2" />
                  <span className="font-semibold text-black">Instagram :</span>
                  <a 
                    href={`https://www.instagram.com/${orgDetails.social_info.instagram}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ml-1 text-blue-600 hover:underline"
                  >
                    @{orgDetails.social_info.instagram}
                  </a>
                </div>
              )}
            </div>
            {orgDetails.recruitment_info && (
              <div className="hidden p-6 bg-white rounded-lg shadow-lg md:p-8 md:block">
                <h2 className="mb-4 text-2xl font-semibold">Join Us</h2>
                <button 
                  className="flex items-center justify-center w-full px-4 py-2 text-white transition duration-300 bg-[#4338CA] rounded hover:bg-gray-800"
                  onClick={() => setShowApplicationForm(true)}
                >
                  <FaShare className="mr-2" />
                  Join Now!
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="pb-20 md:pb-0"></div>
        {orgDetails.recruitment_info && (
          <div className="fixed bottom-0 left-0 right-0 p-4 bg-white border-t border-[#D2D1D1] rounded-lg shadow-lg md:hidden">
            <button 
              className="flex items-center justify-center w-full px-4 py-2 text-white transition duration-300 bg-[#4338CA] rounded hover:bg-gray-800"
              onClick={() => setShowApplicationForm(true)}
            >
              {/* <FaShare className="mr-2" /> */}
              Join Now!
            </button>
          </div>
        )}
      </div>
      {showApplicationForm && orgDetails.recruitment_info && (
        <ApplicationForm 
          campaignId={orgDetails.recruitment_info} 
          hasInterestStage={orgDetails.has_interest_stage}
          onClose={() => setShowApplicationForm(false)}
        />
      )}
    </>
  );
};

export default PublicPage;