import React, { useState, useEffect } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import {
  addCommittee,
  fetchOrganizationMembers,
  addCommitteeMembers,
  fetchCampaigns
} from '../../Contexts/supabaseRecruitment';
import { showToast } from '../../Toast';

interface Member {
  user_id: string;
  first_name: string;
  last_name: string;
}

interface Campaign {
  id: string;
  name: string;
}

const CreateCommittee: React.FC = () => {
  const [committeeName, setCommitteeName] = useState('');
  const [members, setMembers] = useState<Member[]>([]);
  const [selectedMembers, setSelectedMembers] = useState<string[]>([]);
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [selectedCampaign, setSelectedCampaign] = useState('');
  const { groupId } = useParams<{ groupId: string }>();

  useEffect(() => {
    if (!groupId) return;

    const fetchData = async () => {
      try {
        const membersData = await fetchOrganizationMembers(groupId);
        setMembers(membersData);

        const campaignsData = await fetchCampaigns(groupId);
        setCampaigns(campaignsData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [groupId]);

  const handleAddCommittee = async () => {
    if (!groupId) {
      console.error('No groupId provided');
      return;
    }

    try {
      const committee = await addCommittee(groupId, committeeName, selectedCampaign);
      if (committee && committee.length > 0) {
        const committeeId = committee[0].committee_id;

        if (selectedMembers.length > 0) {
          await addCommitteeMembers(committeeId, selectedMembers.map(memberId => ({ user_id: memberId })));
        }

        showToast('Committee added successfully');
        setCommitteeName('');
        setSelectedMembers([]);
        setSelectedCampaign('');
      } else {
        throw new Error('Committee creation failed');
      }
    } catch (error) {
      console.error('Error adding committee:', error);
      showToast('Failed to add committee', true);
    }
  };

  if (!groupId) {
    return <Navigate to="/some-error-page" />;
  }

  return (
    <div className="p-6 bg-gray-100 rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold mb-4">Create Committee</h2>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Committee Name</label>
        <input
          type="text"
          className="mt-1 p-2 w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
          value={committeeName}
          onChange={(e) => setCommitteeName(e.target.value)}
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Select Campaign</label>
        <select
          className="mt-1 p-2 w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
          value={selectedCampaign}
          onChange={(e) => setSelectedCampaign(e.target.value)}
        >
          <option value="">Select a campaign</option>
          {campaigns.map(campaign => (
            <option key={campaign.id} value={campaign.id}>
              {campaign.name}
            </option>
          ))}
        </select>
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Select Committee Members</label>
        <select
          multiple
          className="mt-1 p-2 w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
          value={selectedMembers}
          onChange={(e) => setSelectedMembers(Array.from(e.target.selectedOptions).map(option => option.value))}
        >
          {members.map(member => (
            <option key={member.user_id} value={member.user_id}>
              {member.first_name} {member.last_name}
            </option>
          ))}
        </select>
      </div>
      <button
        className="w-full py-2 px-4 bg-blue-600 text-white font-semibold rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        onClick={handleAddCommittee}
      >
        Save Committee
      </button>
    </div>
  );
};

export default CreateCommittee;