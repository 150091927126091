import React, { useState, useEffect } from 'react';
import {
  addCommittee,
  fetchOrganizationMembers,
  addCommitteeMembers,
  fetchOrganizationCommittees,
  fetchCommitteeMembers,
  fetchCampaigns
} from '../../Contexts/supabaseRecruitment';
import { useParams, Navigate } from 'react-router-dom';
import { showToast } from '../../Toast';

interface Member {
  user_id: string;
  first_name: string;
  last_name: string;
}

interface Role {
  role_id: string;
  role_name: string;
}

interface Committee {
  committee_id: string;
  committee_name: string;
}

interface Campaign {
  id: string;
  name: string;
  created_at: string;
  has_interest_stage: boolean;
  organization_id: string;
}

interface ExpandedCommittee {
  committeeId: string;
  committeeMembers: Member[];
}

const CommitteesComponent = () => {
  const [committeeName, setCommitteeName] = useState('');
  const [members, setMembers] = useState<Member[]>([]);
  const [selectedMembers, setSelectedMembers] = useState<string[]>([]);
  const [committees, setCommittees] = useState<Committee[]>([]);
  const [expandedCommittee, setExpandedCommittee] = useState<ExpandedCommittee | null>(null);
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [selectedCampaign, setSelectedCampaign] = useState('');
  const { groupId } = useParams<{ groupId: string }>();

  useEffect(() => {
    if (!groupId) return;

    const fetchData = async () => {
      try {
        const membersData = await fetchOrganizationMembers(groupId);
        setMembers(membersData);

        const committeesData = await fetchOrganizationCommittees(groupId);
        setCommittees(committeesData);

        const campaignsData = await fetchCampaigns(groupId);
        console.log("Campaigns Data: ", campaignsData);
        setCampaigns(campaignsData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [groupId]);

  const handleAddCommittee = async () => {
    if (!groupId) {
      console.error('No groupId provided');
      return;
    }

    try {
      const committee = await addCommittee(groupId, committeeName, selectedCampaign);
      if (committee && committee.length > 0) {
        const committeeId = committee[0].committee_id;

        if (selectedMembers.length > 0) {
          await addCommitteeMembers(committeeId, selectedMembers.map(memberId => ({ user_id: memberId })));
        }

        showToast('Committee added successfully');
        setCommitteeName('');
        setSelectedMembers([]);
        setSelectedCampaign('');

        const updatedCommittees = await fetchOrganizationCommittees(groupId);
        setCommittees(updatedCommittees);
      } else {
        throw new Error('Committee creation failed');
      }
    } catch (error) {
      console.error('Error adding committee:', error);
      showToast('Failed to add committee', true);
    }
  };

  const toggleExpandCommittee = async (committeeId: string) => {
    if (expandedCommittee && expandedCommittee.committeeId === committeeId) {
      setExpandedCommittee(null);
    } else {
      try {
        const committeeMembers = await fetchCommitteeMembers(committeeId);
        setExpandedCommittee({ committeeId, committeeMembers });
      } catch (error) {
        console.error('Error fetching committee details:', error);
      }
    }
  };

  if (!groupId) {
    return <Navigate to="/some-error-page" />;
  }

  return (
    <div className="p-6 bg-gray-100 rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold mb-4">Create Committee</h2>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Committee Name</label>
        <input
          type="text"
          className="mt-1 p-2 w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
          value={committeeName}
          onChange={(e) => setCommitteeName(e.target.value)}
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Select Campaign</label>
        <select
          className="mt-1 p-2 w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
          value={selectedCampaign}
          onChange={(e) => setSelectedCampaign(e.target.value)}
        >
          <option value="">Select a campaign</option>
          {campaigns.map(campaign => (
            <option key={campaign.id} value={campaign.id}>
              {campaign.name}
            </option>
          ))}
        </select>
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Select Committee Members</label>
        <select
          multiple
          className="mt-1 p-2 w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
          value={selectedMembers}
          onChange={(e) => setSelectedMembers(Array.from(e.target.selectedOptions).map(option => option.value))}
        >
          {members.map(member => (
            <option key={member.user_id} value={member.user_id}>
              {member.first_name} {member.last_name}
            </option>
          ))}
        </select>
      </div>
      <button
        className="w-full py-2 px-4 bg-blue-600 text-white font-semibold rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        onClick={handleAddCommittee}
      >
        Save Committee
      </button>
      <h2 className="mt-6 text-2xl font-semibold">Existing Committees</h2>
      <div>
        {committees.map((committee) => (
          <div key={committee.committee_id} className="p-4 bg-white rounded-md shadow-sm mb-4">
            <h3 className="text-lg font-semibold cursor-pointer" onClick={() => toggleExpandCommittee(committee.committee_id)}>
              {committee.committee_name}
            </h3>
            {expandedCommittee && expandedCommittee.committeeId === committee.committee_id && (
              <div className="mt-4">
                <h4 className="text-md font-semibold">Members:</h4>
                <ul>
                  {expandedCommittee.committeeMembers.map(member => (
                    <li key={member.user_id}>{member.first_name} {member.last_name}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CommitteesComponent;
