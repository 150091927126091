import React, { useEffect, useState } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import { Link } from "react-router-dom";
import successEmoji from "../../Assets/SuccessEmoji.png";
import confetti from "../../Assets/Confetti.gif";

const SuccessPage: React.FC = () => {
  const stripe = useStripe();
  const [message, setMessage] = useState<string | null>(null);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret");

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      if (!paymentIntent) {
        setMessage("Payment intent not found.");
        return;
      }

      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    }).catch((error) => {
      setMessage(`Error retrieving payment intent: ${error.message}`);
    });
  }, [stripe]);

  if (!stripe) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
        <div className="p-6 bg-white rounded-lg shadow-md">
          <h1 className="mb-4 text-2xl font-bold text-center">Loading...</h1>
        </div>
      </div>
    );
  }

  return (
    <div className="relative flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <img src={confetti} alt="Confetti" className="absolute inset-0 w-full h-full object-cover" />
      <div className="absolute inset-0 bg-black opacity-10 backdrop-blur-md"></div>
      <div className="relative z-10 p-6 bg-white rounded-lg shadow-md">
        <h1 className="mb-4 text-2xl font-bold text-center">Thank You!</h1>
        <img src={successEmoji} alt="Success" className="mb-4 w-16 h-16 mx-auto" />
        <div className="mb-4 text-center">
          <em className="italic">{message}</em>
        </div>
        <div className="flex justify-center space-x-4">
          <Link to="/dashboard" className="px-4 py-2 font-semibold text-white bg-indigo-600 rounded-lg hover:bg-indigo-700">
            DASHBOARD
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;
