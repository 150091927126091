import React from 'react';

interface Role {
  name: string;
}

interface RolesListProps {
  roles: Role[];
  handleRoleClick: (role: Role) => void;
  handleNewRole: () => void;
}

const RolesList: React.FC<RolesListProps> = ({ roles, handleRoleClick, handleNewRole }) => (
  <div className="w-1/4 border-r border-gray-300 p-4">
    <h2 className="mb-4 text-2xl font-semibold">Roles</h2>
    <button 
      className="mb-4 w-full text-left p-2 border border-gray-300"
      onClick={handleNewRole}
    >
      New Role
    </button>
    {roles.map((role, index) => (
      <button 
        key={index} 
        className="w-full text-left p-2 border border-gray-300 mb-2"
        onClick={() => handleRoleClick(role)}
      >
        {role.name}
      </button>
    ))}
  </div>
);

export default RolesList;
