import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { supabase } from '../../supabase';

interface PendingRequest {
  id: string;
  initiatorOrganizationName: string;
  rosterStatus: 'Requested' | 'Approved' | null;
  contactStatus: 'Requested' | 'Approved' | null;
}

const ApproveButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <button
    onClick={onClick}
    className="bg-green-500 text-white text-xs font-semibold px-3 py-1 rounded-md ml-2 hover:bg-green-600 transition-colors duration-200"
  >
    Approve
  </button>
);

const PendingRequests: React.FC = () => {
  const { groupId } = useParams<{ groupId: string }>();
  const [pendingRequests, setPendingRequests] = useState<PendingRequest[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchPendingRequests();
  }, [groupId]);

  const fetchPendingRequests = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('social_request_permissions')
        .select(`
          permission_id,
          initiator_organization:organizations!social_request_permissions_initiator_organization_id_fkey(name),
          roster_status,
          contact_status
        `)
        .eq('requested_organization_id', groupId)
        .or('roster_status.eq.Requested,contact_status.eq.Requested');

      if (error) throw error;

      const formattedData: PendingRequest[] = data.map((request: any) => ({
        id: request.permission_id.toString(),  
        initiatorOrganizationName: request.initiator_organization.name,
        rosterStatus: request.roster_status,
        contactStatus: request.contact_status,
      }));

      setPendingRequests(formattedData);
    } catch (err) {
      setError('Failed to fetch pending requests');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleApprove = async (requestId: string, type: 'roster' | 'contact') => {
    try {
      const { data, error } = await supabase
        .from('social_request_permissions')
        .update({ [`${type}_status`]: 'Approved' })
        .eq('permission_id', requestId);

      if (error) throw error;

      setPendingRequests(requests => 
        requests.map(request => 
          request.id === requestId
            ? { ...request, [`${type}Status`]: 'Approved' as 'Approved' }
            : request
        )
      );
    } catch (err) {
      console.error('Failed to approve request:', err);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="p-6">
      <h2 className="text-2xl font-semibold text-gray-800 mb-6">Pending Requests</h2>
      <div className="space-y-4">
        {pendingRequests.map((request) => (
          <div key={request.id} className="bg-white rounded-lg shadow-sm border border-gray-200 p-4">
            <h3 className="text-lg font-semibold text-gray-800 mb-2">{request.initiatorOrganizationName}</h3>
            <div className="space-y-2">
              {request.rosterStatus === 'Requested' && (
                <div className="flex justify-between items-center">
                  <span className="text-gray-600">Is requesting Roster</span>
                  <ApproveButton onClick={() => handleApprove(request.id, 'roster')} />
                </div>
              )}
              {request.contactStatus === 'Requested' && (
                <div className="flex justify-between items-center">
                  <span className="text-gray-600">Is requesting Contact Information</span>
                  <ApproveButton onClick={() => handleApprove(request.id, 'contact')} />
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PendingRequests;