import React from 'react';
import Admin from './Admin';

function GroupAdmin() {
  return (
    <div className="h-full overflow-hidden p-8">
      <div className="h-full overflow-hidden bg-white border rounded-lg shadow-md border-agoraOutline">
        <Admin />
      </div>
    </div>
  );
}

export default GroupAdmin;