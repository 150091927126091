import { supabase } from '../supabase';

export const addApplicant = async (
  campaign_id: string,
  name: string,
  email: string,
  phone: string,
  major: string,
  status: string
) => {
  try {
    const { data, error } = await supabase
      .from('applicants')
      .insert([{ campaign_id, name, email, phone, major, status }]);

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error adding applicant:', error);
    throw error;
  }
};


export const addReview = async (
  applicant_id: string,
  user_id: string,
  review_text: string,
  rating: number
) => {
  try {
    const { data, error } = await supabase
      .from('reviews')
      .insert([{ applicant_id, user_id, review_text, rating }]);

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error adding review:', error);
    throw error;
  }
};

export const addInterviewer = async (
  interview_id: string,
  user_id: string
) => {
  try {
    const { data, error } = await supabase
      .from('interviewers')
      .insert([{ interview_id, user_id }]);

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error adding interviewer:', error);
    throw error;
  }
};

export const addInterviewInfo = async (
  applicant_id: string,
  interview_date: string,
  interview_time: string,
  status: string
) => {
  try {
    const { data, error } = await supabase
      .from('interviews')
      .insert([{ applicant_id, interview_date, interview_time, status }]);

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error adding interview information:', error);
    throw error;
  }
};

export const addQuestionToCampaign = async (
  campaign_id: string,
  question_text: string
) => {
  try {
    const { data, error } = await supabase
      .from('questions')
      .insert([{ campaign_id, question_text }]);

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error adding question to campaign:', error);
    throw error;
  }
};

export const saveInterviewAnswer = async (
  interview_id: string,
  question_id: string,
  answer_text: string
) => {
  try {
    const { data, error } = await supabase
      .from('interviewanswers')
      .insert([{ interview_id, question_id, answer_text }]);

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error saving interview answer:', error);
    throw error;
  }
};

export const moveApplicantsStageBulk = async (
  applicant_ids: number[],
  new_stage: string
) => {
  try {
    const { data, error } = await supabase
      .from('applicants')
      .update({ stage: new_stage })
      .in('id', applicant_ids);

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error moving applicants stage in bulk:', error);
    throw error;
  }
};

export const endCampaign = async (campaign_id: string) => {
  try {
    const { data, error } = await supabase
      .from('campaigns')
      .update({ status: 'ended' })
      .eq('campaign_id', campaign_id);

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error ending campaign:', error);
    throw error;
  }
};

export const deleteApplicants = async (applicant_ids: number[]) => {
  try {
    const { data, error } = await supabase
      .from('applicants')
      .delete()
      .in('id', applicant_ids);

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error deleting applicants:', error);
    throw error;
  }
};

export const checkMemberAccess = async (
  user_id: string,
  permission: string
) => {
  try {
    const { data, error } = await supabase
      .from('permissions')
      .select('permission_name')
      .eq('user_id', user_id)
      .eq('permission_name', permission);

    if (error) throw error;
    return data.length > 0;
  } catch (error) {
    console.error('Error checking member access:', error);
    throw error;
  }
};

export const checkAssignedInterviewer = async (user_id: string) => {
  try {
    const { data, error } = await supabase
      .from('interviewers')
      .select('interview_id')
      .eq('user_id', user_id);

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error checking assigned interviewer:', error);
    throw error;
  }
};

export const getApplicantInfo = async (applicant_id: string) => {
  try {
    const { data, error } = await supabase
      .from('applicants')
      .select('*')
      .eq('applicant_id', applicant_id);

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error retrieving applicant information:', error);
    throw error;
  }
};

export const getApplicantReviews = async (applicant_id: string) => {
  try {
    const { data, error } = await supabase
      .from('reviews')
      .select('*')
      .eq('applicant_id', applicant_id);

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error retrieving applicant reviews:', error);
    throw error;
  }
};

export const getApplicantsBatch = async (
  campaign_id: string,
  batch_size: number,
  page_number: number
) => {
  try {
    const offset = (page_number - 1) * batch_size;
    const { data, error } = await supabase
      .from('applicants')
      .select('*')
      .eq('campaign_id', campaign_id)
      .order('created_at', { ascending: false })
      .range(offset, offset + batch_size - 1);

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error rendering applicants in batch:', error);
    throw error;
  }
};

export const fetchApplicants = async (campaign_id: string) => {
  try {
    const { data, error } = await supabase
      .from('applicants')
      .select('*')
      .eq('campaign_id', campaign_id);
      
    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error fetching applicants:', error);
    throw error;
  }
};

export const searchApplicantsByName = async (name_query: string) => {
  try {
    const { data, error } = await supabase
      .from('applicants')
      .select('*')
      .ilike('name', `%${name_query}%`);

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error searching applicants by name:', error);
    throw error;
  }
};

export const addCommittee = async (
  organization_id: string,
  committee_name: string,
  campaign_id: string
) => {
  try {
    const { data, error } = await supabase
      .from('committees')
      .insert([{ organization_id, committee_name, campaign_id }])
      .select();

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error adding committee:', error);
    throw error;
  }
};

export const fetchOrganizationMembers = async (organization_id: string) => {
  try {
    const { data, error } = await supabase
      .from('memberships')
      .select('user_id')
      .eq('organization_id', organization_id)
      .eq('is_active', true);

    if (error) throw error;

    const userIds = data.map(membership => membership.user_id);

    const { data: usersData, error: usersError } = await supabase
      .from('users')
      .select('user_id, first_name, last_name')
      .in('user_id', userIds);

    if (usersError) throw usersError;

    return usersData;
  } catch (error) {
    console.error('Error fetching organization members:', error);
    throw error;
  }
};

export const fetchOrganizationRoles = async (organization_id: string) => {
  try {
    const { data, error } = await supabase
      .from('roles')
      .select('*')
      .eq('group_id', organization_id);

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error fetching organization roles:', error);
    throw error;
  }
};

export const addCommitteeMembers = async (
  committee_id: string,
  members: any[]
) => {
  try {
    const { data, error } = await supabase
      .from('committeemembers')
      .insert(members.map((member: any) => ({ committee_id, ...member })));

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error adding committee members:', error);
    throw error;
  }
};

export const fetchCommitteeMembers = async (committee_id: string) => {
  try {
    const { data, error } = await supabase
      .from('committeemembers')
      .select('user_id')
      .eq('committee_id', committee_id);

    if (error) throw error;

    const userIds = data.map(member => member.user_id);

    const { data: usersData, error: usersError } = await supabase
      .from('users')
      .select('user_id, first_name, last_name')
      .in('user_id', userIds);

    if (usersError) throw usersError;

    return usersData;
  } catch (error) {
    console.error('Error fetching committee members:', error);
    throw error;
  }
};

export const fetchCommitteeRoles = async (committee_id: string) => {
  try {
    const { data, error } = await supabase
      .from('committeeroles')
      .select('role_id')
      .eq('committee_id', committee_id);

    if (error) throw error;

    const roleIds = data.map(role => role.role_id);

    const { data: rolesData, error: rolesError } = await supabase
      .from('roles')
      .select('role_id, role_name')
      .in('role_id', roleIds);

    if (rolesError) throw rolesError;

    return rolesData;
  } catch (error) {
    console.error('Error fetching committee roles:', error);
    throw error;
  }
};

export const fetchOrganizationCommittees = async (organization_id: string) => {
  try {
    const { data, error } = await supabase
      .from('committees')
      .select('*')
      .eq('organization_id', organization_id);

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error fetching organization committees:', error);
    throw error;
  }
};

export const fetchCampaigns = async (organization_id: string) => {
  try {
    const { data, error } = await supabase
      .from('campaigns')
      .select('*')
      .eq('organization_id', organization_id);

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error fetching campaigns:', error);
    throw error;
  }
};


interface CampaignData {
  p_organization_id: string;
  p_name: string;
  p_has_interest_stage: boolean;
  p_transparency: {
    interest_stage_visible: boolean;
    applicant_stage_visible: boolean;
    interview_stage_visible: boolean;
    decision_stage_visible: boolean;
  };
  p_review_access: string[];
  p_events: {
    name: string;
    date_time: string;
    track_attendance: boolean;
  }[];
}

export const addCampaign = async (campaignData: CampaignData) => {
  try {
    console.log( "Campaign Data: ", campaignData);
    const { data, error } = await supabase.rpc('create_campaign', campaignData);

    if (error) {
      console.error('Supabase RPC error:', error);
      throw new Error(`Supabase RPC error: ${error.message}`);
    }

    if (data && data.status === 'success') {
      console.log('Campaign added successfully:', data);
      return { status: 'success', campaign_id: data.campaign_id };
    } else if (data && data.status === 'error') {
      console.error('SQL function error:', data);
      throw new Error(`SQL function error: ${data.message}. Detail: ${data.detail}`);
    } else {
      throw new Error('Unexpected response from server');
    }
  } catch (error) {
    console.error('Error in addCampaign:', error);
    return { 
      status: 'error', 
      message: error instanceof Error ? error.message : 'An unknown error occurred'
    };
  }
};

interface Applicant {
  id: number;
  full_name: string;
  major?: string;
  stage: string;
}

export const fetchApplicantsByCampaign = async (
  campaign_id: string,
  stage: string,
  page: number,
  itemsPerPage: number
) => {
  try {
    const from = (page - 1) * itemsPerPage;
    const to = from + itemsPerPage - 1;

    const { data, error, count } = await supabase
      .from('applicants')
      .select('id, full_name, major, stage', { count: 'exact' })
      .eq('campaign_id', campaign_id)
      .eq('stage', stage)
      .range(from, to)
      .order('created_at', { ascending: true });

    if (error) throw error;
    return { 
      applicants: data as Applicant[],
      totalCount: count || 0
    };
  } catch (error) {
    console.error('Error fetching applicants:', error);
    throw error;
  }
};

export const checkCampaignBelongsToOrganization = async (campaignId: string, organizationId: string): Promise<boolean> => {
  try {
    const { data, error } = await supabase
      .from('campaigns')
      .select('organization_id')
      .eq('id', campaignId)
      .single();

    if (error) throw error;

    return data?.organization_id === organizationId;
  } catch (error) {
    console.error('Error checking if campaign belongs to organization:', error);
    return false;
  }
};

export const fetchCampaignDetails = async (campaignId: string) => {
  try {
    const { data, error } = await supabase
      .from('campaigns')
      .select('*')
      .eq('id', campaignId)
      .single();

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error fetching campaign details:', error);
    throw error;
  }
};

export const checkUserInCommittee = async (userId: string, campaignId: string): Promise<boolean> => {
  try {
    const { data, error } = await supabase
      .from('committeemembers')
      .select(`
        committee_id,
        committees!inner(campaign_id)
      `)
      .eq('user_id', userId)
      .eq('committees.campaign_id', campaignId);

    if (error) throw error;

    return data.length > 0;
  } catch (error) {
    console.error('Error checking if user is in committee:', error);
    return false;
  }
};

export const fetchStageAccessRoles = async (campaignId: string) => {
  try {
    const { data, error } = await supabase
      .from('campaign_transparency')
      .select('*')
      .eq('campaign_id', campaignId);

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error fetching stage access roles:', error);
    throw error;
  }
};

interface CampaignSettings {
  has_interest_stage: boolean;
}

export const checkAddApplicantVisibility = async (campaignId: string): Promise<boolean> => {
  try {
    const { data, error } = await supabase
      .from('campaigns')
      .select('has_interest_stage')
      .eq('id', campaignId)
      .single();

    if (error) {
      console.error('Error fetching campaign settings:', error);
      return false;
    }

    const campaignSettings = data as CampaignSettings;
    return campaignSettings.has_interest_stage;
  } catch (error) {
    console.error('Error in checkAddApplicantVisibility:', error);
    return false;
  }
};