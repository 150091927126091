import React, { useState, useEffect } from 'react';
import { FaUsers } from 'react-icons/fa';
import { supabase } from '../../../supabase';
import { useParams } from 'react-router-dom';

const Analytics = () => {
  const { groupId } = useParams();
  const [totalMembers, setTotalMembers] = useState(0);

  useEffect(() => {
    const fetchTotalMembers = async () => {
      try {
        const { data, error } = await supabase
          .rpc('get_active_members_count', { group_id: groupId });
        if (error) {
          console.error('Error fetching total members:', error);
        } else {
          setTotalMembers(data);
        }
      } catch (error) {
        console.error('Error fetching total members:', error);
      }
    };

    if (groupId) {
      fetchTotalMembers();
    }
  }, [groupId]);

  return (
    <div>
      <h2 className="mb-4 text-2xl font-semibold">Analytics</h2>
      <div className="grid grid-cols-2 gap-8">
        <div className="p-6 bg-white rounded-lg shadow flex flex-col items-center justify-center">
          <div className="flex items-center mb-4">
            <FaUsers className="mr-2 text-blue-500" size={24} />
            <h3 className="text-lg font-semibold">Total Members</h3>
          </div>
          <p className="text-3xl font-bold">{totalMembers}</p>
        </div>
        <div className="p-6 bg-white rounded-lg shadow flex flex-col items-center justify-center">
          <div className="flex items-center mb-4">
            <h3 className="text-lg font-semibold">More Analytics Coming Soon!</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
