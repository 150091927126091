import React, { useState, ChangeEvent, FormEvent } from 'react';
import { supabase } from '../../supabase';

const FeedbackForm: React.FC = () => {
  const [activeTab, setActiveTab] = useState('bug');
  const [formData, setFormData] = useState({
    description: '',
    steps_to_reproduce: '',
    issue_description: '',
    account_email: '',
    feature_description: '',
    benefit_of_feature: '',
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (activeTab === 'bug') {
      await supabase.from('bug_reports').insert([
        {
          description: formData.description,
          steps_to_reproduce: formData.steps_to_reproduce,
        },
      ]);
    } else if (activeTab === 'support') {
      await supabase.from('support_help').insert([
        {
          issue_description: formData.issue_description,
          account_email: formData.account_email,
        },
      ]);
    } else if (activeTab === 'feature') {
      await supabase.from('feature_requests').insert([
        {
          feature_description: formData.feature_description,
          benefit_of_feature: formData.benefit_of_feature,
        },
      ]);
    }

    setFormData({
      description: '',
      steps_to_reproduce: '',
      issue_description: '',
      account_email: '',
      feature_description: '',
      benefit_of_feature: '',
    });
  };

  const renderFormContent = () => {
    switch (activeTab) {
      case 'bug':
        return (
          <div>
            <label className="block mb-2">
              Bug Description
              <textarea name="description" value={formData.description} onChange={handleChange} className="w-full border rounded p-2"></textarea>
            </label>
            <label className="block mb-2">
              Steps to Reproduce
              <textarea name="steps_to_reproduce" value={formData.steps_to_reproduce} onChange={handleChange} className="w-full border rounded p-2"></textarea>
            </label>
          </div>
        );
      case 'support':
        return (
          <div>
            <label className="block mb-2">
              Issue Description
              <textarea name="issue_description" value={formData.issue_description} onChange={handleChange} className="w-full border rounded p-2"></textarea>
            </label>
            <label className="block mb-2">
              Account Email
              <input type="email" name="account_email" value={formData.account_email} onChange={handleChange} className="w-full border rounded p-2"/>
            </label>
          </div>
        );
      case 'feature':
        return (
          <div>
            <label className="block mb-2">
              Feature Description
              <textarea name="feature_description" value={formData.feature_description} onChange={handleChange} className="w-full border rounded p-2"></textarea>
            </label>
            <label className="block mb-2">
              Benefit of Feature
              <textarea name="benefit_of_feature" value={formData.benefit_of_feature} onChange={handleChange} className="w-full border rounded p-2"></textarea>
            </label>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-lg">
        <div className="flex space-x-4 mb-4">
          <button
            className={`py-2 px-4 rounded ${activeTab === 'bug' ? 'bg-gray-200' : 'bg-white'}`}
            onClick={() => setActiveTab('bug')}
          >
            Bug Report
          </button>
          <button
            className={`py-2 px-4 rounded ${activeTab === 'support' ? 'bg-gray-200' : 'bg-white'}`}
            onClick={() => setActiveTab('support')}
          >
            Support Help
          </button>
          <button
            className={`py-2 px-4 rounded ${activeTab === 'feature' ? 'bg-gray-200' : 'bg-white'}`}
            onClick={() => setActiveTab('feature')}
          >
            Feature Request
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          {renderFormContent()}
          <button type="submit" className="mt-4 bg-blue-500 text-white py-2 px-4 rounded">
            Send
          </button>
        </form>
      </div>
    </div>
  );
};

export default FeedbackForm;
