import { useState, useEffect } from 'react';
import { supabase } from '../supabase';

interface MembershipData {
  isMember: boolean | null;
  userRoles: string[] | null;
}

export const useMembership = (userId: string, groupId: string, requiredRoles: string[] = []): MembershipData => {
  const [isMember, setIsMember] = useState<boolean | null>(null);
  const [userRoles, setUserRoles] = useState<string[] | null>(null);

  useEffect(() => {
    if (!userId || !groupId) {
      setIsMember(false);
      return;
    }

    const checkMembership = async () => {
      const { data, error } = await supabase
        .from('memberships')
        .select('role')
        .eq('user_id', userId)
        .eq('organization_id', groupId);

      if (error) {
        console.error('Error checking membership:', error);
        setIsMember(false);
        return;
      }

      const roles = data.map((membership: { role: string }) => membership.role);
      setUserRoles(roles);

      const hasRequiredRole = requiredRoles.length === 0 || roles.some(role => requiredRoles.includes(role));
      setIsMember(hasRequiredRole);
    };

    checkMembership();
  }, [userId, groupId, requiredRoles]);

  return { isMember, userRoles };
};
