import React, { useState, useEffect } from 'react';
import { Outlet, useParams, useNavigate } from 'react-router-dom';
import RecruitTopHeader from '../../Components/Recruitment/RecruitTopHeader';
import RecruitHeader from '../../Components/Recruitment/RecruitHeader';
import { fetchCampaignDetails, checkUserInCommittee, fetchStageAccessRoles, checkCampaignBelongsToOrganization } from '../../../Contexts/supabaseRecruitment';

interface CampaignDetails {
  id: string;
  name: string;
}

interface StageAccessRoles {
  [stage: string]: string[];
}

const RecruitmentComponent = () => {
  const { groupId, campaignId } = useParams<{ groupId: string; campaignId: string }>();
  const navigate = useNavigate();
  const [campaignDetails, setCampaignDetails] = useState<any>(null);
  const [userInCommittee, setUserInCommittee] = useState<boolean>(false);
  const [stageAccessRoles, setStageAccessRoles] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!groupId || !campaignId) {
        setError('Missing group ID or campaign ID');
        setLoading(false);
        return;
      }

      try {
        const belongsToOrg = await checkCampaignBelongsToOrganization(campaignId, groupId);
        if (!belongsToOrg) {
          navigate('/dashboard');
          return;
        }

        const details = await fetchCampaignDetails(campaignId);
        const userId = localStorage.getItem('userId');
        const inCommittee = userId ? await checkUserInCommittee(userId, campaignId) : false;
        const accessRoles = await fetchStageAccessRoles(campaignId);
        
        setCampaignDetails(details);
        setUserInCommittee(inCommittee);
        setStageAccessRoles(accessRoles);
      } catch (err) {
        setError('Failed to fetch campaign data');
      } finally {
        setLoading(false);
      }
    };
    
    fetchData();
  }, [groupId, campaignId, navigate]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="flex flex-col h-full">
      <div className="flex-shrink-0">
        <RecruitTopHeader 
          campaignDetails={campaignDetails}
          userInCommittee={userInCommittee}
          stageAccessRoles={stageAccessRoles}
        />
      </div>
      <div className="flex-grow overflow-hidden ">
        <div className="px-4 h-full"> 
          <div className="flex flex-col flex-grow overflow-hidden bg-white rounded-md h-full">
            <div className="flex-shrink-0">
              <RecruitHeader 
                campaignDetails={campaignDetails}
                userInCommittee={userInCommittee}
              />
            </div>
            <div className="flex-grow overflow-auto">
              <div className="container  py-8 mx-auto">
                <Outlet context={{ campaignDetails, userInCommittee, stageAccessRoles }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecruitmentComponent;