import React, { useState, useEffect } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { addCampaign, fetchOrganizationRoles } from '../../Contexts/supabaseRecruitment';
import { format } from 'date-fns';
import { getTimezoneOffset } from 'date-fns-tz';

interface Role {
  role_id: string;
  role_name: string;
}

interface Event {
  id: number;
  name: string;
  date_time: string;
  track_attendance: boolean;
}

interface Transparency {
  interest_stage_visible: boolean;
  applicant_stage_visible: boolean;
  interview_stage_visible: boolean;
  decision_stage_visible: boolean;
}

const EventForm: React.FC<{
  event: Event;
  onUpdate: (updatedEvent: Event) => void;
  onRemove: () => void;
}> = ({ event, onUpdate, onRemove }) => {
  const formatLocalDateTime = (utcDateString: string) => {
    const date = new Date(utcDateString);
    return format(date, "yyyy-MM-dd'T'HH:mm");
  };

  return (
    <div className="p-4 mb-4 bg-white rounded-md shadow-sm">
      <input
        type="text"
        className="w-full p-2 mb-2 border border-gray-300 rounded-md"
        value={event.name}
        onChange={(e) => onUpdate({ ...event, name: e.target.value })}
        placeholder="Event Name"
      />
      <input
        type="datetime-local"
        className="w-full p-2 mb-2 border border-gray-300 rounded-md"
        value={formatLocalDateTime(event.date_time)}
        onChange={(e) => {
          const localDate = new Date(e.target.value);
          const offset = getTimezoneOffset(Intl.DateTimeFormat().resolvedOptions().timeZone, localDate);
          const utcDate = new Date(localDate.getTime() - offset);
          onUpdate({ ...event, date_time: utcDate.toISOString() });
        }}
      />
      <div className="flex items-center mb-2">
        <input
          type="checkbox"
          className="mr-2"
          checked={event.track_attendance}
          onChange={(e) => onUpdate({ ...event, track_attendance: e.target.checked })}
        />
        <label>Track Attendance</label>
      </div>
      <button
        className="text-red-600 hover:text-red-800"
        onClick={onRemove}
      >
        Remove Event
      </button>
    </div>
  );
};

const RoleSelector: React.FC<{
  roles: Role[];
  selectedRoles: Role[];
  onToggle: (role: Role) => void;
}> = ({ roles, selectedRoles, onToggle }) => (
  <div>
    <div className="flex flex-wrap gap-2 mb-3">
      {selectedRoles.map((role) => (
        <div key={role.role_id} className="flex items-center px-3 py-1 text-blue-800 bg-blue-100 rounded-full">
          {role.role_name}
          <button className="ml-2 text-blue-600 hover:text-blue-800" onClick={() => onToggle(role)}>×</button>
        </div>
      ))}
    </div>
    <select
      className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
      onChange={(e) => {
        const selectedRole = roles.find(role => role.role_id === e.target.value);
        if (selectedRole) onToggle(selectedRole);
      }}
    >
      <option value="">Select a role</option>
      {roles.map((role) => (
        <option key={role.role_id} value={role.role_id}>{role.role_name}</option>
      ))}
    </select>
  </div>
);

const TransparencySelector: React.FC<{
  transparency: Transparency;
  onUpdate: (key: keyof Transparency) => void;
}> = ({ transparency, onUpdate }) => (
  <div>
    {(Object.keys(transparency) as Array<keyof Transparency>).map((key) => (
      <label key={key} className="flex items-center mb-2">
        <input
          type="checkbox"
          className="w-5 h-5 text-indigo-600 form-checkbox"
          checked={transparency[key]}
          onChange={() => onUpdate(key)}
        />
        <span className="ml-2 text-sm text-gray-700">
          {key.replace(/_/g, ' ').replace(' visible', '').charAt(0).toUpperCase() + key.replace(/_/g, ' ').replace(' visible', '').slice(1)} Stage
        </span>
      </label>
    ))}
  </div>
);

const CreateCampaign: React.FC = () => {
  const [campaignName, setCampaignName] = useState('');
  const [hasInterestStage, setHasInterestStage] = useState(false);
  const [events, setEvents] = useState<Event[]>([]);
  const [roles, setRoles] = useState<Role[]>([]);
  const [selectedRoles, setSelectedRoles] = useState<Role[]>([]);
  const [transparency, setTransparency] = useState<Transparency>({
    interest_stage_visible: false,
    applicant_stage_visible: false,
    interview_stage_visible: false,
    decision_stage_visible: false,
  });
  const { groupId } = useParams<{ groupId: string }>();

  useEffect(() => {
    if (!groupId) return;

    const fetchData = async () => {
      try {
        const rolesData = await fetchOrganizationRoles(groupId);
        setRoles(rolesData);
      } catch (error) {
        console.error('Error fetching roles:', error);
      }
    };
    fetchData();
  }, [groupId]);

  const handleAddCampaign = async () => {
    if (!groupId) {
      console.error('No groupId provided');
      return;
    }
  
    const campaignData = {
      p_organization_id: groupId,
      p_name: campaignName,
      p_has_interest_stage: hasInterestStage,
      p_transparency: transparency,
      p_review_access: selectedRoles.map(role => role.role_id),
      p_events: events.map(event => ({
        name: event.name,
        date_time: event.date_time,
        track_attendance: event.track_attendance
      }))
    };
  
    try {
      const result = await addCampaign(campaignData);
      if (result.status === 'success') {
        console.log('Campaign added successfully:', result);
        alert('Campaign added successfully');
        resetForm();
      } else {
        console.error('Error adding campaign:', result.message);
        alert(`Failed to add campaign: ${result.message}`);
      }
    } catch (error) {
      console.error('Error in handleAddCampaign:', error);
      alert(`An unexpected error occurred: ${error instanceof Error ? error.message : 'Unknown error'}`);
    }
  };

  const resetForm = () => {
    setCampaignName('');
    setHasInterestStage(false);
    setEvents([]);
    setSelectedRoles([]);
    setTransparency({
      interest_stage_visible: false,
      applicant_stage_visible: false,
      interview_stage_visible: false,
      decision_stage_visible: false,
    });
  };

  const addEvent = () => {
    setEvents([...events, { id: Date.now(), name: '', date_time: new Date().toISOString(), track_attendance: false }]);
  };

  const updateEvent = (updatedEvent: Event) => {
    setEvents(events.map(event => event.id === updatedEvent.id ? updatedEvent : event));
  };

  const removeEvent = (id: number) => {
    setEvents(events.filter(event => event.id !== id));
  };

  const toggleRole = (role: Role) => {
    setSelectedRoles(prev => 
      prev.some(r => r.role_id === role.role_id)
        ? prev.filter(r => r.role_id !== role.role_id)
        : [...prev, role]
    );
  };

  if (!groupId) {
    return <Navigate to="/some-error-page" />;
  }

  return (
    <div className="max-w-3xl p-6 mx-auto bg-gray-100 rounded-lg shadow-md">
      <h2 className="mb-6 text-3xl font-bold text-gray-800">Create Campaign</h2>
      
      <div className="mb-6">
        <label className="block mb-2 text-sm font-medium text-gray-700">Campaign Name</label>
        <input
          type="text"
          className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
          value={campaignName}
          onChange={(e) => setCampaignName(e.target.value)}
          placeholder="Enter campaign name"
        />
      </div>

      <div className="mb-6">
        <h3 className="mb-3 text-xl font-semibold">Events</h3>
        {events.map((event) => (
          <EventForm
            key={event.id}
            event={event}
            onUpdate={updateEvent}
            onRemove={() => removeEvent(event.id)}
          />
        ))}
        <button
          className="w-full px-4 py-2 font-semibold text-white bg-green-600 rounded-md shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          onClick={addEvent}
        >
          Add Event
        </button>
      </div>

      <div className="mb-6">
        <h3 className="mb-3 text-xl font-semibold">Roles With Access To Leave Reviews</h3>
        <RoleSelector
          roles={roles}
          selectedRoles={selectedRoles}
          onToggle={toggleRole}
        />
      </div>

      <div className="mb-6">
        <label className="flex items-center mb-4">
          <input
            type="checkbox"
            className="w-5 h-5 text-indigo-600 form-checkbox"
            checked={hasInterestStage}
            onChange={(e) => setHasInterestStage(e.target.checked)}
          />
          <span className="ml-2 text-sm text-gray-700">Do you want an Interest Stage?</span>
        </label>
        <p className="mb-4 text-sm text-gray-600">
          (If you select the above then you must manually filter through applications into the applicants stage
          so that attendance can be taken, this is typically for organizations that like to filter applications and
          selectively invite them to events)
        </p>
      </div>

      <div className="mb-6">
        <h3 className="mb-3 text-xl font-semibold">Transparency: What stages can the above roles also see</h3>
        <TransparencySelector
          transparency={transparency}
          onUpdate={(key) => setTransparency(prev => ({ ...prev, [key]: !prev[key] }))}
        />
      </div>

      <button
        className="w-full px-4 py-3 font-semibold text-white bg-blue-600 rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        onClick={handleAddCampaign}
      >
        Save Campaign
      </button>
    </div>
  );
};

export default CreateCampaign;