import React, { useState } from 'react';

interface BillingAccount {
  provider_account_id: string;
  name: string;
  provider: string;
}

interface BillingInfoProps {
  onboardingCompleted: boolean;
  providerAccountId: string;
  billingAccounts: BillingAccount[];
  finishOnboarding: () => void;
  createStripeAccount: () => void;
  handleLoginLink: (providerAccountId: string) => void;
}

const BillingInfo: React.FC<BillingInfoProps> = ({
  onboardingCompleted,
  providerAccountId,
  billingAccounts,
  finishOnboarding,
  createStripeAccount,
  handleLoginLink,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  return (
    <>
      <div className="mb-6">
        <h3 className="text-lg font-semibold">Stripe Onboarding</h3>
        {!onboardingCompleted ? (
          <div>
            <p className="mb-2">   
              <a 
                href="https://agoraprocessing.com/feedback" 
                target="_blank" 
                rel="noopener noreferrer"
                className="text-blue-600 hover:text-blue-800 underline"
              >
                 Click here 

              </a> to create a Support Help request with your contact information to get access to Billing.
            </p>
            {providerAccountId ? (
              <button
                onClick={finishOnboarding}
                className="px-4 py-2 font-semibold text-white bg-blue-500 rounded-lg hover:bg-blue-600"
              >
                Finish Onboarding
              </button>
            ) : (
              <div className="relative inline-block">
                <button
                  disabled
                  className="px-4 py-2 font-semibold text-white bg-blue-300 rounded-lg cursor-not-allowed"
                  onMouseEnter={() => setShowTooltip(true)}
                  onMouseLeave={() => setShowTooltip(false)}
                >
                  Create Stripe Account
                </button>
                {showTooltip && (
                  <div className="absolute z-10 p-2 text-sm text-white bg-gray-800 rounded-lg opacity-100 transition-opacity duration-300 bottom-full left-1/2 transform -translate-x-1/2 mb-2 w-64">
                    Reach out in our support tab in the side bar requesting billing access
                    <div className="absolute top-full left-1/2 transform -translate-x-1/2 -mt-1 border-4 border-gray-800 border-t-0 border-l-transparent border-r-transparent"></div>
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
          <p>Stripe onboarding completed.</p>
        )}
      </div>
      <div className="mb-6">
        <h3 className="text-lg font-semibold">Billing Accounts</h3>
        <div className="grid grid-cols-1 gap-4">
          {billingAccounts.map((account) => (
            <div key={account.provider_account_id} className="p-4 border rounded-lg shadow-sm">
              <h4 className="text-md font-semibold">Name: {account.name}</h4>
              <p>Provider: {account.provider}</p>
              <button
                onClick={() => handleLoginLink(account.provider_account_id)}
                className="mt-2 px-4 py-2 font-semibold text-white bg-green-500 rounded-lg hover:bg-green-600"
              >
                Create Login Link
              </button>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default BillingInfo;
