import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import AgoraLogo from '../../../../Assets/AgoraLogo.svg';

const Header: FC = () => {
    return (
        <header className="bg-[#ECEAFD]">
            <div className="container flex items-center justify-between px-6 py-3 mx-auto">
                <div className='flex items-center'>
                    <img src={AgoraLogo} alt="Agora Logo" className="h-8 mr-2" />
                    <h1 className="text-xl font-bold">Agora</h1>
                </div>
                <div>
                    <Link to="/login" className="px-4 py-2 mr-4 text-black font-semibold hover:text-gray-800 transition duration-300">Login</Link>
                    <Link to="/signup" className="px-4 py-2 text-white bg-[#4338CA] font-normal rounded-md hover:bg-indigo-700 transition duration-300">Sign up</Link>
                </div>
            </div>
        </header>
    );
};

export default Header;