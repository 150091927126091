import { supabase } from '../../../supabase';

interface OrganizationItem {
  item_id: number;
  amount: number;
  organization_id: string;
  user_id: string;
  product_id: string;
  quantity: number;
  currency: string;
  status: string;
  due_date: string;
  created_at: string;
  updated_at: string;
  billing_account_id: number;
  product_name: string;
  user_name: string;
  billing_account_name: string;
}

export const fetchPendingBills = async (groupId: string): Promise<OrganizationItem[]> => {
  const { data, error } = await supabase
    .from('organizationitems')
    .select(`
      *,
      users:user_id (first_name, last_name),
      products:product_id (name),
      billingaccounts:billing_account_id (name)
    `)
    .eq('organization_id', groupId);

  if (error) {
    console.error('Error fetching pending bills:', error);
    return [];
  } else {
    return data?.map(item => ({
      ...item,
      product_name: item.products?.name || 'N/A',
      user_name: item.users ? `${item.users.first_name} ${item.users.last_name}` : 'N/A',
      billing_account_name: item.billingaccounts?.name || 'N/A'
    })) || [];
  }
};

export const deleteOrganizationItem = async (itemId: number) => {
    const { data, error } = await supabase
        .from('organizationitems')
        .delete()
        .eq('item_id', itemId);

    if (error) {
        console.error('Error deleting organization item:', error);
        throw new Error('Failed to delete organization item');
    }
    return data;
};

export interface SubscriptionPlan {
    plan_id: number;
    name: string;
    amount: number;
    currency: string;
    billing_cycle: string;
    assignment_count: number;
}

export interface Assignment {
    assignment_id: string;
    first_name: string;
    last_name: string;
    status: string;
    cycles_remaining: number;
    billing_account_name: string;
}

export const fetchSubscriptionPlans = async (): Promise<SubscriptionPlan[]> => {
    const { data, error } = await supabase
        .rpc('get_subscription_plans_with_count')
        .select('*');

    if (error) {
        console.error('Error fetching subscription plans:', error);
        return [];
    }

    return data as SubscriptionPlan[];
};

export const fetchAssignments = async (planId: number): Promise<Assignment[]> => {
    const { data, error } = await supabase
        .rpc('get_subscription_assignments', { p_plan_id: planId })
        .select('*');

    if (error) {
        console.error('Error fetching assignments:', error);
        return [];
    }

    return data as Assignment[];
};

export const deleteSubscriptionPlan = async (planId: number): Promise<void> => {
    const { error } = await supabase
        .from('subscriptionplans')
        .delete()
        .eq('plan_id', planId);

    if (error) {
        console.error('Error deleting subscription plan:', error);
        throw new Error('Failed to delete subscription plan');
    }
};

export const deleteSubscriptionAssignment = async (assignmentId: string) => {
    try {
        const { error } = await supabase
        .from('subscriptionassignments')
        .delete()
        .eq('assignment_id', assignmentId);

        if (error) {
        throw error;
        }

        return true;
    } catch (error) {
        console.error('Error deleting subscription assignment:', error);
        throw error;
    }
};