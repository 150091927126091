import { useState, useEffect } from 'react';
import { supabase } from '../supabase';

export const useAdminMembership = (userId: string, groupId: string) => {
  const [isAdmin, setIsAdmin] = useState<boolean | null>(null);
  const [userPermissions, setUserPermissions] = useState<string[]>([]);

  useEffect(() => {
    const checkAdminMembership = async () => {
      try {
        const { data, error } = await supabase
          .rpc('get_user_permissions', { user_id: userId, group_id: groupId });
        if (error) throw new Error('Error fetching permissions');

        const permissions = data ? data.map((perm: { permission_name: string }) => perm.permission_name) : [];

        setIsAdmin(permissions.includes('Admin Access'));
        setUserPermissions(permissions);
      } catch (error) {
        setIsAdmin(false);
        setUserPermissions([]);
        if (error instanceof Error) {
          console.error('Error fetching permissions:', error.message);
        }
      }
    };

    if (userId && groupId) {
      checkAdminMembership();
    }
  }, [userId, groupId]);

  return { isAdmin, userPermissions };
};
