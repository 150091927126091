import React, { useState, useEffect } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import { supabase } from '../../../supabase'; 
import useIsAdmin from '../../../hooks/useIsAdmin';
import useHasCampaigns from '../../../hooks/useHasCampaigns';

const GroupTabs = () => {
  const { groupId } = useParams<{ groupId: string }>();
  const userId = localStorage.getItem('userId') || '';
  const isAdmin = useIsAdmin(userId, groupId || '');
  const { campaignId, loading: campaignsLoading } = useHasCampaigns(groupId || '');
  const location = useLocation();
  const [organizationName, setOrganizationName] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchOrganizationName = async () => {
      if (!groupId) return;

      try {
        const { data, error } = await supabase
          .from('organizations')
          .select('name')
          .eq('organization_id', groupId)
          .single();

        if (error) throw error;

        setOrganizationName(data?.name || 'Organization');
      } catch (err) {
        console.error('Error fetching organization name:', err);
        setError('Failed to load organization name');
      } finally {
        setLoading(false);
      }
    };

    fetchOrganizationName();
  }, [groupId]);

  if (isAdmin === null || campaignsLoading || loading || !groupId) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const tabs = [
    { name: 'About', path: `/dashboard/${groupId}` },
    { name: 'Billing', path: `/dashboard/${groupId}/billing` },
    { name: 'Calendar', path: `/dashboard/${groupId}/calendar` },
    ...(campaignId ? [{ name: 'Recruitment', path: `/dashboard/${groupId}/recruitment/${campaignId}/applicants` }] : []),
    ...(isAdmin ? [{ name: 'Admin', path: `/dashboard/${groupId}/admin` }] : []),
  ];

  return (
    <div className="flex flex-col px-4 py-3 bg-white border-b border-gray-200 md:flex-row md:items-center md:justify-between">
      <h1 className="mb-2 text-2xl font-bold md:mb-0">{organizationName}</h1>
      <nav className="flex overflow-x-auto md:overflow-x-visible">
        {tabs.map((tab) => (
          <Link
            key={tab.name}
            to={tab.path}
            className={`flex-shrink-0 px-3 py-2 text-base font-medium md:text-sm ${
              location.pathname === tab.path
                ? 'text-black bg-[#E3E0FF] rounded-md'
                : 'text-[#5F6776] hover:text-gray-900'
            } ${tab.name === 'Admin' ? 'md:ml-auto' : 'mr-2 md:mr-6'}`}
          >
            {tab.name}
          </Link>
        ))}
      </nav>
    </div>
  );
};

export default GroupTabs;