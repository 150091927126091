import React, { useState, useEffect } from 'react';
import { FaReceipt } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { supabase } from '../../supabase';  

interface Deadline {
  id: string;
  organization_id: string;
  title: string;
  description: string | null;  
  approved_at: string | null;
  approved_by_name: string | null;  
  optional_link?: string;
}

const DoneDeadlines: React.FC = () => {
  const [deadlines, setDeadlines] = useState<Deadline[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { groupId } = useParams();

  useEffect(() => {
    fetchFinishedDeadlines();
  }, []);

  const fetchFinishedDeadlines = async () => {
    if (!groupId) {
      setError('Group ID not found in local storage');
      setLoading(false);
      return;
    }

    try {
      const { data, error } = await supabase.rpc('get_org_finished_deadlines', {
        org_id: groupId
      });

      if (error) throw error;

      setDeadlines(data || []);
      setError(null);
    } catch (error) {
      console.error('Error fetching finished deadlines:', error);
      setError('Failed to fetch finished deadlines. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6 text-gray-800">Finished Deadlines</h1>
      <div className="space-y-6">
        {deadlines.map((deadline) => (
          <div key={deadline.id} className="bg-white shadow-md rounded-lg p-6 flex justify-between items-center">
            <div className="flex-grow">
              <h2 className="text-xl font-bold text-gray-800">{deadline.title}</h2>
              <p className="text-gray-600 mt-1">{deadline.description}</p>  
            </div>
            <div className="flex items-center">
              {deadline.optional_link && (
                <button 
                  onClick={() => window.open(deadline.optional_link, '_blank')}
                  className="mr-4 bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600 transition duration-300"
                >
                  <FaReceipt />
                </button>
              )}
              <div className="w-px h-16 bg-gray-300 mr-4"></div>
              <div className="text-left">
                <div className="text-sm text-gray-500">
                  Approved on: {deadline.approved_at ? new Date(deadline.approved_at).toLocaleDateString() : 'N/A'}
                </div>
                <div className="text-sm text-gray-500 mt-1">
                  Approved by: {deadline.approved_by_name || 'N/A'} 
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DoneDeadlines;
