import React, { createContext, useContext } from 'react';
import { Navigate, Outlet, useParams } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { useMembership } from '../hooks/useMembership';

const RoleContext = createContext<string[] | null>(null);

export const useRole = () => useContext(RoleContext);

interface GroupPrivateRouteProps {
  requiredRoles?: string[];
}

const GroupPrivateRoute: React.FC<GroupPrivateRouteProps> = ({ requiredRoles = [] }) => {
  const { user } = useAuth();
  const { groupId } = useParams<{ groupId: string }>();

  const membershipData = useMembership(user?.id ?? '', groupId ?? '', requiredRoles);

  if (!user) {
    return <Navigate to="/login" />;
  }

  if (membershipData.isMember === null) {
    return <div>Loading...</div>;
  }

  if (!membershipData.isMember) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <RoleContext.Provider value={membershipData.userRoles}>
      <Outlet />
    </RoleContext.Provider>
  );
};

export default GroupPrivateRoute;
