import React, { useState, useEffect } from 'react';
import { fetchGroupMembers, fetchRoleMembers, addMemberToRole, removeMemberFromRole } from '../../../Contexts/supabaseRoles';
import { showToast } from '../../../Toast';

interface Member {
  user_id: string;
  fullName: string;
  users: {
    first_name: string;
    last_name: string;
  };
}

interface SelectedRole {
  id: string;
  name: string;
}

interface MembersManagementProps {
  groupId: string;
  selectedRole: SelectedRole | null;
}

const MembersManagement: React.FC<MembersManagementProps> = ({ groupId, selectedRole }) => {
  const [groupMembers, setGroupMembers] = useState<Member[]>([]);
  const [roleMembers, setRoleMembers] = useState<Member[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');

  useEffect(() => {
    const loadMembers = async () => {
      try {
        const groupMembersData = await fetchGroupMembers(groupId);
        setGroupMembers(groupMembersData.map((member: any) => ({
          ...member,
          fullName: `${member.users.first_name} ${member.users.last_name}`
        })));

        if (selectedRole) {
          const roleMembersData = await fetchRoleMembers(groupId, selectedRole.id);
          setRoleMembers(roleMembersData.map((member: any) => ({
            ...member,
            fullName: `${member.users.first_name} ${member.users.last_name}`
          })));
        }
      } catch (error) {
        console.error('Error loading members:', error);
      }
    };

    loadMembers();
  }, [groupId, selectedRole]);

  const handleAddMember = async (user: Member) => {
    try {
      await addMemberToRole(user.user_id, groupId, selectedRole?.id || '');
      showToast('Member Added Successfully!');
      setRoleMembers([...roleMembers, user]);
    } catch (error) {
      showToast('Failed to add member', true);
    }
  };

  const handleRemoveMember = async (user_id: string) => {
    try {
      await removeMemberFromRole(user_id, groupId, selectedRole?.id || '');
      showToast('Member Removed Successfully!');
      setRoleMembers(roleMembers.filter(member => member.user_id !== user_id));
    } catch (error) {
      console.error('Error removing member:', error);
      showToast('Failed To Remove Member', true);
    }
  };

  const filteredGroupMembers = groupMembers.filter(member =>
    member.fullName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <div className="flex mb-4">
        <input
          className="p-2 border border-gray-300 w-full"
          type="text"
          placeholder="Search Members"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      {roleMembers.length === 0 ? (
        <p>No members in this role. Add members to this role.</p>
      ) : (
        <div>
          <h3 className="font-semibold mb-2">Members in Role</h3>
          <ul>
            {roleMembers.map((member, index) => (
              <li key={index} className="flex justify-between p-2 border border-gray-300 mb-2">
                {member.fullName}
                <button className="ml-2 p-2 bg-red-500 text-white" onClick={() => handleRemoveMember(member.user_id)}>Remove</button>
              </li>
            ))}
          </ul>
        </div>
      )}
      <div>
        <h3 className="font-semibold mb-2">All Group Members</h3>
        <ul>
          {filteredGroupMembers.map((member, index) => (
            <li key={index} className="flex justify-between p-2 border border-gray-300 mb-2">
              {member.fullName}
              <button className="ml-2 p-2 bg-blue-500 text-white" onClick={() => handleAddMember(member)}>Add to Role</button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default MembersManagement;
