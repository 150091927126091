import { useEffect, useState } from 'react';
import { supabase } from '../supabase';

const useIsAdmin = (userId: string, groupId: string) => {
  const [isAdmin, setIsAdmin] = useState<boolean | null>(null);

  useEffect(() => {
    const checkAdminRole = async () => {
      if (!userId || !groupId) {
        setIsAdmin(false);
        return;
      }

      const { data, error } = await supabase
        .from('user_organization_roles')
        .select('*')
        .eq('user_id', userId)
        .eq('group_id', groupId);

      if (error) {
        console.error('Error checking admin role:', error);
        setIsAdmin(false);
      } else {
        setIsAdmin(data.length > 0);
      }
    };

    checkAdminRole();
  }, [userId, groupId]);

  return isAdmin;
};

export default useIsAdmin;
