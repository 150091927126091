import React, { useEffect, useState } from 'react';
import { supabase } from '../../../supabase';
import { format, parseISO } from 'date-fns';
import { getTimezoneOffset } from 'date-fns-tz';
import { useParams } from 'react-router-dom';
import TransactionDetailsPopup from './TransactionDetailsPopup';

type User = {
  first_name: string;
  last_name: string;
};

type Transaction = {
  id: number;
  gross_amount: number;
  created_at: string;
  users: User | User[];
};

const ITEMS_PER_PAGE = 15;

const TransactionHistory: React.FC = () => {
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedTransactionId, setSelectedTransactionId] = useState<number | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const { groupId } = useParams<{ groupId: string }>();

  useEffect(() => {
    const fetchTransactionHistory = async () => {
      try {
        setLoading(true);
        const { data, error, count } = await supabase
          .from('transfers')
          .select(`
            id,
            gross_amount,
            created_at,
            users:user_id (
              first_name,
              last_name
            )
          `, { count: 'exact' })
          .eq('organization_id', groupId)
          .order('created_at', { ascending: false })
          .range((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE - 1);

        if (error) {
          throw error;
        }

        setTransactions(data as Transaction[]);
        setTotalCount(count || 0);
      } catch (error) {
        console.error('Error fetching transaction history:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTransactionHistory();
  }, [groupId, currentPage]);

  const formatCurrency = (amountInCents: number): string => {
    const amountInDollars = amountInCents / 100;
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amountInDollars);
  };

  const renderUserNames = (users: User | User[]) => {
    if (Array.isArray(users)) {
      return users.map((user, index) => (
        <span key={index}>
          {user.first_name} {user.last_name}
          {index < users.length - 1 ? ', ' : ''}
        </span>
      ));
    } else {
      return `${users.first_name} ${users.last_name}`;
    }
  };

  const handleRowClick = (transactionId: number) => {
    setSelectedTransactionId(transactionId);
  };

  const formatLocalDate = (utcDateString: string): string => {
    const date = parseISO(utcDateString);
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const offset = getTimezoneOffset(timeZone, date);
    return format(new Date(date.getTime() + offset), 'MMM d, yyyy');
  };

  const totalPages = Math.ceil(totalCount / ITEMS_PER_PAGE);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-semibold mb-4">Transaction History</h2>
      {loading ? (
        <p>Loading transaction history...</p>
      ) : (
        <>
          <table className="w-full">
            <thead>
              <tr className="text-left border-b">
                <th className="pb-2">Date</th>
                <th className="pb-2">Status</th>
                <th className="pb-2">From</th>
                <th className="pb-2 text-right">Amount</th>
              </tr>
            </thead>
            <tbody>
              {transactions.map((transaction) => (
                <tr
                  key={transaction.id}
                  className="border-b last:border-b-0 hover:bg-gray-100 cursor-pointer transition duration-150 ease-in-out"
                  onClick={() => handleRowClick(transaction.id)}
                >
                  <td className="py-2">{formatLocalDate(transaction.created_at)}</td>
                  <td className="py-2">
                    <span className="px-2 py-1 rounded-full text-xs font-semibold bg-green-100 text-green-800">
                      Succeeded
                    </span>
                  </td>
                  <td className="py-2">
                    {transaction.users ? renderUserNames(transaction.users) : 'N/A'}
                  </td>
                  <td className="py-2 text-right">{formatCurrency(transaction.gross_amount)}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="mt-4 flex justify-between items-center">
            <div className="text-sm text-gray-500">
              Showing {(currentPage - 1) * ITEMS_PER_PAGE + 1} to {Math.min(currentPage * ITEMS_PER_PAGE, totalCount)} of {totalCount} results
            </div>
            <div className="flex space-x-2">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="px-4 py-2 border rounded-md disabled:opacity-50"
              >
                Previous
              </button>
              <span className="px-4 py-2 border rounded-md bg-gray-100">
                Page {currentPage} of {totalPages}
              </span>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="px-4 py-2 border rounded-md disabled:opacity-50"
              >
                Next
              </button>
            </div>
          </div>
        </>
      )}
      {selectedTransactionId && (
        <TransactionDetailsPopup
          transactionId={selectedTransactionId}
          onClose={() => setSelectedTransactionId(null)}
        />
      )}
    </div>
  );
};

export default TransactionHistory;