import React, { ChangeEvent } from 'react';

interface SocialProps {
  instagram: string;
  setInstagram: (value: string) => void;
  showInstagram: boolean;
  handleCheckboxChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const Social: React.FC<SocialProps> = ({ instagram, setInstagram, showInstagram, handleCheckboxChange }) => {
  return (
    <div className="mb-6">
      <h2 className="text-xl font-bold mb-4">Social</h2>
      <div className="border border-gray-200 rounded-lg overflow-hidden">
        <div className="p-4">
          <div className="flex items-center justify-between mb-2">
            <label className="text-lg font-semibold">Instagram</label>
            <input
              type="checkbox"
              name="show_instagram"
              checked={showInstagram}
              onChange={handleCheckboxChange}
              className="h-5 w-5 text-blue-600 rounded focus:ring-blue-500"
            />
          </div>
          {showInstagram && (
            <div className="relative">
              <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-500">
                @
              </span>
              <input
                type="text"
                value={instagram}
                onChange={(e) => setInstagram(e.target.value)}
                placeholder="Enter username"
                className="w-full pl-7 p-2 border border-gray-300 rounded-md"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Social;