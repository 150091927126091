import React from 'react';

interface TabNavigationProps {
  activeTab: string;
  setActiveTab: (tab: string) => void;
  selectedRole: string | null;
}

const TabNavigation: React.FC<TabNavigationProps> = ({ activeTab, setActiveTab, selectedRole }) => (
  <div className="flex mb-4">
    <button
      className={`mr-4 ${activeTab === 'permissions' ? 'border-b-2 border-blue-500' : ''}`}
      onClick={() => setActiveTab('permissions')}
    >
      Permissions
    </button>
    {selectedRole && (
      <button
        className={`${activeTab === 'members' ? 'border-b-2 border-blue-500' : ''}`}
        onClick={() => setActiveTab('members')}
      >
        Manage Members
      </button>
    )}
  </div>
);

export default TabNavigation;
