import React from 'react';
import Header from './Components/Header';
import Hero from './Components/Hero';
import Feature1 from './Components/Feature1';
import Footer from './Components/Footer';

const App: React.FC = () => {
  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <main className="flex-grow">
        <Hero />
        <Feature1 />
      </main>
      <Footer />
    </div>
  );
};

export default App;