import React, { useState, useEffect, useRef } from 'react';
import { FiMenu, FiSearch, FiBell, FiChevronDown } from 'react-icons/fi';
import { useAuth } from '../../../Contexts/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../../../Assets/AgoraLogo.svg';


interface AppHeaderProps {
  toggleSidebar: () => void;
}

const AppHeader: React.FC<AppHeaderProps> = ({ toggleSidebar }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [fName, setFName] = useState('');
  const [lName, setLName] = useState('');
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { user, signOut } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const firstName = user?.user_metadata?.first_name || 'Name';
    const lastName = user?.user_metadata?.last_name || 'Unavailable';
    setFName(firstName);
    setLName(lastName);
  }, [user]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    // Implement search logic here
  };

  return (
    <header className="flex items-center justify-between p-4 bg-white border-b border-[#DADEE3]">
      <div className="flex items-center flex-grow">
        <div className="flex items-center lg:hidden">
          <button className="mr-4 text-gray-500" onClick={toggleSidebar}>
            <FiMenu size={24} />
          </button>
          <img src={Logo} alt="Agora Logo" className="w-6 h-auto" />
          <span className="ml-1 text-xl font-bold">Agora</span>
        </div>
        <div className="relative flex-grow max-w-xl hidden lg:block">
          <input
            type="text"
            placeholder="Coming Soon..."
            disabled
            className="w-full py-2 pl-10 pr-4 text-gray-500 bg-gray-100 rounded-md cursor-not-allowed"
          />
          <span className="absolute text-gray-400 transform -translate-y-1/2 pointer-events-none left-3 top-1/2">
            <FiSearch size={20} />
          </span>
        </div>
      </div>
      
      <div className="flex items-center ml-4">
      <button className="mr-4 text-gray-500 hidden lg:block">
        <FiBell size={20} />
      </button>
        <div className="relative" ref={dropdownRef}>
          <button 
            onClick={toggleDropdown}
            className="flex items-center px-3 py-2 text-gray-700 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
          >
            <span className="mr-2">{fName} {lName}</span>
            <FiChevronDown size={20} />
          </button>
          
          {isDropdownOpen && (
            <div className="absolute right-0 z-10 w-48 mt-2 bg-white border border-gray-200 rounded-md shadow-lg">
              <div className="py-1">
                <Link to="/dashboard" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Dashboard</Link>
                <Link to="/settings/orders" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Billing History</Link>
                <Link to="/settings" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Account Settings</Link>
              </div>
              <div className="border-t border-gray-100">
                <button 
                  className="block w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100"
                  onClick={() => {
                    signOut();
                    setIsDropdownOpen(false);
                    navigate('/');
                  }}
                >
                  Sign Out
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default AppHeader;