import React, { useState, useEffect } from 'react';
import { Outlet, Link, useLocation, useParams } from 'react-router-dom';
import { supabase } from '../../../supabase';

import { ReactComponent as Analytics } from '../../../Assets/Icons/analytics.svg';
import { ReactComponent as billingmanagement } from '../../../Assets/Icons/billingmanagement.svg';
import { ReactComponent as community } from '../../../Assets/Icons/community.svg';
import { ReactComponent as organizationSettings } from '../../../Assets/Icons/organizationSettings.svg';
import { ReactComponent as recruitment } from '../../../Assets/Icons/recruitment.svg';
import { ReactComponent as Roles } from '../../../Assets/Icons/Roles.svg';
import { ReactComponent as transactionhistory } from '../../../Assets/Icons/transactionhistory.svg';
import { ReactComponent as plus } from '../../../Assets/Icons/plus.svg';

const Admin = () => {
  const location = useLocation();
  const currentPath = location.pathname.split('/admin/')[1] || '';
  const { groupId } = useParams();
  const [showCreateEvent, setShowCreateEvent] = useState(false);
  const [showChapterPortal, setShowChapterPortal] = useState(false);

  useEffect(() => {
    const fetchOrganizationData = async () => {
      if (groupId) {
        const { data: orgData, error: orgError } = await supabase
          .from('organizations')
          .select('college_id, is_greek')
          .eq('organization_id', groupId)
          .single();

        if (orgError) {
          console.error('Error fetching organization data:', orgError);
        } else if (orgData) {
          setShowCreateEvent(orgData.college_id === 1 && orgData.is_greek === true);
        }

        const { data: portalData, error: portalError } = await supabase
          .rpc('check_chapter_portal', { p_group_id: groupId });

        if (portalError) {
          console.error('Error checking Chapter Portal:', portalError);
        } else {
          setShowChapterPortal(portalData);
        }
      }
    };

    fetchOrganizationData();
  }, [groupId]);

  const navItems = [
    { to: "", icon: Analytics, label: "Analytics" },
    { to: "billing", icon: billingmanagement, label: "Billing Management" },
    { to: "settings", icon: organizationSettings, label: "Organization Settings" },
    { to: "transactions", icon: transactionhistory, label: "Transaction History" },
    { to: "invites", icon: community, label: "Create Invite" },
    { to: "roles", icon: Roles, label: "Roles" },
    { to: "recruitment", icon: recruitment, label: "Recruitment" },
    { to: "knowledge", icon: billingmanagement, label: "Knowledge" },
    { to: "polls", icon: plus, label: "Polls" },
  ];

  if (showCreateEvent) {
    navItems.push({ to: "socials", icon: plus, label: "Create Event" });
  }

  if (showChapterPortal) {
    navItems.push({ to: "portal/pending", icon: plus, label: "Chapter Portal" });
  }

  return (
    <div className="flex h-full">
      <nav className="w-64 flex-shrink-0 overflow-y-auto bg-white border-r">
        <div className="p-4">
          <ul className="space-y-2">
            {navItems.map((item) => (
              <li key={item.to}>
                <Link
                  to={item.to}
                  className={`flex items-center w-full p-2 rounded-lg hover:bg-gray-100 ${
                    currentPath === item.to ? 'bg-gray-200' : ''
                  }`}
                >
                  <item.icon className="mr-2" />
                  {item.label}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </nav>
      <main className="flex-grow overflow-auto">
        <div className="p-8">
          <Outlet />
        </div>
      </main>
    </div>
  );
};

export default Admin;