import React, { useState, useEffect } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { fetchCampaigns } from '../../Contexts/supabaseRecruitment';

interface Campaign {
  id: string;
  name: string;
  created_at: string;
  has_interest_stage: boolean;
  organization_id: string;
}

const ViewCampaigns: React.FC = () => {
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [expandedCampaign, setExpandedCampaign] = useState<string | null>(null);
  const { groupId } = useParams<{ groupId: string }>();

  useEffect(() => {
    if (!groupId) return;

    const fetchData = async () => {
      try {
        const campaignsData = await fetchCampaigns(groupId);
        setCampaigns(campaignsData);
      } catch (error) {
        console.error('Error fetching campaigns:', error);
      }
    };
    fetchData();
  }, [groupId]);

  const toggleExpandCampaign = (campaignId: string) => {
    setExpandedCampaign(expandedCampaign === campaignId ? null : campaignId);
  };

  if (!groupId) {
    return <Navigate to="/some-error-page" />;
  }

  return (
    <div className="p-6 bg-gray-100 rounded-lg shadow-md max-w-3xl mx-auto">
      <h2 className="text-3xl font-bold mb-6 text-gray-800">Existing Campaigns</h2>
      {campaigns.map((campaign) => (
        <div key={campaign.id} className="mb-4 p-4 bg-white rounded-md shadow-sm">
          <h3 
            className="text-xl font-semibold cursor-pointer"
            onClick={() => toggleExpandCampaign(campaign.id)}
          >
            {campaign.name}
          </h3>
          {expandedCampaign === campaign.id && (
            <div className="mt-4">
              <p><strong>Created At:</strong> {new Date(campaign.created_at).toLocaleString()}</p>
              <p><strong>Has Interest Stage:</strong> {campaign.has_interest_stage ? 'Yes' : 'No'}</p>
            </div>
          )}
        </div>
      ))}
      {campaigns.length === 0 && (
        <p className="text-gray-600">No campaigns found. Create a new campaign to get started.</p>
      )}
    </div>
  );
};

export default ViewCampaigns;