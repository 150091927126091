import React from 'react';
import { Outlet } from 'react-router-dom';
import GroupTabs from './GroupTabs';

function GroupDashboard() {
  return (
    <div className="flex flex-col h-screen bg-[#F8FAFC] overflow-hidden">
      <div className="flex-none">
        <GroupTabs />
      </div>
      <div className="flex-grow overflow-y-auto">
        <Outlet />
      </div>
    </div>
  );
}

export default GroupDashboard;
