import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import AddApplicantPopup from './AddApplicantPopup';
import { fetchCampaigns, checkAddApplicantVisibility } from '../../../Contexts/supabaseRecruitment';
import Dropdown from './Dropdown';

interface Campaign {
  id: string;
  name: string;
}

export interface RecruitHeaderProps {
  campaignDetails: {
    id: string;
    name: string;
  } | null;
  userInCommittee: boolean;
}

const RecruitHeader: React.FC<RecruitHeaderProps> = ({ campaignDetails, userInCommittee }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [selectedCampaign, setSelectedCampaign] = useState<string>('');
  const [hasInterestStage, setHasInterestStage] = useState<boolean>(false);
  const { groupId, campaignId } = useParams<{ groupId: string; campaignId: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (groupId) {
      const fetchData = async () => {
        try {
          const data = await fetchCampaigns(groupId);
          setCampaigns(data);
          if (campaignId) {
            setSelectedCampaign(campaignId);
          } else if (data.length > 0) {
            setSelectedCampaign(data[0].id);
            navigate(`/dashboard/${groupId}/recruitment/${data[0].id}/applicants`);
          }
        } catch (error) {
          console.error('Error fetching campaigns:', error);
        }
      };
      fetchData();
    }
  }, [groupId, campaignId, navigate]);

  useEffect(() => {
    const checkInterestStage = async () => {
      if (selectedCampaign) {
        const hasInterest = await checkAddApplicantVisibility(selectedCampaign);
        setHasInterestStage(hasInterest);
      }
    };
    checkInterestStage();
  }, [selectedCampaign]);
  
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const togglePopup = () => setIsPopupOpen(!isPopupOpen);
  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);
  const closeDropdown = () => setIsDropdownOpen(false);

  const handleSelectCampaign = (campaignId: string) => {
    setSelectedCampaign(campaignId);
    closeDropdown();
    const currentStage = location.pathname.split('/').pop() || 'applicants';
    navigate(`/dashboard/${groupId}/recruitment/${campaignId}/${currentStage}`);
  };

  const getCurrentStage = () => {
    const path = location.pathname;
    if (path.includes('/applicants')) return 'Applicants';
    if (path.includes('/interview')) return 'Interview';
    if (path.includes('/interest')) return 'Interest';
    if (path.includes('/decisions')) return 'Decisions';
    if (path.includes('/accepted')) return 'Accepted Stage';
    return 'Unknown Stage';
  };

  const shouldShowAddApplicant = () => {
    const currentPath = location.pathname;
    if (hasInterestStage) {
      return currentPath.includes('/interest');
    } else {
      return currentPath.includes('/applicants');
    }
  };

  if (!campaignDetails) {
    return null;
  }

  return (
    <div className="bg-white">
      <div className="flex flex-col md:flex-row items-start md:items-center justify-between px-8 py-4">
        <h1 className="text-2xl font-semibold mb-2 md:mb-0">{getCurrentStage()}</h1>
        <div className="flex flex-col md:flex-row items-start md:items-center space-y-4 md:space-y-0 md:space-x-4 w-full md:w-auto">
          {userInCommittee && shouldShowAddApplicant() && (
            <button
              onClick={togglePopup}
              className="px-4 py-2 text-white transition-colors bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              + Add Applicant
            </button>
          )}
          <div className="relative w-full md:w-auto" ref={dropdownRef}>
            <button
              onClick={toggleDropdown}
              className="flex items-center justify-between w-full md:w-auto px-4 py-2 space-x-2 transition-colors bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              <span>{campaigns.find(c => c.id === selectedCampaign)?.name || 'Select Campaign'}</span>
              <span className="text-gray-500">▼</span>
            </button>
            <Dropdown
              isOpen={isDropdownOpen}
              onClose={closeDropdown}
              campaigns={campaigns}
              selectedCampaign={selectedCampaign}
              onSelectCampaign={handleSelectCampaign}
            />
          </div>
        </div>
      </div>
      {isPopupOpen && (
        <AddApplicantPopup 
          onClose={togglePopup} 
          selectedCampaign={selectedCampaign}
        />
      )}
    </div>
  );
};

export default RecruitHeader;