import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { supabase } from '../../supabase';

interface AccessControl {
  id: string;
  initiatorOrganizationName: string;
  rosterAccess: boolean;
  contactAccess: boolean;
}

const AccessControlsPage: React.FC = () => {
  const { groupId } = useParams<{ groupId: string }>();
  const [accessControls, setAccessControls] = useState<AccessControl[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchAccessControls();
  }, [groupId]);

  const fetchAccessControls = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('social_request_permissions')
        .select(`
          permission_id,
          initiator_organization:organizations!social_request_permissions_initiator_organization_id_fkey(name),
          roster_status,
          contact_status
        `)
        .eq('requested_organization_id', groupId)
        .or('roster_status.eq.Approved,contact_status.eq.Approved');

      if (error) throw error;

      const formattedData: AccessControl[] = data.map((control: any) => ({
        id: control.permission_id.toString(),
        initiatorOrganizationName: control.initiator_organization.name,
        rosterAccess: control.roster_status === 'Approved',
        contactAccess: control.contact_status === 'Approved',
      }));

      setAccessControls(formattedData);
    } catch (err) {
      setError('Failed to fetch access controls');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleRemovePermission = async (controlId: string, type: 'roster' | 'contact') => {
    try {
      const { data, error } = await supabase
        .from('social_request_permissions')
        .update({ [`${type}_status`]: 'Request' })
        .eq('permission_id', controlId);

      if (error) throw error;

      setAccessControls(controls => 
        controls.map(control => 
          control.id === controlId
            ? { ...control, [`${type}Access`]: false }
            : control
        )
      );
    } catch (err) {
      console.error('Failed to remove permission:', err);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="p-6">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-2xl font-semibold text-gray-800">Access Controls</h2>
        <span className="text-sm text-gray-500">
          Removing permission only works moving forward as they won't have access to the latest information
        </span>
      </div>
      <div className="space-y-4">
        {accessControls.map((control) => (
          <div key={control.id} className="bg-white rounded-lg shadow-sm border border-gray-200 p-4">
            <h3 className="text-lg font-semibold text-gray-800 mb-2">{control.initiatorOrganizationName}</h3>
            <div className="space-y-2">
              {control.rosterAccess && (
                <div className="flex justify-between items-center">
                  <span className="text-gray-600">Has Roster Access</span>
                  <button
                    onClick={() => handleRemovePermission(control.id, 'roster')}
                    className="bg-red-500 text-white text-xs font-semibold px-3 py-1 rounded-md hover:bg-red-600 transition-colors duration-200"
                  >
                    Remove Permission
                  </button>
                </div>
              )}
              {control.contactAccess && (
                <div className="flex justify-between items-center">
                  <span className="text-gray-600">Has Contact Information Access</span>
                  <button
                    onClick={() => handleRemovePermission(control.id, 'contact')}
                    className="bg-red-500 text-white text-xs font-semibold px-3 py-1 rounded-md hover:bg-red-600 transition-colors duration-200"
                  >
                    Remove Permission
                  </button>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AccessControlsPage;