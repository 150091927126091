import { supabase } from '../../../supabase';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { format, addMonths, subMonths, startOfMonth, endOfMonth, startOfWeek, endOfWeek, addDays, isSameMonth, isSameDay, parseISO } from 'date-fns';
import { toZonedTime, fromZonedTime } from 'date-fns-tz';
import { IoChevronBackOutline, IoChevronForwardOutline, IoAddOutline, IoCloseOutline } from "react-icons/io5";

interface Event {
  event_id: string;
  title: string;
  event_time: Date;
  role_id: string | null;
  color: string;
  is_public: boolean;
}

interface Role {
  role_id: string;
  role_name: string;
}

const colorChoices = [
  { color: '#BAE1FF', name: 'Blue' },
  { color: '#BAFFC9', name: 'Green' },
  { color: '#FFFFBA', name: 'Yellow' },
];

export default function EventCalendar() {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [events, setEvents] = useState<Event[]>([]);
  const [roles, setRoles] = useState<Role[]>([]);
  const [newEventTitle, setNewEventTitle] = useState("");
  const [newEventRole, setNewEventRole] = useState("Everyone");
  const [newEventDate, setNewEventDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [newEventTime, setNewEventTime] = useState("12:00");
  const [newEventColor, setNewEventColor] = useState(colorChoices[0].color);
  const [selectedEventId, setSelectedEventId] = useState<string | null>(null);
  const { groupId } = useParams<{ groupId: string }>();
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    const fetchRoles = async () => {
      const { data, error } = await supabase
        .from('roles')
        .select('role_id, role_name')
        .eq('group_id', groupId);

      if (error) {
        console.error('Error fetching roles:', error);
      } else {
        setRoles(data || []);
      }
    };

    const fetchEvents = async () => {
        const monthStart = startOfMonth(currentDate).toISOString();
        const monthEnd = endOfMonth(currentDate).toISOString();
        
        const userResponse = await supabase.auth.getUser();
        const userId = userResponse.data?.user?.id;
        
        if (!userId) {
            console.error('User not authenticated');
            return;
        }
        
        const { data, error } = await supabase.rpc('get_user_visible_events', {
            p_user_id: userId,
            p_group_id: groupId,
            p_start_time: monthStart,
            p_end_time: monthEnd,
        });
        
        if (error) {
            console.error('Error fetching events:', error);
        } else {
            const convertedEvents = data?.map((event: any) => ({
            ...event,
            event_time: toZonedTime(parseISO(event.event_time), userTimeZone),
            })) || [];
            setEvents(convertedEvents);
        }
    };

    fetchRoles();
    fetchEvents();
  }, [groupId, currentDate, userTimeZone]);

  const monthStart = startOfMonth(currentDate);
  const monthEnd = endOfMonth(monthStart);
  const startDate = startOfWeek(monthStart);
  const endDate = endOfWeek(monthEnd);

  const rows = [];
  let days = [];
  let day = startDate;

  while (day <= endDate) {
    for (let i = 0; i < 7; i++) {
      const cloneDay = day;
      days.push(
        <div 
          key={day.toISOString()} 
          className={`p-2 border-r border-b relative min-h-[100px] ${
            !isSameMonth(day, monthStart)
              ? "bg-gray-50 text-gray-400"
              : isSameDay(day, new Date())
              ? "bg-blue-50"
              : "bg-white"
          }`}
        >
          <div className={`text-sm font-medium ${isSameDay(day, new Date()) ? "text-blue-600" : ""}`}>
            {format(day, 'd')}
          </div>
          {isSameDay(day, new Date()) && (
            <span className="absolute px-1 text-xs text-blue-800 bg-blue-100 rounded top-2 right-2">Today</span>
          )}
          {events
            .filter(event => isSameDay(event.event_time, cloneDay))
            .map(event => (
                <div 
                key={event.event_id} 
                className={`text-xs p-1 mt-1 rounded truncate relative ${selectedEventId === event.event_id ? "border border-red-600" : ""}`} 
                style={{ backgroundColor: event.color, color: 'black' }}
                onClick={() => setSelectedEventId(selectedEventId === event.event_id ? null : event.event_id)}
                >
                {format(event.event_time, 'HH:mm')} - {event.title}
                {selectedEventId === event.event_id && (
                    <button 
                    className="absolute top-0 right-0 p-1"
                    onClick={() => deleteEvent(event.event_id)}
                    >
                    <IoCloseOutline size={14} color="red" />
                    </button>
                )}
                </div>
            ))
           }
        </div>
      );
      day = addDays(day, 1);
    }
    rows.push(
      <div key={day.toISOString()} className="grid grid-cols-7">
        {days}
      </div>
    );
    days = [];
  }

  const addEvent = async () => {
    if (newEventTitle) {
      const [hours, minutes] = newEventTime.split(':').map(Number);
      
      const combinedDateTime = new Date(`${newEventDate}T${newEventTime}:00`);
      
      const utcEventDate = fromZonedTime(combinedDateTime, userTimeZone);
  
      const newEvent = {
        title: newEventTitle,
        event_time: utcEventDate.toISOString(),  
        role_id: newEventRole === "Everyone" ? null : roles.find(role => role.role_name === newEventRole)?.role_id,
        color: newEventColor,
        organization_id: groupId,
        is_public: newEventRole === "Everyone",
      };
  
      const { data, error } = await supabase
        .from('calendar_events')
        .insert([newEvent])
        .select();
  
      if (error) {
        console.error('Error adding event:', error);
      } else if (data) {
        const addedEvent = {
          ...data[0],
          event_time: toZonedTime(parseISO(data[0].event_time), userTimeZone),
        };
        setEvents([...events, addedEvent]);
      }
  
      setNewEventTitle("");
      setNewEventRole("Everyone");
      setNewEventDate(format(new Date(), "yyyy-MM-dd"));
      setNewEventTime("12:00");
    }
  };

  const deleteEvent = async (eventId: string) => {
    const { error } = await supabase
      .from('calendar_events')
      .delete()
      .eq('event_id', eventId);

    if (error) {
      console.error('Error deleting event:', error);
    } else {
      setEvents(events.filter(event => event.event_id !== eventId));
      setSelectedEventId(null);
    }
  };

  return (
    <div className="p-6 bg-white rounded-lg shadow">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-2xl font-semibold text-gray-800">Calendar</h2>
        <div className="flex items-center space-x-2">
          <button onClick={() => setCurrentDate(subMonths(currentDate, 1))} className="p-2 rounded-full hover:bg-gray-100">
            <IoChevronBackOutline size={20} />
          </button>
          <h3 className="text-lg font-medium text-gray-600">{format(currentDate, 'MMMM yyyy')}</h3>
          <button onClick={() => setCurrentDate(addMonths(currentDate, 1))} className="p-2 rounded-full hover:bg-gray-100">
            <IoChevronForwardOutline size={20} />
          </button>
        </div>
      </div>
      <div className="grid grid-cols-7 gap-px overflow-hidden bg-gray-200 border border-gray-200 rounded-t-lg">
        {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
          <div key={day} className="py-2 font-medium text-center text-gray-500 bg-gray-100">{day}</div>
        ))}
      </div>
      <div className="overflow-hidden border-l border-gray-200 rounded-b-lg">
        {rows}
      </div>
      <div className="flex flex-wrap gap-2 mt-6">
        <input 
          type="text" 
          value={newEventTitle} 
          onChange={(e) => setNewEventTitle(e.target.value)} 
          placeholder="New event title" 
          className="flex-grow p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        />
        <select
          value={newEventRole}
          onChange={(e) => setNewEventRole(e.target.value)}
          className="p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        >
          <option value="Everyone">Everyone</option>
          {roles.map(role => (
            <option key={role.role_id} value={role.role_name}>{role.role_name}</option>
          ))}
        </select>
        <input
          type="date"
          value={newEventDate}
          onChange={(e) => setNewEventDate(e.target.value)}
          className="p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        />
        <input
          type="time"
          value={newEventTime}
          onChange={(e) => setNewEventTime(e.target.value)}
          className="p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        />
        <select
          value={newEventColor}
          onChange={(e) => setNewEventColor(e.target.value)}
          className="p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        >
          {colorChoices.map((color) => (
            <option key={color.color} value={color.color} style={{backgroundColor: color.color}}>
              {color.name}
            </option>
          ))}
        </select>
        <button 
          onClick={addEvent} 
          className="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
        >
          <IoAddOutline size={20} />
        </button>
      </div>
    </div>
  );
}
