import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { supabase } from '../../../supabase';
import { showToast } from '../../../Toast';

interface EventDetails {
  id: string;
  name: string;
  campaign_id: string;
  has_interest_stage: boolean;
}

interface ApplicantDetails {
  id?: string;
  full_name: string;
  email: string;
  phone_number?: string;
  major?: string;
  graduation_year?: string;
}

const EventCheckIn: React.FC = () => {
  const { eventId } = useParams<{ eventId: string }>();
  const [applicantDetails, setApplicantDetails] = useState<ApplicantDetails>({
    full_name: '',
    email: '',
    phone_number: '',
    major: '',
    graduation_year: '',
  });
  const [eventDetails, setEventDetails] = useState<EventDetails | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        const { data, error } = await supabase
          .from('events')
          .select('id, name, campaign_id, campaigns(has_interest_stage)')
          .eq('id', eventId)
          .single();

        if (error) throw error;

        const serializedData = JSON.parse(JSON.stringify(data));

        setEventDetails({
          id: serializedData.id,
          name: serializedData.name,
          campaign_id: serializedData.campaign_id,
          has_interest_stage: serializedData.campaigns?.has_interest_stage ?? false,
        });
      } catch (err) {
        console.error('Error fetching event details:', err);
        setError('Failed to load event details');
      } finally {
        setLoading(false);
      }
    };

    fetchEventDetails();
  }, [eventId]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setApplicantDetails(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      if (!eventDetails) throw new Error('Event details not loaded');

      const { data: existingApplicant, error: applicantError } = await supabase
        .from('applicants')
        .select('id, stage')
        .eq('email', applicantDetails.email)
        .eq('campaign_id', eventDetails.campaign_id)
        .single();

      if (applicantError && applicantError.code !== 'PGRST116') {
        throw applicantError;
      }

      let applicantId: string;

      if (!existingApplicant) {
        if (!eventDetails.has_interest_stage && !showAdditionalFields) {
          setShowAdditionalFields(true);
          setLoading(false);
          return;
        }

        const { data: newApplicant, error: createError } = await supabase
          .from('applicants')
          .insert({
            campaign_id: eventDetails.campaign_id,
            full_name: applicantDetails.full_name,
            email: applicantDetails.email,
            phone_number: applicantDetails.phone_number,
            major: applicantDetails.major,
            graduation_year: applicantDetails.graduation_year,
            stage: eventDetails.has_interest_stage ? 'interest' : 'applicant',
          })
          .select('id')
          .single();

        if (createError) throw createError;
        applicantId = newApplicant.id;
      } else {
        applicantId = existingApplicant.id;

        console.log("Event Details: ", eventDetails, "Existing Applicant: ", existingApplicant);

        
        if (eventDetails.has_interest_stage && existingApplicant.stage !== 'applicant') {
          throw new Error('Only applicants are allowed to check in for this event.');
        }
      }

      const { error: attendanceError } = await supabase
        .from('attendance')
        .insert({
          event_id: eventId,
          applicant_id: applicantId,
          campaign_id: eventDetails.campaign_id,
        });

      if (attendanceError) throw attendanceError;

      showToast('Check-In Successful!');
      setApplicantDetails({
        full_name: '',
        email: '',
        phone_number: '',
        major: '',
        graduation_year: '',
      });
      setShowAdditionalFields(false);
    } catch (err) {
      console.error('Error during check-in:', err);
      setError(`Failed to complete check-in: ${err instanceof Error ? err.message : 'Unknown error'}`);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!eventDetails) return <div>Event not found</div>;

  return (
    <div className="max-w-md mx-auto mt-10">
      <h1 className="text-2xl font-bold mb-5">Check-in for {eventDetails.name}</h1>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="full_name" className="block mb-1">Full Name</label>
          <input
            type="text"
            id="full_name"
            name="full_name"
            value={applicantDetails.full_name}
            onChange={handleInputChange}
            required
            className="w-full px-3 py-2 border rounded"
          />
        </div>
        <div>
          <label htmlFor="email" className="block mb-1">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={applicantDetails.email}
            onChange={handleInputChange}
            required
            className="w-full px-3 py-2 border rounded"
          />
        </div>
        {showAdditionalFields && (
          <>
            <div>
              <label htmlFor="phone_number" className="block mb-1">Phone Number</label>
              <input
                type="tel"
                id="phone_number"
                name="phone_number"
                value={applicantDetails.phone_number}
                onChange={handleInputChange}
                required
                className="w-full px-3 py-2 border rounded"
              />
            </div>
            <div>
              <label htmlFor="major" className="block mb-1">Major</label>
              <input
                type="text"
                id="major"
                name="major"
                value={applicantDetails.major}
                onChange={handleInputChange}
                required
                className="w-full px-3 py-2 border rounded"
              />
            </div>
            <div>
              <label htmlFor="graduation_year" className="block mb-1">Graduation Year</label>
              <input
                type="text"
                id="graduation_year"
                name="graduation_year"
                value={applicantDetails.graduation_year}
                onChange={handleInputChange}
                required
                className="w-full px-3 py-2 border rounded"
              />
            </div>
          </>
        )}
        <button
          type="submit"
          disabled={loading}
          className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600"
        >
          {loading ? 'Checking in...' : 'Check In'}
        </button>
      </form>
    </div>
  );
};

export default EventCheckIn;