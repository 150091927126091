import './App.css';
import { BrowserRouter, Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import Dashboard from './Pages/Dashboard/Dashboard';
import GroupDashboard from './Pages/Dashboard/Components/GroupDashboard'; 
import Settings from './Pages/Misc/Settings';
import SettingsComponent from './Pages/Misc/Components/SettingsComponent';
import OrderHistoryComponent from './Pages/Misc/Components/OrderHistoryComponent';

import Return from './Pages/Misc/Return';
import PayTest from './Pages/Payments/PayTest';
import PrivateRoute from './Contexts/PrivateRoute';
import GroupPrivateRoute from './Contexts/GroupPrivateRoute';
import AdminPrivateRoute from './Contexts/AdminPrivateRoute';

import LandingPage from './Pages/Components/LandingPage/LandingPage3';

import PrivacyPolicy from './Pages/Legal/Privacy';
import TermsAndConditions from './Pages/Legal/Terms';

import Login from './Pages/Misc/Login';
import Signup from './Pages/Misc/Signup';
import ForgotPassword from './Pages/Misc/ForgotPassword';
import { AuthProvider } from './Contexts/AuthContext'; 
import BillingComponent from './Pages/Dashboard/Components/BillingComponent';
import CalendarComponent from './Pages/Dashboard/Components/CalendarComponent';
import RecruitmentComponent from './Pages/Dashboard/Components/RecruitmentComponent';
import CheckoutPage from './Pages/Payments/CheckoutPage';
import SuccessPage from './Pages/Payments/SuccessPage';

import AboutComponent from './Pages/Dashboard/Components/AboutComponent';  

import Interest from './Pages/Recruitment/Interest';
import Applicants from './Pages/Recruitment/Applicants';
import Interview from './Pages/Recruitment/Interview';
import Decisions from './Pages/Recruitment/Decisions';
import Accepted from './Pages/Recruitment/Accepted';

import CreatePoll from './Pages/Admin/CreatePoll';
import PollVoting from './Pages/Polls/PollVoting';

import Layout from './Pages/Layout';


import GroupAdmin from './Pages/Dashboard/Components/GroupAdmin'; 
import RolesComponent from './Pages/Admin/RolesComponent'; 
import KnowledgeComponent from './Pages/Admin/KnowledgeComponent';
import CommitteesComponent from './Pages/Admin/CommitteesComponent';
import CreateCommittees from './Pages/Admin/CreateCommittee';
import ViewCommittees from './Pages/Admin/ViewCommittees';
import ViewCampaigns from './Pages/Admin/ViewCampaign';
import CreateCampaign from './Pages/Admin/CreateCampaign';
import CampaignEvents from './Pages/Admin/CampaignEvents';
import PortalComponent from './Pages/Admin/PortalComponent'; 
import DoneDeadlines from './Pages/Admin/DoneDeadlines';

import CampaignsComponent from './Pages/Admin/CampaignsComponent';
import Analytics from './Pages/Dashboard/Components/Analytics'; 

import BillingManagement from './Pages/Dashboard/Components/BillingManagement'; 
import PortalManagement from './Pages/Dashboard/Components/PortalManagement'; 

import Subscriptions from './Pages/Dashboard/Components/CreateSubscription';
import AddToSubscription from './Pages/Dashboard/Components/AddToSubscription';
import OneTimePayments from './Pages/Dashboard/Components/OneTimePayments';
import ViewSubscriptions from './Pages/Dashboard/Components/ViewSubscriptions';
import ViewPendingBills from './Pages/Dashboard/Components/ViewPendingBills';
import BillingAccounts from './Pages/Dashboard/Components/BillingAccounts';
import TransactionHistory from './Pages/Dashboard/Components/TransactionHistory';

import RecruitmentManagement from './Pages/Dashboard/Components/RecruitmentManagement'; 
import EventCheckIn from './Pages/Components/Recruitment/EventCheckIn';

import SocialsManagement from './Pages/Dashboard/Components/SocialsManagement'; 
import RequestSocials from './Pages/Admin/RequestSocials';
import PendingSocialPermissions from './Pages/Admin/PendingSocialPermissions';
import SocialSettings from './Pages/Admin/SocialSettings'; 
import AccessControls from './Pages/Admin/AccessControls';
import CreateSocials from './Pages/Admin/CreateSocials';

import OrganizationSettings from './Pages/Dashboard/Components/OrganizationSettings'; 
import ProductManagement from './Pages/Dashboard/Components/ProductManagement'; 
import InviteForm from './Pages/Dashboard/Components/InviteForm'; 
import PublicPage from './Pages/Dashboard/Components/PublicPage';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import FeedbackForm from './Pages/Misc/Feedback';
// import CommunityPage from './Pages/Misc/CommunityPage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const stripePromise = loadStripe('pk_live_51OjRt8JaLwIxLZCHSm4dwSRT8ZOTr9ZpTadvSGIYyqLaMK70R6Ncl81fItlysyIb7mOaMSXIDz3zbCaFQwtZQhxo00Nplr6fMb');


const FallbackRoute = () => {
  let navigate = useNavigate();
  
  useEffect(() => {
    navigate('/dashboard');
  }, [navigate]);

  return null;
}

export function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Elements stripe={stripePromise}>
          <ToastContainer />
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/paytest" element={<PayTest />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
            <Route path="/:collegename/:groupId" element={<PublicPage />} />
            <Route path="/event/:eventId/check-in" element={<EventCheckIn />} />
            <Route path="/polls/:pollId" element={<PollVoting />} />
            <Route element={<PrivateRoute />}>
              <Route element={<Layout />}>
                <Route path="/dashboard">
                  <Route index element={<Dashboard />} />
                  <Route path="/dashboard/:groupId" element={<GroupPrivateRoute requiredRoles={['member', 'admin']} />}>
                    <Route path="" element={<GroupDashboard />}>
                      <Route index element={<AboutComponent />} />
                      <Route path="billing" element={<BillingComponent />} />
                      <Route path="calendar" element={<CalendarComponent />} />
                      <Route path="recruitment/:campaignId/*" element={<RecruitmentComponent />}>
                        <Route path="applicants" element={<Applicants />} />
                        <Route path="interview" element={<Interview />} />
                        <Route path="interest" element={<Interest />} />
                        <Route path="decisions" element={<Decisions />} />
                        <Route path="accepted" element={<Accepted />} />
                      </Route>
                      <Route path="admin" element={<AdminPrivateRoute requiredPermissions={[]} />}>
                        <Route path="" element={<GroupAdmin />}>
                          <Route index element={<Analytics />} />
                          <Route path="billing" element={<AdminPrivateRoute requiredPermissions={["Admin Access", "Billing Access"]} />}>
                            <Route element={<BillingManagement />}>
                              <Route index element={<Navigate to="subscriptions" replace />} />
                              <Route path="subscriptions" element={<Subscriptions />} />
                              <Route path="subscriptions/add" element={<AddToSubscription />} />
                              <Route path="subscriptions/view" element={<ViewSubscriptions />} />
                              <Route path="payments" element={<OneTimePayments />} />
                              <Route path="payments/pending" element={<ViewPendingBills />} />
                              <Route path="accounts" element={<BillingAccounts />} />
                              <Route path="transactions" element={<TransactionHistory />} />
                            </Route>
                          </Route>
                          <Route path="settings" element={<AdminPrivateRoute requiredPermissions={["Admin Access"]} />}>
                            <Route index element={<OrganizationSettings />} />
                          </Route>
                          {/* <Route path="products" element={<AdminPrivateRoute requiredPermissions={["Admin Access", "Product Management Access"]} />}>
                            <Route index element={<ProductManagement />} />
                          </Route> */}
                          <Route path="transactions" element={<AdminPrivateRoute requiredPermissions={["Admin Access", "Transaction History Access"]} />}>
                            <Route index element={<TransactionHistory />} />
                          </Route>
                          <Route path="invites" element={<AdminPrivateRoute requiredPermissions={["Admin Access", "Invite Management Access"]} />}>
                            <Route index element={<InviteForm />} />
                          </Route>
                          <Route path="roles" element={<AdminPrivateRoute requiredPermissions={["Admin Access", "Roles Management Access"]} />}>
                            <Route index element={<RolesComponent />} />
                          </Route>
                          <Route path="knowledge" element={<AdminPrivateRoute requiredPermissions={["Admin Access"]} />}>
                            <Route index element={<KnowledgeComponent />} />
                          </Route>
                          <Route path="portal" element={<AdminPrivateRoute requiredPermissions={["Admin Access"]} />}>
                            <Route element={<PortalManagement />}>
                              <Route path="pending" element={<PortalComponent />} />
                              <Route path="done" element={<DoneDeadlines />} />
                            </Route>
                          </Route>
                          <Route path="committees" element={<AdminPrivateRoute requiredPermissions={["Admin Access", "Committees Management Access"]} />}>
                            <Route index element={<CommitteesComponent />} />
                          </Route>
                          <Route path="polls" element={<AdminPrivateRoute requiredPermissions={["Admin Access"]} />}>
                            <Route index element={<CreatePoll />} />
                          </Route>
                          <Route path="socials" element={<AdminPrivateRoute requiredPermissions={["Admin Access"]} />}>
                          <Route element={<SocialsManagement />}>
                              <Route index element={<Navigate to="requests" replace />} />
                              <Route path="requests" element={<RequestSocials />} />
                              <Route path="pending" element={<PendingSocialPermissions />} />
                              <Route path="create" element={<CreateSocials />} />
                              <Route path="settings" element={<SocialSettings />} />
                              <Route path="settings/access" element={<AccessControls />} />
                            </Route>
                          </Route>
                          <Route path="recruitment" element={<AdminPrivateRoute requiredPermissions={["Admin Access", "Campaigns Management Access"]} />}>
                            <Route element={<RecruitmentManagement />}>
                              <Route index element={<Navigate to="campaigns" replace />} />
                              <Route path="committees" element={<CreateCommittees />} />
                              <Route path="campaigns" element={<CreateCampaign />} />
                              <Route path="committees/view" element={<ViewCommittees />} />
                              <Route path="campaigns/view" element={<ViewCampaigns />} />
                              <Route path="events" element={<CampaignEvents />} />
                            </Route>
                          </Route>
                        </Route>
                      </Route>
                    </Route>
                  </Route>
                </Route>
                <Route path="/settings" element={<Settings />}>
                  <Route index element={<SettingsComponent />} />
                  <Route path="orders" element={<OrderHistoryComponent />} />
                </Route>
                <Route path="/checkout" element={<CheckoutPage />} />
                <Route path="/feedback" element={<FeedbackForm />} />
                {/* <Route path="/:collegename" element={<CommunityPage />} /> */}
                <Route path="/success" element={<SuccessPage />} />
                <Route path="/return" element={<Return />} />
                {/* <Route path="/polls/:pollId" element={<PollVoting />} /> */}
              </Route>
            </Route>
            <Route path="*" element={<FallbackRoute />} />
          </Routes>
        </Elements>
      </AuthProvider>
    </BrowserRouter>
  );
}