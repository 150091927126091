import React, { useState, FormEvent } from 'react';
import { addApplicant } from '../../../Contexts/supabaseRecruitment';
import { FaTimes, FaUser, FaGraduationCap, FaCalendarAlt, FaClipboardList } from 'react-icons/fa';

interface AddApplicantPopupProps {
  onClose: () => void;
  selectedCampaign: string;
}

const AddApplicantPopup: React.FC<AddApplicantPopupProps> = ({ onClose, selectedCampaign }) => {
  const [applicantName, setApplicantName] = useState<string>('');
  const [applicantMajor, setApplicantMajor] = useState<string>('');
  const [applicantGradTerm, setApplicantGradTerm] = useState<string>('');
  const [applicantStatus, setApplicantStatus] = useState<string>('');

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    try {
      await addApplicant(selectedCampaign, applicantName, applicantMajor, applicantGradTerm, applicantStatus, 'user_id');
      alert('Applicant added successfully');
      onClose();
    } catch (error) {
      console.error('Error adding applicant:', error);
      alert('Failed to add applicant');
    }
  };

  return (
    <div 
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-40 backdrop-blur-sm"
      onClick={onClose}
    >
      <div 
        className="bg-white p-8 rounded-lg shadow-xl relative max-w-md w-full"
        onClick={e => e.stopPropagation()}
      >
        <button 
          onClick={onClose} 
          className="absolute top-4 right-4 text-gray-400 hover:text-gray-600 transition-colors"
          aria-label="Close"
        >
          <FaTimes className="text-xl" />
        </button>
        <h2 className="text-2xl font-semibold mb-6">Add Applicant</h2>
        <form className="space-y-4" onSubmit={handleSubmit}>
          <div className="relative">
            <FaUser className="absolute top-3 left-3 text-gray-400" />
            <input 
              type="text" 
              placeholder="Applicant Name" 
              className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-opacity-50" 
              value={applicantName}
              onChange={(e) => setApplicantName(e.target.value)}
            />
          </div>
          <div className="relative">
            <FaGraduationCap className="absolute top-3 left-3 text-gray-400" />
            <input 
              type="text" 
              placeholder="Applicant Major" 
              className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-opacity-50" 
              value={applicantMajor}
              onChange={(e) => setApplicantMajor(e.target.value)}
            />
          </div>
          <div className="relative">
            <FaCalendarAlt className="absolute top-3 left-3 text-gray-400" />
            <input 
              type="text" 
              placeholder="Applicant Grad Term" 
              className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-opacity-50" 
              value={applicantGradTerm}
              onChange={(e) => setApplicantGradTerm(e.target.value)}
            />
          </div>
          <div className="relative">
            <FaClipboardList className="absolute top-3 left-3 text-gray-400" />
            <input 
              type="text" 
              placeholder="Applicant Status" 
              className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-opacity-50" 
              value={applicantStatus}
              onChange={(e) => setApplicantStatus(e.target.value)}
            />
          </div>
          <button 
            type="submit" 
            className="w-full py-2 px-4 bg-gray-800 text-white rounded-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-opacity-50 transition-colors"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddApplicantPopup;