import React, { useState, useEffect, FormEvent } from 'react';
import { createGroupInvite } from '../../../Contexts/supabaseService';
import { fetchInvites, deleteInvite } from '../../../Contexts/supabaseRPC';

import { useParams } from 'react-router-dom';

interface Invite {
  code: string;
  max_uses: number;
  used_count: number;
  expires_at: string | null;
}

const InviteForm: React.FC = () => {
  const [expiresAt, setExpiresAt] = useState<string>('');
  const [maxUses, setMaxUses] = useState<number>(1);
  const [isUnlimited, setIsUnlimited] = useState<boolean>(false);
  const [invites, setInvites] = useState<Invite[]>([]);
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const [inviteToDelete, setInviteToDelete] = useState<Invite | null>(null);
  const { groupId } = useParams<{ groupId: string }>();

  useEffect(() => {
    async function loadInvites() {
      try {
        if (!groupId) return;
        const data = await fetchInvites(groupId);
        setInvites(data);
      } catch (error) {
        console.error('Error fetching invites:', error);
      }
    }
    loadInvites();
  }, [groupId]);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    try {
      if (!groupId) return;
      const data = await createGroupInvite(groupId, expiresAt, isUnlimited ? -1 : maxUses);
      if (data && data.length > 0) {
        setExpiresAt('');
        setMaxUses(1);
        setIsUnlimited(false);
        const refreshedInvites = await fetchInvites(groupId);
        setInvites(refreshedInvites);
      } else {
        throw new Error('No data returned from createGroupInvite');
      }
    } catch (error) {
      console.error('Error creating invite:', error);
    }
  };

  const handleDeleteInvite = async () => {
    try {
      if (!inviteToDelete) return;
      await deleteInvite(inviteToDelete.code);
      setInvites(invites.filter(invite => invite.code !== inviteToDelete.code));
      setShowConfirmDelete(false);
    } catch (error) {
      console.error('Error deleting invite:', error);
    }
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).catch(err => {
      console.error('Error copying text: ', err);
    });
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return (date.getMonth() + 1).toString().padStart(2, '0') + '/' +
           date.getDate().toString().padStart(2, '0') + '/' +
           date.getFullYear();
  };

  return (
    <div>
      <h2 className="mb-4 text-2xl font-semibold">Create Group Invite</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="expiresAt" className="block mb-2 font-semibold">
            Expiration Date (optional):
          </label>
          <input
            type="date"
            id="expiresAt"
            value={expiresAt}
            onChange={(e) => setExpiresAt(e.target.value)}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="maxUses" className="block mb-2 font-semibold">
            Max Uses:
          </label>
          <input
            type="number"
            id="maxUses"
            min="1"
            value={maxUses}
            onChange={(e) => setMaxUses(parseInt(e.target.value))}
            disabled={isUnlimited}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="isUnlimited" className="flex items-center">
            <input
              type="checkbox"
              id="isUnlimited"
              checked={isUnlimited}
              onChange={(e) => setIsUnlimited(e.target.checked)}
              className="mr-2"
            />
            <span>Unlimited Uses</span>
          </label>
        </div>
        <button
          type="submit"
          className="px-4 py-2 font-semibold text-white bg-blue-500 rounded-lg"
        >
          Create Invite
        </button>
      </form>

      <h2 className="mt-8 mb-4 text-2xl font-semibold">Existing Invites</h2>
      <div className="grid grid-cols-1 gap-4">
        {invites.map((invite) => (
          <div key={invite.code} className="grid grid-cols-12 items-center p-4 border border-gray-300 rounded-lg bg-blue-100">
            <div className="col-span-3 flex items-center">
              <p className="font-semibold">Code: {invite.code}</p>
              <button onClick={() => copyToClipboard(invite.code)} className="ml-2 text-gray-600 hover:text-gray-800">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M7 2a1 1 0 00-1 1v1H5a2 2 0 00-2 2v9a2 2 0 002 2h9a2 2 0 002-2v-1h1a1 1 0 001-1V6a1 1 0 00-1-1h-1V4a2 2 0 00-2-2H7z" />
                  <path d="M9 5a1 1 0 00-1 1v9a1 1 0 001 1h9a1 1 0 001-1V6a1 1 0 00-1-1H9z" />
                </svg>
              </button>
            </div>
            <div className="col-span-5">
              <p># Uses Left: {invite.max_uses === -1 ? 'Unlimited' : invite.max_uses - invite.used_count}</p>
            </div>
            <div className="col-span-3">
              <p>Expiration: {invite.expires_at ? formatDate(invite.expires_at) : 'No set expiration date'}</p>
            </div>
            <div className="col-span-1">
              <button
                className="px-2 py-1 text-white bg-red-500 rounded-lg hover:bg-red-600"
                onClick={() => {
                  setInviteToDelete(invite);
                  setShowConfirmDelete(true);
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 011.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
              </button>
            </div>
          </div>
        ))}
      </div>

      {showConfirmDelete && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="w-1/2 p-6 bg-white rounded-lg">
            <h2 className="mb-4 text-xl font-bold">Confirm Deletion</h2>
            <p>Are you sure you want to delete the invite code: {inviteToDelete?.code}?</p>
            <div className="flex justify-end mt-4">
              <button
                className="px-4 py-2 mr-2 font-bold text-gray-500 bg-gray-200 rounded-md hover:bg-gray-300"
                onClick={() => setShowConfirmDelete(false)}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 font-bold text-white bg-red-500 rounded-md hover:bg-red-600"
                onClick={handleDeleteInvite}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InviteForm;
