import React from 'react';

interface ContactProps {
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  phone: string;
  setPhone: React.Dispatch<React.SetStateAction<string>>;
  showEmail: boolean;
  showPhone: boolean;
  handleCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Contact: React.FC<ContactProps> = ({
  name,
  setName,
  email,
  setEmail,
  phone,
  setPhone,
  showEmail,
  showPhone,
  handleCheckboxChange
}) => {
  return (
    <div className="mb-6">
      <h2 className="text-xl font-bold mb-4">Contact</h2>
      <div className="border border-gray-200 rounded-lg overflow-hidden">
        <div className="p-4 border-b border-gray-200">
          <div className="flex items-center justify-between mb-2">
            <label className="text-lg font-semibold">Email</label>
            <input
              type="checkbox"
              name="show_email"
              checked={showEmail}
              onChange={handleCheckboxChange}
              className="h-5 w-5 text-blue-600 rounded focus:ring-blue-500"
            />
          </div>
          {showEmail && (
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter email"
              className="w-full p-2 border border-gray-300 rounded-md"
            />
          )}
        </div>
        <div className="p-4">
          <div className="flex items-center justify-between mb-2">
            <label className="text-lg font-semibold">Phone Number</label>
            <input
              type="checkbox"
              name="show_phone"
              checked={showPhone}
              onChange={handleCheckboxChange}
              className="h-5 w-5 text-blue-600 rounded focus:ring-blue-500"
            />
          </div>
          {showPhone && (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Enter name"
                  className="w-full p-2 border border-gray-300 rounded-md"
                />
              </div>
              <div>
                <input
                  type="tel"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  placeholder="Enter phone number"
                  className="w-full p-2 border border-gray-300 rounded-md"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Contact;