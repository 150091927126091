import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { supabase } from '../../../supabase';

interface InformationBoxProps {
  title: string;
  subtext: string;
}

const InformationBox: React.FC<InformationBoxProps> = ({ title, subtext }) => (
  <div className="mb-8">
    <h2 className="mb-1 text-xl font-semibold">{title}</h2>
    <p className="text-base">{subtext}</p>
  </div>
);

interface YouTubeVideoProps {
  url: string;
}

const YouTubeVideo: React.FC<YouTubeVideoProps> = ({ url }) => {
  const getEmbeddableUrl = (url: string): string | null => {
    try {
      const urlObj = new URL(url);
      let videoId: string | null = null;

      if (urlObj.hostname === "youtube.com" || urlObj.hostname === "www.youtube.com") {
        videoId = urlObj.searchParams.get("v");
      } else if (urlObj.hostname === "youtu.be") {
        videoId = urlObj.pathname.slice(1);
      }

      if (!videoId) {
        console.error("Invalid YouTube URL: Unable to extract video ID");
        return null;
      }

      return `https://www.youtube-nocookie.com/embed/${videoId}?rel=0`;
    } catch (error) {
      // console.error("Invalid URL:", error);
      return null;
    }
  };

  const embeddableUrl = getEmbeddableUrl(url);

  if (!embeddableUrl) {
    return <div>Invalid YouTube URL</div>;
  }

  return (
    <div className="mb-8">
      <iframe
        className="rounded-xl"
        width="100%"
        height="315"
        src={embeddableUrl}
        title="YouTube Video"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
};

interface OrgDetails {
  organizations: {
    name: string;
    colleges: {
      descriptor: string;
    };
  };
  show_college_name: boolean;
  build_page: Array<{
    type: string;
    title?: string;
    subtext?: string;
    url?: string;
  }>;
  contact_info: {
    email: string;
    phone: string;
    name: string;
  };
  social_info: {
    instagram: string;
  };
}

const AboutComponent: React.FC = () => {
  const { groupId } = useParams<{ groupId: string }>();
  const [orgDetails, setOrgDetails] = useState<OrgDetails | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchDetails = async () => {
      setIsLoading(true);
      try {
        const { data: organizationData, error: organizationError } = await supabase
          .from('organization_pages')
          .select(`
            *,
            organizations (
              college_id,
              name,
              colleges (
                descriptor
              )
            )
          `)
          .eq('organization_id', groupId)
          .single();

        if (organizationError) {
          console.error('Error fetching organization details:', organizationError);
          setOrgDetails(null);
        } else {
          setOrgDetails(organizationData);
        }
      } catch (error) {
        console.error('Unexpected error:', error);
        setOrgDetails(null);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDetails();
  }, [groupId]);

  if (isLoading) {
    return <div className="flex items-center justify-center h-screen">Loading...</div>;
  }

  if (!orgDetails) {
    return (
      <div className="flex items-center justify-center h-screen">
        <h1 className="text-2xl font-bold text-gray-800">Page hasn't been created yet</h1>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center min-h-screen">
      <div className="container flex flex-col p-8 mx-auto md:flex-row">
        <div className="p-8 bg-white border rounded-lg shadow-lg md:w-4/5 border-agoraOutline">
          <div className="flex flex-row items-center justify-between mb-8">
            <h1 className="text-5xl font-bold text-gray-800">{orgDetails.organizations?.name || 'Organization Name'}</h1>
            {orgDetails.show_college_name && orgDetails.organizations?.colleges?.descriptor && (
              <p className="text-xl text-gray-600">@{orgDetails.organizations.colleges.descriptor}</p>
            )}
          </div>
          {orgDetails.build_page && orgDetails.build_page.length > 0 ? (
            orgDetails.build_page.map((item, index) => (
              <div key={index} className="mb-8">
                {item.type === 'Information Box' && item.title && item.subtext && (
                  <InformationBox title={item.title} subtext={item.subtext} />
                )}
                {item.type === 'YouTube Video' && item.url && (
                  <YouTubeVideo url={item.url} />
                )}
              </div>
            ))
          ) : (
            <p>No content available</p>
          )}
        </div>
        <div className="mt-8 md:w-1/3 md:pl-8 md:mt-0">
          <div className="p-8 mb-8 bg-white border rounded-lg shadow-lg border-agoraOutline">
            <h2 className="mb-4 text-2xl font-semibold">Contact</h2>
            {orgDetails.contact_info?.email && (
              <p className="mb-2 text-base">
                <strong>Email:</strong> {orgDetails.contact_info.email}
              </p>
            )}
            {orgDetails.contact_info?.phone && (
              <p className="mb-2 text-base">
                <strong>Phone:</strong> {orgDetails.contact_info.phone}
              </p>
            )}
            {orgDetails.contact_info?.name && (
              <p className="mb-2 text-base">
                <strong>Contact Name:</strong> {orgDetails.contact_info.name}
              </p>
            )}
          </div>
          <div className="p-8 mb-8 bg-white border rounded-lg shadow-lg border-agoraOutline">
            <h2 className="mb-4 text-2xl font-semibold">Socials</h2>
            {orgDetails.social_info?.instagram && (
              <p className="mb-2 text-base">
                <strong>Instagram:</strong> @{orgDetails.social_info.instagram}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutComponent;