import { supabase } from '../supabase';
import { postData } from './edgeApi';
import { addMonths, subDays, formatISO } from 'date-fns';
import { PostgrestError } from '@supabase/supabase-js';

export const fetchSubscriptions = async (organization_id: string) => {
  try {
    const { data, error } = await supabase
      .from('subscriptionplans')
      .select('*')
      .eq('organization_id', organization_id);

    if (error) {
      throw new Error('Failed to fetch subscriptions');
    }

    return data;
  } catch (error) {
    console.error('Error fetching subscriptions:', (error as PostgrestError).message);
    throw error;
  }
};

export const fetchOneTimePayments = async (organization_id: string) => {
  try {
    const { data, error } = await supabase
      .from('products')
      .select('*')
      .eq('organization_id', organization_id);

    if (error) {
      throw new Error('Failed to fetch one-time payments');
    }

    return data;
  } catch (error) {
    console.error('Error fetching one-time payments:', (error as PostgrestError).message);
    throw error;
  }
};

export const fetchSubscriptionPlans = async (organization_id: string) => {
  try {
    const { data, error } = await supabase
      .from('subscriptionplans')
      .select('*')
      .eq('organization_id', organization_id);

    if (error) {
      console.error('Error fetching subscription plans:', (error as PostgrestError).message);
      throw error;
    }

    return data;
  } catch (error) {
    console.error('Error fetching subscription plans:', (error as PostgrestError).message);
    return [];
  }
};

export const fetchSubscriptionAssignments = async (organization_id: string) => {
  try {
    const { data, error } = await supabase
      .from('subscriptionassignments')
      .select('*')
      .eq('organization_id', organization_id);

    if (error) {
      console.error('Error fetching subscription assignments:', (error as PostgrestError).message);
      throw error;
    }

    return data;
  } catch (error) {
    console.error('Error fetching subscription assignments:', (error as PostgrestError).message);
    throw error;
  }
};

export const fetchOrganizationItems = async (organization_id: string) => {
  try {
    const { data, error } = await supabase
      .from('organizationitems')
      .select(`
        *,
        product:products (
          name,
          description
        )
      `)
      .eq('organization_id', organization_id);
    return data;
  } catch (error) {
    console.error('Error fetching organization items:', (error as PostgrestError).message);
    throw error;
  }
};

export const fetchOrganizationProducts = async (organization_id: string) => {
  try {
    const { data, error } = await supabase
      .from('products')
      .select(`
        *
      `)
      .eq('organization_id', organization_id);
    return data;
  } catch (error) {
    console.error('Error fetching organization products:', (error as PostgrestError).message);
    throw error;
  }
};

export const createSubscription = async (
  organization_id: string,
  subscriptionData: any,
  selectedCustomers: any[]
) => {
  try {
    console.log('Creating subscription plan with data:', subscriptionData);
    const response  = await postData('stripe-product-create', {
      productName: subscriptionData.name,
      amount: subscriptionData.amount,
      currency: subscriptionData.currency,
      billing_cycle: subscriptionData.billing_cycle,
      due_date: subscriptionData.due_date,
      organization_id: organization_id,
      product_type: 'subscription',
      selectedCustomers: selectedCustomers,
      billing_days_before_due: subscriptionData.billing_days_before_due,
      cycles_remaining: subscriptionData.cycles_remaining,
      billing_account_id: subscriptionData.billing_account_id,
    });

    if (!response.success) {
      throw new Error('Failed to create subscription');
    }

    return response.success ? 200 : 500;
  } catch (error) {
    console.error('Error creating subscription:', error);
    throw error;
  }
};

export const createOneTimePayment = async (
  organization_id: string,
  paymentData: any,
  selectedCustomers: any[],
  selectedBillingAccount: string
) => {
  try {
    console.log('Creating one-time payment with data:', paymentData);

    const stripeProductResponse = await postData('stripe-onetime-product-create', {
      organization_id: organization_id,
      paymentData: {
        name: paymentData.name,
        amount: paymentData.amount,
        currency: paymentData.currency,
        quantity: paymentData.quantity,
        dueDate: paymentData.dueDate,
      },
      selectedBillingAccount: selectedBillingAccount,
      selectedCustomers: selectedCustomers,
    });

    console.log('Stripe product response:', stripeProductResponse);

    if (!stripeProductResponse.success) {
      throw new Error('Failed to create one-time payment');
    }

    return 200;
  } catch (error) {
    console.error('Error creating one-time payment:', error);
    throw error;
  }
};

function addUTCMonths(date: Date, months: number) {
  const d = new Date(date);
  d.setUTCMonth(d.getUTCMonth() + months);

  if (d.getUTCDate() < date.getUTCDate()) {
    d.setUTCDate(0);
  }

  return d;
}

export const addUsersToSubscription = async (
  subscriptionId: string,
  userIds: string[],
  dueDate: Date,
  billingDaysBeforeDue: number,
  cyclesRemaining: number,
  groupId: string,
  selectedBillingAccount: string
) => {
  try {
    console.log(subscriptionId, userIds, dueDate, billingDaysBeforeDue, cyclesRemaining);
    
    const { data: planData, error: planError } = await supabase
      .from('subscriptionplans')
      .select('product_id, amount, currency')
      .eq('plan_id', subscriptionId)
      .single();
      
    if (planError) {
      console.error('Error fetching plan data from subscriptionplans:', (planError as PostgrestError).message);
      throw planError;
    }

    const { product_id, amount, currency } = planData;

    const organizationItemsData = userIds.map((userId: string) => ({
      organization_id: groupId,
      user_id: userId,
      product_id: product_id,
      quantity: 1,
      amount: amount,
      currency: currency,
      status: 'active',
      billing_account_id: selectedBillingAccount,
      due_date: new Date(Date.UTC(
        new Date(dueDate).getUTCFullYear(),
        new Date(dueDate).getUTCMonth(),
        new Date(dueDate).getUTCDate()
      )).toISOString()
    }));

    const { error: itemsError } = await supabase
      .from('organizationitems')
      .insert(organizationItemsData);

    if (itemsError) {
      console.error('Error adding items to organization:', (itemsError as PostgrestError).message);
      throw itemsError;
    }

    const dueDateUTC = new Date(Date.UTC(
      new Date(dueDate).getUTCFullYear(),
      new Date(dueDate).getUTCMonth(),
      new Date(dueDate).getUTCDate()
    ));

    const dueDateWithOneMonth = addUTCMonths(dueDateUTC, 1);

    const billingDay = new Date(dueDateWithOneMonth);
    billingDay.setUTCDate(billingDay.getUTCDate() - billingDaysBeforeDue);
    
    const { data, error } = await supabase
      .from('subscriptionassignments')
      .insert(
        userIds.map((userId: string) => ({
          user_id: userId,
          plan_id: subscriptionId,
          status: 'active',
          billing_day: billingDay.toISOString().split('T')[0],
          due_date: dueDateWithOneMonth.toISOString().split('T')[0],
          cycles_remaining: cyclesRemaining - 1,
          billing_account_id: selectedBillingAccount,
        }))
      );

    if (error) {
      console.error('Error adding users to subscription:', (error as PostgrestError).message);
      throw error;
    }

    return data;
  } catch (error) {
    console.error('Error adding users to subscription:', (error as PostgrestError).message);
    throw error;
  }
};
