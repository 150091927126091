import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { supabase } from '../../supabase';
import QRCode from 'react-qr-code';
import { RealtimeChannel } from '@supabase/supabase-js';

interface Vote {
  id: string;
  user_id: string;
  answer: 'yes' | 'no' | 'abstain';
  timestamp: string;
  question_index: number;
  user_name: string;
}

interface Poll {
  id: string;
  current_question_index: number;
  ended_at: string | null;
}

const CreatePoll: React.FC = () => {
  const { groupId } = useParams<{ groupId: string }>();
  const [poll, setPoll] = useState<Poll | null>(null);
  const [votes, setVotes] = useState<Vote[]>([]);
  const [isActive, setIsActive] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchVotes = useCallback(async (pollId: string, questionIndex: number) => {
    console.log('Fetching votes for poll:', pollId, 'question:', questionIndex);
    try {
      const { data, error } = await supabase
        .from('votes')
        .select(`
          *,
          users (
            first_name,
            last_name
          )
        `)
        .eq('poll_id', pollId)
        .eq('question_index', questionIndex);

      if (error) throw error;

      console.log('Fetched votes:', data);
      const votesWithNames = data.map(vote => ({
        ...vote,
        user_name: vote.users ? `${vote.users.first_name} ${vote.users.last_name}` : 'Unknown User',
      }));
      setVotes(votesWithNames);
    } catch (err) {
      console.error('Error fetching votes:', err);
      setError(err instanceof Error ? err.message : 'An error occurred while fetching votes');
    }
  }, []);

  const handleManualFetch = useCallback(async () => {
    if (poll) {
      try {
        const { data: pollData, error: pollError } = await supabase
          .from('polls')
          .select('current_question_index')
          .eq('id', poll.id)
          .single();

        if (pollError) throw pollError;

        if (pollData) {
          console.log('Latest poll data:', pollData);
          const latestQuestionIndex = pollData.current_question_index;
          
          setPoll(prevPoll => ({ ...prevPoll!, current_question_index: latestQuestionIndex }));

          await fetchVotes(poll.id, latestQuestionIndex);
        }
      } catch (err) {
        console.error('Error in manual fetch:', err);
        setError(err instanceof Error ? err.message : 'An error occurred while fetching the latest data');
      }
    }
  }, [poll, fetchVotes]);

  const handleVote = useCallback((payload: { new: Vote; old: Vote | null; eventType: 'INSERT' | 'UPDATE' | 'DELETE' }) => {
    console.log('Vote change received:', payload);
    setVotes(currentVotes => {
      if (payload.eventType === 'DELETE') {
        return currentVotes.filter(v => v.id !== payload.old?.id);
      } else if (payload.eventType === 'INSERT') {
        return [...currentVotes, payload.new];
      } else {
        return currentVotes.map(v => v.id === payload.new.id ? payload.new : v);
      }
    });
  }, []);

  const handlePollUpdate = useCallback((payload: { new: Poll; old: Poll }) => {
    console.log('Poll updated:', payload.new);
    setPoll(payload.new);
    if (payload.new.current_question_index !== payload.old.current_question_index) {
      fetchVotes(payload.new.id, payload.new.current_question_index);
    }
    if (payload.new.ended_at && !payload.old.ended_at) {
      setIsActive(false);
    }
  }, [fetchVotes]);

  useEffect(() => {
    let channel: RealtimeChannel;

    const setupSubscription = async () => {
      if (poll) {
        console.log('Setting up subscription for poll:', poll.id);
        channel = supabase.channel(`poll-${poll.id}`);

        channel
          .on(
            'postgres_changes',
            { event: '*', schema: 'public', table: 'votes', filter: `poll_id=eq.${poll.id}` },
            (payload) => handleVote(payload as any)
          )
          .on(
            'postgres_changes',
            { event: 'UPDATE', schema: 'public', table: 'polls', filter: `id=eq.${poll.id}` },
            (payload) => handlePollUpdate(payload as any)
          )
          .subscribe((status) => {
            console.log('Subscription status:', status);
          });

        await fetchVotes(poll.id, poll.current_question_index);
      }
    };

    setupSubscription();

    return () => {
      if (channel) {
        console.log('Unsubscribing from poll:', poll?.id);
        channel.unsubscribe();
      }
    };
  }, [poll, handleVote, handlePollUpdate, fetchVotes]);

  const startPoll = async () => {
    try {
      const { data, error } = await supabase
        .from('polls')
        .insert({
          group_id: groupId,
          created_at: new Date().toISOString(),
          current_question_index: 0,
        })
        .select();

      if (error) throw error;

      if (data) {
        console.log('Poll created:', data[0]);
        setPoll(data[0]);
        setIsActive(true);
      }
    } catch (err) {
      console.error('Error creating poll:', err);
      setError(err instanceof Error ? err.message : 'An error occurred while creating the poll');
    }
  };

  const endPoll = async () => {
    if (poll) {
      try {
        const { error } = await supabase
          .from('polls')
          .update({ ended_at: new Date().toISOString() })
          .eq('id', poll.id);

        if (error) throw error;

        console.log('Poll ended:', poll.id);
        setIsActive(false);
      } catch (err) {
        console.error('Error ending poll:', err);
        setError(err instanceof Error ? err.message : 'An error occurred while ending the poll');
      }
    }
  };

  const nextQuestion = async () => {
    if (poll) {
      try {
        const { error } = await supabase
          .from('polls')
          .update({ current_question_index: poll.current_question_index + 1 })
          .eq('id', poll.id);

        if (error) throw error;

        console.log('Moving to next question');
      } catch (err) {
        console.error('Error moving to next question:', err);
        setError(err instanceof Error ? err.message : 'An error occurred while moving to the next question');
      }
    }
  };

  const voteResults = useMemo(() => {
    const results = {
      yes: { count: 0, users: [] as string[] },
      no: { count: 0, users: [] as string[] },
      abstain: { count: 0, users: [] as string[] },
    };

    votes.forEach(vote => {
      results[vote.answer].count += 1;
      results[vote.answer].users.push(vote.user_name);
    });

    return results;
  }, [votes]);

  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <h2 className="mb-6 text-2xl font-semibold">Real-time Poll</h2>
      {error && (
        <div className="p-4 mb-4 text-red-700 bg-red-100 border-l-4 border-red-500" role="alert">
          <p className="font-bold">Error</p>
          <p>{error}</p>
        </div>
      )}
      {!isActive ? (
        <button
          onClick={startPoll}
          className="px-4 py-2 text-white transition-colors bg-blue-600 rounded-md hover:bg-blue-700"
        >
          Start Poll
        </button>
      ) : (
        <div className="space-y-6">
          <div className="flex space-x-4">
            <button
              onClick={endPoll}
              className="px-4 py-2 text-white transition-colors bg-red-600 rounded-md hover:bg-red-700"
            >
              End Poll
            </button>
            <button
              onClick={nextQuestion}
              className="px-4 py-2 text-white transition-colors bg-green-600 rounded-md hover:bg-green-700"
            >
              Next Question
            </button>
            <button
              onClick={handleManualFetch}
              className="px-4 py-2 text-white transition-colors bg-yellow-600 rounded-md hover:bg-yellow-700"
            >
              Fetch Latest Votes
            </button>
          </div>
          {poll && (
            <div className="mt-6">
              <h3 className="mb-2 text-lg font-semibold">Poll QR Code</h3>
              <QRCode value={`${window.location.origin}/polls/${poll.id}`} />
            </div>
          )}
          <div>
            <h3 className="mb-2 text-lg font-semibold">Live Results (Question {poll?.current_question_index})</h3>
            <div className="overflow-x-auto">
              <table className="min-w-full bg-white">
                <thead className="bg-gray-100">
                  <tr>
                    <th className="px-4 py-2 text-left">Yes ({voteResults.yes.count})</th>
                    <th className="px-4 py-2 text-left">No ({voteResults.no.count})</th>
                    <th className="px-4 py-2 text-left">Abstain ({voteResults.abstain.count})</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="px-4 py-2 align-top">
                      {voteResults.yes.users.map(user => (
                        <div key={`yes-${user}`}>{user}</div>
                      ))}
                    </td>
                    <td className="px-4 py-2 align-top">
                      {voteResults.no.users.map(user => (
                        <div key={`no-${user}`}>{user}</div>
                      ))}
                    </td>
                    <td className="px-4 py-2 align-top">
                      {voteResults.abstain.users.map(user => (
                        <div key={`abstain-${user}`}>{user}</div>
                      ))}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreatePoll;