import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import RolesList from './Roles/RolesList';
import RoleForm from './Roles/RoleForm';
import MembersManagement from './Roles/MembersManagement';
import TabNavigation from './Roles/TabNavigation';
import { showToast } from '../../Toast';

import { addRole, fetchRoles, fetchRolePermissions, updateRolePermissions } from '../../Contexts/supabaseRoles';

interface Role {
  role_id: string;
  role_name: string;
}

interface SelectedRole {
  id: string;
  name: string;
}

const RolesComponent: React.FC = () => {
  const [roleName, setRoleName] = useState<string>('');
  const [permissions, setPermissions] = useState<string[]>([]);
  const [roles, setRoles] = useState<Role[]>([]);
  const [selectedRole, setSelectedRole] = useState<SelectedRole | null>(null);
  const [activeTab, setActiveTab] = useState<string>('permissions');
  const { groupId } = useParams<{ groupId: string }>();

  useEffect(() => {
    const loadRoles = async () => {
      if (!groupId) return;  
      try {
        const fetchedRoles = await fetchRoles(groupId);
        setRoles(fetchedRoles);
      } catch (error) {
        console.error('Error fetching roles:', error);
      }
    };

    loadRoles();
  }, [groupId]);

  const handleAddOrUpdateRole = async () => {
    if (selectedRole) {
      try {
        await updateRolePermissions(selectedRole.id, permissions);
        showToast('Role Updated Successfully!');
      } catch (error) {
        console.error('Error updating role:', error);
        showToast('Failed to update role', true);
      }
    } else {
      try {
        if (!groupId) return; 
        const role = await addRole(groupId, roleName, permissions);
        showToast('Role Added Successfully!');
        setRoles([...roles, { role_id: role.role_id, role_name: roleName }]);
        setRoleName('');
        setPermissions([]);
      } catch (error) {
        console.error('Error adding role:', error);
        showToast('Failed to add role', true);
      }
    }
  };

  const handleRoleClick = async (role: { name: string }) => {
    const fullRole = roles.find(r => r.role_name === role.name);
    if (fullRole) {
      const selectedRole: SelectedRole = { id: fullRole.role_id, name: fullRole.role_name };
      setSelectedRole(selectedRole);
      setRoleName(selectedRole.name);
      try {
        const rolePermissions = await fetchRolePermissions(selectedRole.id);
        if (Array.isArray(rolePermissions)) {
          setPermissions(rolePermissions);
        } else {
          console.error('Unexpected permissions format:', rolePermissions);
          setPermissions([]);
        }
      } catch (error) {
        console.error('Error fetching role permissions:', error);
        setPermissions([]);
      }
      setActiveTab('permissions');
    }
  };

  const handleNewRole = () => {
    setSelectedRole(null);
    setRoleName('');
    setPermissions([]);
    setActiveTab('permissions');
  };

  return (
    <div className="flex">
      <RolesList 
        roles={roles.map(role => ({ name: role.role_name }))}
        handleRoleClick={handleRoleClick} 
        handleNewRole={handleNewRole} 
      />
      <div className="w-3/4 p-4">
        <h2 className="pb-2 mb-4 text-2xl font-semibold border-b border-gray-300">
          Manage Role - {selectedRole ? selectedRole.name : 'New Role'}
        </h2>
        <TabNavigation activeTab={activeTab} setActiveTab={setActiveTab} selectedRole={selectedRole ? selectedRole.name : null} />
        {activeTab === 'permissions' && (
          <RoleForm 
            roleName={roleName} 
            setRoleName={setRoleName} 
            permissions={permissions} 
            setPermissions={setPermissions} 
            handleAddOrUpdateRole={handleAddOrUpdateRole} 
          />
        )}
        {activeTab === 'members' && selectedRole && (
          <MembersManagement 
            groupId={groupId!} 
            selectedRole={selectedRole} 
          />
        )}
      </div>
    </div>
  );
};

export default RolesComponent;