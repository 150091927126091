import React, { useEffect, useState } from 'react';
import { supabase } from '../../../../supabase';
import Contact from './Contact';
import Social from './Social';
import BuildPage from './BuildPage';
import { OrgDetails, Campaign } from '../../../../types/types';
import { showToast } from '../../../../Toast';


interface GeneralSettingsProps {
  orgDetails: OrgDetails;
  setOrgDetails: React.Dispatch<React.SetStateAction<OrgDetails>>;
}

const GeneralSettings: React.FC<GeneralSettingsProps> = ({ orgDetails = {}, setOrgDetails }) => {
  const contact_info = orgDetails.contact_info || {};
  const social_info = orgDetails.social_info || {};
  const build_page = Array.isArray(orgDetails.build_page) ? orgDetails.build_page : [];

  const [name, setName] = useState(contact_info.name || '');
  const [email, setEmail] = useState(contact_info.email || '');
  const [phone, setPhone] = useState(contact_info.phone || '');
  const [instagram, setInstagram] = useState(social_info.instagram || '');

  const [showEmail, setShowEmail] = useState(!!contact_info.email);
  const [showPhone, setShowPhone] = useState(!!contact_info.phone);
  const [showInstagram, setShowInstagram] = useState(!!social_info.instagram);
  const [showCollegeName, setShowCollegeName] = useState(orgDetails.show_college_name || false);

  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [selectedCampaignId, setSelectedCampaignId] = useState<string | null>(null);

  useEffect(() => {
    fetchCampaigns();
  }, [orgDetails]);

  const fetchCampaigns = async () => {
    const { data, error } = await supabase
      .from('campaigns')
      .select('id, name')
      .eq('organization_id', orgDetails.organization_id);

    if (error) {
      console.error('Error fetching campaigns:', error);
    } else {
      setCampaigns(data);
    }
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    if (name === 'show_email') setShowEmail(checked);
    if (name === 'show_phone') setShowPhone(checked);
    if (name === 'show_instagram') setShowInstagram(checked);
    if (name === 'show_college_name') setShowCollegeName(checked);
  };

  const handleBuildPageChange = (index: number, type: string, value: string) => {
    const updatedBuildPage = [...build_page];
    updatedBuildPage[index] = { ...updatedBuildPage[index], [type]: value };
    setOrgDetails({ ...orgDetails, build_page: updatedBuildPage });
  };

  const addBuildPageField = (type: string) => {
    const newBuildPage = [...build_page, { type, title: '', subtext: '', url: '' }];
    setOrgDetails({
      ...orgDetails,
      build_page: newBuildPage
    });
  };

  const removeBuildPageField = (index: number) => {
    const newBuildPage = build_page.filter((_, i) => i !== index);
    setOrgDetails({ ...orgDetails, build_page: newBuildPage });
  };

  const handleInterestBoxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    setOrgDetails({
      ...orgDetails,
      recruitment_info: isChecked ? (selectedCampaignId || '') : null
    });
    if (!isChecked) {
      setSelectedCampaignId(null);
    }
  };

  const handleCampaignSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const campaignId = e.target.value;
    setSelectedCampaignId(campaignId);
    setOrgDetails({
      ...orgDetails,
      recruitment_info: campaignId
    });
  };

  const savePage = async () => {
    const { id, organization_id, ...updatedDetails } = orgDetails;
    updatedDetails.contact_info = {
      name,
      email: showEmail ? email : '',
      phone: showPhone ? phone : ''
    };
    updatedDetails.social_info = {
      instagram: showInstagram ? instagram : ''
    };
    updatedDetails.show_college_name = showCollegeName;
    updatedDetails.recruitment_info = selectedCampaignId;

    const { data, error } = await supabase
      .from('organization_pages')
      .update(updatedDetails)
      .eq('organization_id', organization_id);

    if (error) {
      console.error('Error updating organization page:', error);
    } else {
      showToast('Page Updated!');
    }
  };

  return (
    <div className="p-4">
      <div className="mb-6">
        <h2 className="mb-4 text-2xl font-bold">Branding</h2>
        <div className="border border-[#DADEE3] rounded-lg overflow-hidden">
          <div className="flex items-center justify-between p-4 border-b border-[#DADEE3]">
            <label className="text-lg font-semibold">Make About Page Public?</label>
            <input
              type="checkbox"
              name="is_public"
              checked={orgDetails.is_public || false}
              onChange={(e) => setOrgDetails({ ...orgDetails, is_public: e.target.checked })}
              className="mr-2 h-5 w-5 rounded text-[#4338CA] focus:ring-[#4338CA] focus:ring-offset-0"
            />
          </div>
          {orgDetails.is_public && (
            <div className="flex items-center justify-between p-4">
              <span className="text-lg font-semibold">Public Page Link</span>
              <button className="px-4 py-2 ml-4 text-white bg-[#4338CA] rounded-md hover:bg-opacity-90 transition-colors duration-200">
                Copy Link
              </button>
            </div>
          )}
        </div>
      </div>

      <Contact
        name={name}
        setName={setName}
        email={email}
        setEmail={setEmail}
        phone={phone}
        setPhone={setPhone}
        showEmail={showEmail}
        showPhone={showPhone}
        handleCheckboxChange={handleCheckboxChange}
      />
      <Social
        instagram={instagram}
        setInstagram={setInstagram}
        showInstagram={showInstagram}
        handleCheckboxChange={handleCheckboxChange}
      />

      <div className="mb-6">
        <h2 className="mb-4 text-xl font-bold">Recruitment</h2>
        <div className="border border-gray-200 rounded-lg overflow-hidden">
          <div className="p-4">
            <div className="flex items-center justify-between mb-2">
              <label className="text-lg font-semibold">Add Interest Box</label>
              <input
                type="checkbox"
                name="recruitment_info"
                checked={orgDetails.recruitment_info !== null}
                onChange={handleInterestBoxChange}
                className="h-5 w-5 text-blue-600 rounded focus:ring-blue-500"
              />
            </div>
            {orgDetails.recruitment_info !== null && (
              <div className="relative">
                <select
                  name="recruitment_info"
                  value={selectedCampaignId || ''}
                  onChange={handleCampaignSelect}
                  className="w-full p-2 pr-8 border border-gray-300 rounded-md appearance-none"
                >
                  <option value="">Select campaign</option>
                  {campaigns.map((campaign) => (
                    <option key={campaign.id} value={campaign.id}>
                      {campaign.name}
                    </option>
                  ))}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <BuildPage
        build_page={build_page}
        handleBuildPageChange={handleBuildPageChange}
        addBuildPageField={addBuildPageField}
        removeBuildPageField={removeBuildPageField}
        showCollegeName={showCollegeName}
        setShowCollegeName={setShowCollegeName}
      />

      <div className="flex justify-center">
        <button className="mt-4 bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 transition-colors duration-200" onClick={savePage}>
          Save Page
        </button>
      </div>
    </div>
  );
};

export default GeneralSettings;
