import React, { useState } from 'react';
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';
import {
  Elements,
  PaymentElement,
  useStripe,
  useElements,
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import { postData } from '../../Contexts/edgeApi';
import CheckoutForm from './CheckoutForm';
import PaymentItem from './PaymentItem';
import OrderSummary from './OrderSummary';

const stripePromise = loadStripe('pk_test_51OjRt8JaLwIxLZCHQBxK2uXUqxbytHFLN7ArUkt8s8I0BHyqkIU9opYdkz5fkO27XVHwT7Ik6vu6sV5pRUSquivB00WEuVYpPe');

const PaymentForm: React.FC = () => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: 'https://localhost:3000/success',
      },
    });

    if (error) {
      console.error('Error confirming payment:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <button
        type="submit"
        className="px-4 py-2 mt-4 font-semibold text-white bg-indigo-600 rounded-lg hover:bg-indigo-700"
        disabled={!stripe}
      >
        Pay Now
      </button>
    </form>
  );
};

const PayTest: React.FC = () => {
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [options, setOptions] = useState<StripeElementsOptions | null>(null);
  const [yesEmbed, setYesEmbed] = useState<boolean | null>(null);
  const [isCustomCheckoutActive, setIsCustomCheckoutActive] = useState(false);

  const paymentItems = [
    { productName: 'Rent', amount: 100000, dueDate: '2023-10-10' },
    { productName: 'Philanthropy Shirt', amount: 1899, dueDate: '2023-10-10' },
    { productName: 'Philanthropy Shirt', amount: 1899, dueDate: '2023-10-10' },
  ];

  const amounts = paymentItems.map(item => item.amount);

  const handleHostedCheckout = async () => {
    try {
      const response = await postData('create-checkout-session-hosted', {
        lineItems: [
          {
            price: 'price_1PEk8rR7ToLv7AOOYQOdQx9p',
            quantity: 1,
          },
        ],
        returnUrl: 'https://localhost:3000/success',
        connectedAccountId: 'acct_1P1I9wR7ToLv7AOO',
      });
      const { url } = response;
      window.location.href = url;
    } catch (error) {
      console.error('Error creating hosted checkout session:', error);
    }
  };

  const handleEmbeddedCheckout = async () => {
    try {
      const response = await postData('create-checkout-session', {
        lineItems: [
          {
            price: 'price_1PEk8rR7ToLv7AOOYQOdQx9p',
            quantity: 1,
          },
        ],
        returnUrl: 'https://localhost:3000/success',
        connectedAccountId: 'acct_1P1I9wR7ToLv7AOO',
      });
      const { clientSecret } = response;
      setOptions({
        clientSecret,
        appearance: {
          theme: 'stripe',
        },
      });
      setClientSecret(clientSecret);
      setYesEmbed(true);
    } catch (error) {
      console.error('Error creating embedded checkout session:', error);
    }
  };

  const handleCustomCheckout = async () => {
    try {
      const response = await postData('create-checkout-session-custom', {
        organizationId: 'c7f28f1b-9af4-4e5e-b43c-2097edb01172',
        stripeAccountId: 'acct_1P1I9wR7ToLv7AOO',
        productId: 'prod_Q4twXZFlFzsIax',
        priceId: 'price_1PEk8rR7ToLv7AOOYQOdQx9p',
        items: [
          {
            price: 1000, 
            quantity: 1,
          },
        ],
      });

      const { clientSecret } = response;

      if (!clientSecret) {
        throw new Error('Invalid clientSecret');
      }

      setClientSecret(clientSecret);
      setYesEmbed(false);
      setIsCustomCheckoutActive(true);
      setOptions({
        clientSecret,
        appearance: {
          theme: 'stripe',
        },
      });
    } catch (error) {
      console.error('Error creating custom checkout session:', error);
    }
  };

  return (
    <div className="container mx-auto">
      <h1 className="mb-4 text-2xl font-bold">Payment Test</h1>

      <div className="mb-4">
        <button
          onClick={handleHostedCheckout}
          className="px-4 py-2 font-semibold text-white bg-indigo-600 rounded-lg hover:bg-indigo-700"
        >
          Hosted Checkout
        </button>
      </div>

      <div className="mb-4">
        <button
          onClick={handleEmbeddedCheckout}
          className="px-4 py-2 font-semibold text-white bg-indigo-600 rounded-lg hover:bg-indigo-700"
        >
          Embedded Checkout
        </button>
      </div>

      <div className="flex flex-col justify-between p-4 space-x-0 md:flex-row md:space-x-4">
        <div className="w-full space-y-4">
          {paymentItems.map((item, index) => (
            <PaymentItem key={index} productName={item.productName} amount={item.amount} dueDate={item.dueDate} />
          ))}
        </div>
        <div className="flex justify-center w-full mt-4 md:mt-0 md:w-auto">
          <OrderSummary
            amounts={amounts}
            handleCustomCheckout={handleCustomCheckout}
            isCustomCheckoutActive={isCustomCheckoutActive}
            className="w-full md:w-auto"
          />
        </div>
      </div>

      {clientSecret && (
        yesEmbed ? (
          <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={options!}
          >
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        ) : (
          <Elements options={options!} stripe={stripePromise}>
            <CheckoutForm />
          </Elements>
        )
      )}
    </div>
  );
};

export default PayTest;
