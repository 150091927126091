import { supabase } from '../supabase';

export const addRole = async (
  group_id: string,
  roleName: string,
  permissions: string[]
) => {
  try {
    const { data, error } = await supabase
      .rpc('add_role_with_permissions', {
        group_id,
        role_name: roleName,
        permission_names: permissions,
      });

    if (error) throw error;

    return data;
  } catch (error) {
    console.error('Error adding role via RPC:', error);
    throw error;
  }
};

export const fetchRoles = async (organization_id: string) => {
  try {
    const { data: roles, error } = await supabase
      .from('roles')
      .select('role_id, role_name')
      .eq('group_id', organization_id);

    if (error) throw error;

    return roles;
  } catch (error) {
    console.error('Error fetching roles:', error);
    throw error;
  }
};

export const updateRolePermissions = async (
  role_id: string,
  permissions: string[]
) => {
  try {
    const { data, error } = await supabase
      .rpc('update_role_permissions', {
        role_id,
        permission_names: permissions,
      });

    if (error) throw error;

    return data;
  } catch (error) {
    console.error('Error updating role permissions:', error);
    throw error;
  }
};

export const addMemberToRole = async (
  user_id: string,
  organization_id: string,
  role_id: string
) => {
  try {
    const { data, error } = await supabase
      .from('user_organization_roles')
      .insert([{ user_id, group_id: organization_id, role_id }]);

    if (error) throw error;

    return data;
  } catch (error) {
    console.error('Error adding member to role:', error);
    throw error;
  }
};

export const removeMemberFromRole = async (
  user_id: string,
  organization_id: string,
  role_id: string
) => {
  try {
    const { data, error } = await supabase
      .from('user_organization_roles')
      .delete()
      .eq('user_id', user_id)
      .eq('group_id', organization_id)
      .eq('role_id', role_id);

    if (error) throw error;

    return data;
  } catch (error) {
    console.error('Error removing member from role:', error);
    throw error;
  }
};

export const fetchGroupMembers = async (organization_id: string) => {
  try {
    const { data: members, error } = await supabase
      .from('memberships')
      .select('user_id, users(first_name, last_name)')
      .eq('organization_id', organization_id)
      .eq('is_active', true);

    if (error) throw error;

    return members;
  } catch (error) {
    console.error('Error fetching group members:', error);
    throw error;
  }
};

export const fetchRoleMembers = async (
  organization_id: string,
  role_id: string
) => {
  try {
    const { data: members, error } = await supabase
      .from('user_organization_roles')
      .select('user_id, users(first_name, last_name)')
      .eq('group_id', organization_id)
      .eq('role_id', role_id);

    if (error) throw error;

    return members;
  } catch (error) {
    console.error('Error fetching role members:', error);
    throw error;
  }
};

export const fetchRolePermissions = async (roleId: string) => {
  try {
    console.log('Fetching role permissions for roleId:', roleId);
    const { data, error } = await supabase
      .from('role_permissions')
      .select(`
        permissions:permission_id ( permission_name )
      `)
      .eq('role_id', roleId);

    if (error) {
      throw error;
    }

    console.log('Received data:', data);

    if (!Array.isArray(data)) {
      console.error('Unexpected data format. Expected an array.');
      return [];
    }

    const permissions = data.reduce((acc: string[], rp: any) => {
      if (rp.permissions && typeof rp.permissions === 'object') {
        if (Array.isArray(rp.permissions)) {
          return [...acc, ...rp.permissions.map((p: any) => p.permission_name)];
        } else {
          return [...acc, rp.permissions.permission_name];
        }
      }
      return acc;
    }, []);

    console.log('Processed permissions:', permissions);
    return permissions;
  } catch (error) {
    console.error('Error fetching role permissions:', error);
    throw error;
  }
};

