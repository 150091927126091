import React, { useState, useEffect } from 'react';
import { IoMdClose } from 'react-icons/io';
import { format, parseISO } from 'date-fns';
import { getTimezoneOffset } from 'date-fns-tz';
import { supabase } from '../../../supabase';

type TransactionDetailsPopupProps = {
  transactionId: number;
  onClose: () => void;
};

const TransactionDetailsPopup: React.FC<TransactionDetailsPopupProps> = ({ transactionId, onClose }) => {
  const [showRefundForm, setShowRefundForm] = useState(false);
  const [transactionDetails, setTransactionDetails] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTransactionDetails = async () => {
      try {
        const { data, error } = await supabase
          .from('transfers')
          .select(`
            id,
            gross_amount,
            net_amount,
            fee_amount,
            created_at,
            user:user_id (
              first_name,
              last_name
            ),
            product_name
          `)
          .eq('id', transactionId)
          .single();

          if (error) throw error;

        if (data) {
          const jsonStringified = JSON.stringify(data);
          setTransactionDetails(jsonStringified);
        } else {
          throw new Error('Data is missing or in incorrect format');
        }
      } catch (error) {
        console.error('Error fetching transaction details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTransactionDetails();
  }, [transactionId]);

  const handleRefundClick = () => {
    setShowRefundForm(true);
  };

  const handleBackgroundClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const parsedTransactionDetails = transactionDetails ? JSON.parse(transactionDetails) : null;

  const formatLocalDateTime = (utcDateString: string) => {
    const date = parseISO(utcDateString);
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const offset = getTimezoneOffset(timeZone, date);
    const localDate = new Date(date.getTime() + offset);    
    return format(localDate, 'MMM d, h:mm a');
  };

  if (loading) {
    return (
      <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center">
        <div className="bg-white rounded-lg shadow-lg w-full max-w-md p-4">
          <div className="animate-pulse space-y-4">
            <div className="h-4 bg-gray-200 rounded w-3/4"></div>
            <div className="h-4 bg-gray-200 rounded"></div>
            <div className="h-4 bg-gray-200 rounded"></div>
            <div className="h-4 bg-gray-200 rounded w-5/6"></div>
          </div>
        </div>
      </div>
    );
  }

  if (!transactionDetails) {
    return null;
  }

  return (
    <div
      className="fixed inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center"
      onClick={handleBackgroundClick}
    >
      <div className="bg-white rounded-lg shadow-lg w-full max-w-md">
        <div className="flex justify-between items-center border-b border-gray-200 p-4">
          <h2 className="text-xl font-semibold">
            {showRefundForm ? 'Send Refund' : 'Transaction Details'}
          </h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 focus:outline-none"
          >
            <IoMdClose size={24} />
          </button>
        </div>
        <div className="p-4">
          {!showRefundForm ? (
            <>
              <p className="text-sm text-black font-semibold">Product</p>
              <div className="mb-4 p-3 border border-agoraOutline rounded-md">
                <p className="text-black">{parsedTransactionDetails.product_name}</p>
              </div>
              <div className="mb-4">
                <p className="text-sm text-gray-600">Status</p>
                <p className="font-semibold text-green-600">Succeeded</p>
              </div>
              <div className="mb-4">
                <p className="text-sm text-gray-600">Date received</p>
                <p className="font-semibold">
                    {formatLocalDateTime(parsedTransactionDetails.created_at)}
                </p>
              </div>
              <div className="mb-4">
                <p className="text-sm text-gray-600">Amount</p>
                <p className="font-semibold">${(parsedTransactionDetails.gross_amount / 100).toFixed(2)} USD</p>
              </div>
              <div className="mb-4 relative">
                <p className="text-sm text-gray-600">Processing fee</p>
                <p className="font-semibold">${(parsedTransactionDetails.fee_amount / 100).toFixed(2)}</p>
                <div className="absolute top-0 right-0">
                  <div className="group relative">
                    <span className="cursor-help text-gray-400">ⓘ</span>
                    <div className="opacity-0 bg-black text-white text-xs rounded py-1 px-2 absolute z-10 bottom-full left-1/2 transform -translate-x-1/2 transition-opacity duration-300 group-hover:opacity-100 w-48 text-center">
                      This fee includes Agora's as well as its payment partner's fees to handle payments
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-4">
                <p className="text-sm text-gray-600">Net</p>
                <p className="font-semibold">${(parsedTransactionDetails.net_amount / 100).toFixed(2)}</p>
              </div>
              <div className="mb-4">
                <p className="text-sm text-gray-600">From</p>
                <p className="font-semibold">
                {`${parsedTransactionDetails.user.first_name} ${parsedTransactionDetails.user.last_name}`}
                </p>
              </div>
              <button
                onClick={handleRefundClick}
                className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition duration-300"
              >
                Send refund
              </button>
            </>
          ) : (
            <div>
              <p className="text-sm text-gray-600 mb-2">
                Refunds take 5-10 days to appear on a customer's statement. Fees for the
                original payment won't be returned, but there are no additional fees for
                the refund.
              </p>
              <div className="mb-4">
                <label htmlFor="refundAmount" className="block text-sm font-medium text-gray-700">
                  Refund amount
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-sm">$</span>
                  </div>
                  <input
                    type="number"
                    name="refundAmount"
                    id="refundAmount"
                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                    placeholder="0.00"
                    aria-describedby="refund-currency"
                  />
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-sm" id="refund-currency">
                      USD
                    </span>
                  </div>
                </div>
              </div>
              <div className="mb-4">
                <label htmlFor="refundReason" className="block text-sm font-medium text-gray-700">
                  Reason
                </label>
                <select
                  id="refundReason"
                  name="refundReason"
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                >
                  <option>Requested by customer</option>
                  <option>Duplicate charge</option>
                  <option>Fraudulent charge</option>
                </select>
              </div>
              <div className="mb-4">
                <label htmlFor="refundDetails" className="block text-sm font-medium text-gray-700">
                  Add more details about this refund...
                </label>
                <textarea
                  id="refundDetails"
                  name="refundDetails"
                  rows={3}
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                  placeholder="Optional"
                />
              </div>
              <button
                className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition duration-300"
              >
                Refund
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TransactionDetailsPopup;