import React, { useState, useEffect } from 'react';
import { FaTimes, FaCopy, FaEdit, FaTrash } from 'react-icons/fa';
import { supabase } from '../../supabase';
import { useParams } from 'react-router-dom';

interface KnowledgeItem {
  id: string;
  title: string;
  link: string;
  created_at: string;
}

const ITEMS_PER_PAGE = 4;

interface KnowledgeItemComponentProps extends KnowledgeItem {
  openEditPopup: (item: KnowledgeItem) => void;
  deleteKnowledge: (id: string) => void;
}

const KnowledgeItemComponent: React.FC<KnowledgeItemComponentProps> = ({
  id,
  title,
  link,
  created_at,
  openEditPopup,
  deleteKnowledge,
}) => {
  const formatLink = (url: string) => {
    if (!/^https?:\/\//i.test(url)) {
      return `https://${url}`;
    }
    return url;
  };

  const formattedLink = formatLink(link);

  return (
    <div className="bg-white rounded-lg shadow-md p-4 mb-4 flex items-center justify-between">
      <div className="flex-grow">
        <h3 className="text-lg font-semibold">{title}</h3>
        <div className="flex items-center mt-2">
          <a href={formattedLink} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline mr-2">
            {link}
          </a>
          <button onClick={() => navigator.clipboard.writeText(formattedLink)} className="text-gray-500 hover:text-gray-700 mr-2">
            <FaCopy />
          </button>
          <button onClick={() => openEditPopup({ id, title, link, created_at })} className="text-blue-500 hover:text-blue-700 mr-2">
            <FaEdit />
          </button>
          <button onClick={() => deleteKnowledge(id)} className="text-red-500 hover:text-red-700">
            <FaTrash />
          </button>
        </div>
      </div>
      <span className="text-sm text-gray-500">{new Date(created_at).toLocaleDateString()}</span>
    </div>
  );
};

const KnowledgePage: React.FC = () => {
  const [knowledge, setKnowledge] = useState<KnowledgeItem[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isAddPopupOpen, setIsAddPopupOpen] = useState(false);
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [newTitle, setNewTitle] = useState('');
  const [newLink, setNewLink] = useState('');
  const [editingItem, setEditingItem] = useState<KnowledgeItem | null>(null);
  const { groupId } = useParams<{ groupId: string }>();

  useEffect(() => {
    fetchKnowledge();
  }, [currentPage, groupId]);

  const fetchKnowledge = async () => {
    if (!groupId) return;

    const { data, error, count } = await supabase
      .from('knowledge')
      .select('*', { count: 'exact' })
      .eq('organization_id', groupId)
      .order('created_at', { ascending: false })
      .range((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE - 1);

    if (error) {
      console.error('Error fetching knowledge:', error);
    } else if (data) {
      setKnowledge(data);
      setTotalPages(Math.ceil((count || 0) / ITEMS_PER_PAGE));
    }
  };

  const formatLink = (url: string) => {
    if (!/^https?:\/\//i.test(url)) {
      return `https://${url}`;
    }
    return url;
  };

  const addKnowledge = async () => {
    if (!groupId) return;

    const userId = localStorage.getItem('userId');
    const formattedLink = formatLink(newLink);
    const { data, error } = await supabase
      .from('knowledge')
      .insert([
        {
          title: newTitle,
          link: formattedLink,
          created_by: userId,
          organization_id: groupId,
        },
      ]);

    if (error) {
      console.error('Error adding knowledge:', error);
    } else {
      setIsAddPopupOpen(false);
      setNewTitle('');
      setNewLink('');
      fetchKnowledge();
    }
  };

  const editKnowledge = async () => {
    if (!groupId || !editingItem) return;

    const formattedLink = formatLink(newLink);
    const { data, error } = await supabase
      .from('knowledge')
      .update({ title: newTitle, link: formattedLink })
      .eq('id', editingItem.id)
      .eq('organization_id', groupId);

    if (error) {
      console.error('Error editing knowledge:', error);
    } else {
      setIsEditPopupOpen(false);
      setNewTitle('');
      setNewLink('');
      setEditingItem(null);
      fetchKnowledge();
    }
  };

  const deleteKnowledge = async (id: string) => {
    if (!groupId) return;

    const { error } = await supabase
      .from('knowledge')
      .delete()
      .eq('id', id)
      .eq('organization_id', groupId);

    if (error) {
      console.error('Error deleting knowledge:', error);
    } else {
      fetchKnowledge();
    }
  };

  const openEditPopup = (item: KnowledgeItem) => {
    setEditingItem(item);
    setNewTitle(item.title);
    setNewLink(item.link);
    setIsEditPopupOpen(true);
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold">Knowledge Base</h1>
        <button
          onClick={() => setIsAddPopupOpen(true)}
          className="bg-purple-700 text-white px-4 py-2 rounded-lg hover:bg-purple-800 transition-colors"
        >
          Add Knowledge
        </button>
      </div>

      {knowledge.map((item) => (
        <KnowledgeItemComponent
          key={item.id}
          {...item}
          openEditPopup={openEditPopup}
          deleteKnowledge={deleteKnowledge}
        />
      ))}

      {totalPages > 1 && (
        <div className="mt-6 flex justify-center items-center space-x-4">
          <button
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
            className="px-4 py-2 bg-gray-200 rounded-lg disabled:opacity-50"
          >
            Previous
          </button>
          <span>
            {currentPage} of {totalPages}
          </span>
          <button
            onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}
            className="px-4 py-2 bg-gray-200 rounded-lg disabled:opacity-50"
          >
            Next
          </button>
        </div>
      )}

      {isAddPopupOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg w-96 relative">
            <button
              onClick={() => setIsAddPopupOpen(false)}
              className="absolute top-2 right-2 text-red-500 hover:text-red-700"
            >
              <FaTimes />
            </button>
            <h2 className="text-xl font-semibold mb-4">Add New Knowledge</h2>
            <input
              type="text"
              placeholder="Title"
              value={newTitle}
              onChange={(e) => setNewTitle(e.target.value)}
              className="w-full p-2 mb-4 border rounded"
            />
            <input
              type="text"
              placeholder="Link"
              value={newLink}
              onChange={(e) => setNewLink(e.target.value)}
              className="w-full p-2 mb-4 border rounded"
            />
            <button
              onClick={addKnowledge}
              className="w-full bg-purple-700 text-white px-4 py-2 rounded-lg hover:bg-purple-800 transition-colors"
            >
              Save
            </button>
          </div>
        </div>
      )}
      {isEditPopupOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg w-96 relative">
            <button
              onClick={() => setIsEditPopupOpen(false)}
              className="absolute top-2 right-2 text-red-500 hover:text-red-700"
            >
              <FaTimes />
            </button>
            <h2 className="text-xl font-semibold mb-4">Edit Knowledge</h2>
            <input
              type="text"
              placeholder="Title"
              value={newTitle}
              onChange={(e) => setNewTitle(e.target.value)}
              className="w-full p-2 mb-4 border rounded"
            />
            <input
              type="text"
              placeholder="Link"
              value={newLink}
              onChange={(e) => setNewLink(e.target.value)}
              className="w-full p-2 mb-4 border rounded"
            />
            <button
              onClick={editKnowledge}
              className="w-full bg-purple-700 text-white px-4 py-2 rounded-lg hover:bg-purple-800 transition-colors"
            >
              Save Changes
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default KnowledgePage;
