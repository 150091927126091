import React from 'react';
import { Link } from 'react-router-dom';
import AgoraLogo from '../../Assets/AgoraLogo.svg';


const Header = () => {
    return (
      <header className="">
        <div className="container mx-auto flex justify-between items-center py-4 px-6">
          <div className='flex flex-row'>
            <Link to="/">
                <img src={AgoraLogo} alt="Agora Logo" className="h-8" />
            </Link>
            <h1 className="text-3xl font-bold">gora</h1>
          </div>
          <div>
            <Link to="/" className="mr-4 text-blue-500 border border-blue-500 rounded px-4 py-2 hover:bg-blue-500 hover:text-white transition duration-300">Back To Home</Link>
          </div>
        </div>
      </header>
    );
  };

const TermsAndConditions = () => {
  return (
    <div className="container mx-auto p-8">
      <Header />
      <h1 className="text-4xl font-bold mb-8">Terms and Conditions</h1>
      
      <h2 className="text-2xl font-bold mb-4">Introduction</h2>
      <p>Welcome to Agora Processing, Inc. ("Agora"). By accessing or using our platform, you agree to comply with and be bound by these Terms and Conditions ("Terms"). If you do not agree with these Terms, please do not use our services.</p>
      
      <h2 className="text-2xl font-bold mt-8 mb-4">Eligibility</h2>
      <p>Our services are intended for users who are at least 18 years old. By using our services, you represent and warrant that you are at least 18 years of age.</p>
      
      <h2 className="text-2xl font-bold mt-8 mb-4">User Responsibilities</h2>
      <ul className="list-disc ml-6">
        <li>Users must provide accurate and complete information during registration.</li>
        <li>Users are responsible for maintaining the confidentiality of their account information and for all activities that occur under their account.</li>
        <li>Users must not misuse our services, including but not limited to interfering with the normal operation of the platform or attempting to access other users' data without authorization.</li>
      </ul>
      
      <h2 className="text-2xl font-bold mt-8 mb-4">Account Registration and Security</h2>
      <ul className="list-disc ml-6">
        <li>Provide accurate, current, and complete information during registration.</li>
        <li>Maintain the security of your password and accept all risks of unauthorized access to your account.</li>
      </ul>
      
      <h2 className="text-2xl font-bold mt-8 mb-4">Organizational Accounts</h2>
      <ul className="list-disc ml-6">
        <li>Organizations can be any group created on our platform.</li>
        <li>Organizational accounts must ensure that all members comply with these Terms.</li>
        <li>Organizations are responsible for the activities of their members on the platform.</li>
      </ul>
      
      <h2 className="text-2xl font-bold mt-8 mb-4">Payment Terms</h2>
      <ul className="list-disc ml-6">
        <li>Payments for our services are processed through Stripe. By using our platform, you agree to Stripe's terms and conditions.</li>
        <li>You are responsible for all charges incurred under your account.</li>
        <li>We do not offer refunds except as required by law.</li>
      </ul>
      
      <h2 className="text-2xl font-bold mt-8 mb-4">Data Collection and Usage</h2>
      <ul className="list-disc ml-6">
        <li>We collect personal data including name, email, billing information, phone number, school information, and site activity.</li>
        <li>We use this data to provide and improve our services, process payments, and ensure financial protection.</li>
        <li>Data related to financial transactions is stored with Stripe.</li>
        <li>We retain personal information as long as the account is active and financial information indefinitely.</li>
      </ul>
      
      <h2 className="text-2xl font-bold mt-8 mb-4">Limitation of Liability</h2>
      <p>To the fullest extent permitted by law, Agora shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from:</p>
      <ul className="list-disc ml-6">
        <li>Your use or inability to use our services;</li>
        <li>Any unauthorized access to or use of our servers and/or any personal information stored therein;</li>
        <li>Any interruption or cessation of transmission to or from our services.</li>
      </ul>
      
      <h2 className="text-2xl font-bold mt-8 mb-4">Dispute Resolution</h2>
      <p>Any disputes arising out of or related to these Terms and our services shall be resolved through binding arbitration in accordance with the rules of the American Arbitration Association.</p>
      
      <h2 className="text-2xl font-bold mt-8 mb-4">Termination and Suspension</h2>
      <p>We reserve the right to terminate or suspend your account and access to our services at our sole discretion, without notice, for conduct that we believe violates these Terms or is harmful to other users.</p>
      
      <h2 className="text-2xl font-bold mt-8 mb-4">Governing Law</h2>
      <p>These Terms are governed by the laws of the State of Delaware, without regard to its conflict of laws principles.</p>
      
      <h2 className="text-2xl font-bold mt-8 mb-4">Changes to Terms</h2>
      <p>We may update these Terms from time to time. We will notify you of any changes by posting the new Terms on our platform. Your continued use of the services after any such changes constitutes your acceptance of the new Terms.</p>
      
      <h2 className="text-2xl font-bold mt-8 mb-4">Acceptance of Terms</h2>
      <p>By creating an account, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions.</p>
      
      <h2 className="text-2xl font-bold mt-8 mb-4">Contact Information</h2>
      <p>If you have any questions about these Terms, please contact us at support@agoraprocessing.com.</p>
    </div>
  );
};

export default TermsAndConditions;
