import React from 'react';

interface OrderSummaryProps {
  amounts: number[];
  handleCustomCheckout?: () => Promise<void>; 
  isCustomCheckoutActive?: boolean; 
  className: string;
}

const OrderSummary: React.FC<OrderSummaryProps> = ({ amounts, handleCustomCheckout, isCustomCheckoutActive, className }) => {
  const totalAmount = amounts.reduce((acc, amount) => acc + amount, 0);
  const formattedTotal = (totalAmount / 100).toFixed(2);

  return (
    <div className={`p-4 rounded-md bg-[#EFF0F7] border border-[#CDCBE5] ${className}`}>
      <h2 className="font-bold w-max">Order Summary</h2>
      <div className="flex items-center justify-between my-4">
        <span>Total</span>
        <span>${formattedTotal}</span>
      </div>
      {isCustomCheckoutActive && handleCustomCheckout && (
        <button
          onClick={handleCustomCheckout}
          className="px-4 py-2 mt-4 font-semibold text-white bg-indigo-600 rounded-lg hover:bg-indigo-700"
        >
          Custom Checkout
        </button>
      )}
    </div>
  );
};

export default OrderSummary;
