import React, { useState, ChangeEvent, FormEvent } from 'react';
import { FaUsers, FaPlus } from 'react-icons/fa';
import { invokeInviteRPC, createOrganization } from '../../Contexts/supabaseRPC';

const Dashboard: React.FC = () => {
  const user_id = localStorage.getItem('userId');
  
  const [inviteCode, setInviteCode] = useState('');
  const [message, setMessage] = useState('');
  const [groupName, setGroupName] = useState('');

  const handleInviteCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInviteCode(event.target.value);
  };

  const handleJoinGroup = async () => {
    if (!user_id) {
      setMessage('User not authenticated. Please log in again.');
      return;
    }

    try {
      const data = await invokeInviteRPC(inviteCode, user_id);
      if (data.status === 'success') {
        setMessage('Successfully joined the organization');
        window.location.reload();
      } else {
        setMessage(data.message);
      }
    } catch (error) {
      console.error('Error using invite:', error);
      setMessage('An error occurred while trying to join the group');
    }

    setInviteCode('');
  };

  const handleGroupNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setGroupName(event.target.value);
  };

  const handleCreateGroupSubmit = async (event: FormEvent) => {
    event.preventDefault();
  
    if (!user_id) {
      setMessage('User ID not found. Please log in again.');
      return;
    }
  
    try {
      await createOrganization(user_id, groupName);
      setMessage('Group created successfully');
      setGroupName('');
      window.location.reload();
    } catch (error) {
      console.error('Error creating organization:', error);
      setMessage('Failed to create group. Please try again.');
    }
  };

  return (
    <div className="flex flex-col items-center min-h-screen p-6 bg-gray-100">
      <div className="w-full max-w-md">
        <h1 className="mb-8 text-3xl font-bold text-center text-gray-800">Dashboard</h1>
        
        <div className="p-6 mb-6 bg-white rounded-lg shadow-md">
          <h2 className="flex items-center mb-4 text-xl font-semibold text-gray-700">
            <FaUsers className="mr-2 text-blue-500" />
            Join a Group
          </h2>
          <input
            type="text"
            placeholder="Enter invite code"
            value={inviteCode}
            onChange={handleInviteCodeChange}
            className="w-full px-4 py-2 mb-4 text-gray-700 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <button
            className="w-full px-4 py-2 font-bold text-white transition-colors bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            onClick={handleJoinGroup}
          >
            Join Group
          </button>
        </div>

        <div className="p-6 bg-white rounded-lg shadow-md">
          <h2 className="flex items-center mb-4 text-xl font-semibold text-gray-700">
            <FaPlus className="mr-2 text-green-500" />
            Create a Group
          </h2>
          <form onSubmit={handleCreateGroupSubmit}>
            <input
              type="text"
              placeholder="Group Name"
              value={groupName}
              onChange={handleGroupNameChange}
              className="w-full px-4 py-2 mb-4 text-gray-700 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
              required
            />
            <button 
              type="submit" 
              className="w-full px-4 py-2 font-bold text-white transition-colors bg-green-500 rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
            >
              Create Group
            </button>
          </form>
        </div>

        {message && (
          <div className="p-4 mt-6 text-center text-white bg-blue-500 rounded-md">
            {message}
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;