import React, { useState, useRef, useEffect } from 'react';
import { ReactComponent as Delete } from '../../../../Assets/Icons/delete.svg';
import { ReactComponent as Plus } from '../../../../Assets/Icons/plus.svg';

interface BuildPageProps {
  build_page: {
    type: string;
    title?: string;
    subtext?: string;
    url?: string;
  }[];
  handleBuildPageChange: (index: number, field: string, value: string) => void;
  addBuildPageField: (type: string) => void;
  removeBuildPageField: (index: number) => void;
  showCollegeName: boolean;
  setShowCollegeName: (value: boolean) => void;
}

const BuildPage: React.FC<BuildPageProps> = ({
  build_page,
  handleBuildPageChange,
  addBuildPageField,
  removeBuildPageField,
  showCollegeName,
  setShowCollegeName,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && buttonRef.current &&
          !dropdownRef.current.contains(event.target as Node) &&
          !buttonRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="mb-6">
      <h2 className="text-xl font-bold mb-4">Build Page</h2>
      <div className="border border-gray-200 rounded-lg overflow-hidden mb-4">
        <div className="p-4 flex justify-between items-center">
          <span className="text-lg font-semibold">Add Section</span>
          <div className="relative">
            <button
              ref={buttonRef}
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              className="p-2 hover:bg-gray-100 rounded-full transition-colors duration-200"
            >
              <Plus className={`w-6 h-6 ${isDropdownOpen ? 'transform rotate-45' : ''}`} />
            </button>
            {isDropdownOpen && (
              <div
                ref={dropdownRef}
                className="fixed right-0 mt-1 w-48 bg-white rounded-md shadow-lg z-50 border border-gray-200"
                style={{
                  top: buttonRef.current ? buttonRef.current.getBoundingClientRect().bottom + window.scrollY : 0,
                  right: buttonRef.current ? window.innerWidth - buttonRef.current.getBoundingClientRect().right : 0
                }}
              >
                <button
                  onClick={() => {
                    addBuildPageField('YouTube Video');
                    setIsDropdownOpen(false);
                  }}
                  className="w-full text-left px-4 py-2 hover:bg-gray-100 transition-colors duration-200"
                >
                  Add Youtube Video
                </button>
                <button
                  onClick={() => {
                    addBuildPageField('Information Box');
                    setIsDropdownOpen(false);
                  }}
                  className="w-full text-left px-4 py-2 hover:bg-gray-100 transition-colors duration-200"
                >
                  Add Information Box
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      {build_page.map((item, index) => (
        <div key={index} className="mt-4 border border-gray-200 rounded-lg overflow-hidden">
          <div className="p-4">
            <div className="flex items-center justify-between mb-2">
              <span className="text-lg font-semibold">{item.type}</span>
              <button
                onClick={() => removeBuildPageField(index)}
                className="p-2 bg-red-100 text-red-500 hover:bg-red-200 rounded-md transition-colors duration-200"
              >
                <Delete className="w-5 h-5" />
              </button>
            </div>
            {item.type === 'YouTube Video' && (
              <input
                type="text"
                placeholder="Youtube video URL"
                value={item.url || ''}
                onChange={(e) => handleBuildPageChange(index, 'url', e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-md"
              />
            )}
            {item.type === 'Information Box' && (
              <>
                <input
                  type="text"
                  placeholder="Box title"
                  value={item.title || ''}
                  onChange={(e) => handleBuildPageChange(index, 'title', e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-md mb-2"
                />
                <input
                  type="text"
                  placeholder="Box subtext"
                  value={item.subtext || ''}
                  onChange={(e) => handleBuildPageChange(index, 'subtext', e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-md"
                />
              </>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default BuildPage;