const apiBase = process.env.REACT_APP_SUPABASE_API_BASE_URL;

const getBearer = () => {
  const token = localStorage.getItem('userToken');
  return token ? `Bearer ${token}` : null;
};

interface FetchOptions extends RequestInit {
  headers?: HeadersInit;
}

export const fetchData = async (endpoint: string, options: FetchOptions = {}) => {
  const bearer = getBearer();
  const headers = {
    'Content-Type': 'application/json',
    ...(bearer && { Authorization: bearer }),
    ...options.headers,
  };

  const response = await fetch(`${apiBase}${endpoint}`, {
    ...options,
    headers,
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
};

export const postData = async (endpoint: string, data: any, options: FetchOptions = {}) => {
  const bearer = getBearer();
  const headers = {
    'Content-Type': 'application/json',
    ...(bearer && { Authorization: bearer }),
    ...options.headers,
  };

  const response = await fetch(`${apiBase}${endpoint}`, {
    method: 'POST',
    ...options,
    headers,
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
};
