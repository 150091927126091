import React, { useState, useEffect, useRef } from 'react';
import ListView from '../Components/Recruitment/ListView';
import LoadingSkeleton from './LoadingSkeleton';
import { fetchApplicantsByCampaign } from '../../Contexts/supabaseRecruitment';
import { useParams } from 'react-router-dom';

interface Applicant {
  id: number;
  full_name: string;
  major?: string;
  stage: string;
}

const Decision: React.FC = () => {
  const { campaignId } = useParams<{ campaignId: string }>();
  const [loading, setLoading] = useState<boolean>(true);
  const [applicants, setApplicants] = useState<Applicant[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const user_id = localStorage.getItem('userId');
  const containerRef = useRef<HTMLDivElement>(null);

  const fetchApplicants = async (page: number) => {
    if (!campaignId) {
      console.error('Campaign ID is undefined');
      setLoading(false);
      return;
    }
    setLoading(true);
    try {
      const { applicants, totalCount } = await fetchApplicantsByCampaign(campaignId, 'decision', page, itemsPerPage);
      setApplicants(applicants);
      setTotalCount(totalCount);
    } catch (error) {
      console.error('Error fetching applicants:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchApplicants(currentPage);
  }, [campaignId, currentPage, itemsPerPage]);

  useEffect(() => {
    const updateItemsPerPage = () => {
      if (containerRef.current) {
        const containerHeight = containerRef.current.clientHeight;
        const itemHeight = 100;
        const footerHeight = 60; 
        const availableHeight = containerHeight - footerHeight;
        const newItemsPerPage = Math.max(5, Math.floor(availableHeight / itemHeight));
        setItemsPerPage(newItemsPerPage);
      }
    };

    updateItemsPerPage();
    window.addEventListener('resize', updateItemsPerPage);
    return () => window.removeEventListener('resize', updateItemsPerPage);
  }, []);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const totalPages = Math.ceil(totalCount / itemsPerPage);

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxVisiblePages = 5;
    let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    if (endPage - startPage + 1 < maxVisiblePages) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }

    if (startPage > 1) {
      pageNumbers.push(
        <button key="first" onClick={() => handlePageChange(1)} className="px-2 py-1 text-gray-600 hover:bg-gray-100">
          1
        </button>
      );
      if (startPage > 2) {
        pageNumbers.push(<span key="ellipsis1">...</span>);
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`px-3 py-1 rounded-md ${
            i === currentPage
              ? 'font-medium text-indigo-700 bg-indigo-100'
              : 'text-gray-600 hover:bg-gray-100'
          }`}
        >
          {i}
        </button>
      );
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pageNumbers.push(<span key="ellipsis2">...</span>);
      }
      pageNumbers.push(
        <button
          key="last"
          onClick={() => handlePageChange(totalPages)}
          className="px-2 py-1 text-gray-600 hover:bg-gray-100"
        >
          {totalPages}
        </button>
      );
    }

    return pageNumbers;
  };

  return (
    <div ref={containerRef} className="flex flex-col h-full">
      {loading ? (
        <LoadingSkeleton count={3} />
      ) : (
        <>
          <div className="flex-grow overflow-auto min-h-0">
            <ListView 
              applicants={applicants} 
              onApplicantsUpdated={() => fetchApplicants(currentPage)}
              reviewerId={user_id as string}
            />
          </div>
          <div className="flex items-center justify-between px-4 py-3 mt-4 bg-white">
            <div className="text-sm text-gray-700">
              Showing {((currentPage - 1) * itemsPerPage) + 1} to {Math.min(currentPage * itemsPerPage, totalCount)} of {totalCount} applicants
            </div>
            <div className="flex items-center space-x-2">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="px-2 py-1 text-gray-600 rounded-md hover:bg-gray-100 disabled:opacity-50"
              >
                &lt;
              </button>
              {renderPageNumbers()}
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="px-2 py-1 text-gray-600 rounded-md hover:bg-gray-100 disabled:opacity-50"
              >
                &gt;
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Decision;