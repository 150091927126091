import React from 'react';
import { Link } from 'react-router-dom';
import { FaInstagram } from 'react-icons/fa';
import AgoraLogo from '../../../../Assets/AgoraLogo.svg';

const Footer = () => {
  return (
    <footer className="bg-[#F7F7FC] text-black py-8">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 text-center md:text-left">
        <div className="flex flex-row items-center justify-center md:justify-start space-x-4">
          <img src={AgoraLogo} alt="Agora Logo" className="h-10" />
          <p className="text-2xl font-bold">
            Agora
          </p>
        </div>
        <div className="space-y-2">
          <h4 className="font-semibold mb-2">Resources</h4>
          <ul className="space-y-1">
            <li>
              <Link to="/terms-and-conditions" className="hover:underline">
                Terms & Conditions
              </Link>
            </li>
            <li>
              <Link to="/privacy-policy" className="hover:underline">
                Privacy Policy
              </Link>
            </li>
          </ul>
        </div>
        <div className="space-y-2">
          <h4 className="font-semibold mb-2">Follow Us</h4>
          <Link to="https://www.instagram.com/AgoraOnCampus" className="hover:underline flex justify-center md:justify-start items-center">
            <FaInstagram className="text-purple-600 text-2xl" />
            <p className="ml-2">Instagram</p>
          </Link>
        </div>
      </div>
      <div className="mt-8 text-center text-sm">
        &copy; 2024 Agora Processing, Inc.
      </div>
    </footer>
  );
};

export default Footer;
