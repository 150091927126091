import React, { useState, useEffect } from 'react';
import { fetchSubscriptionPlans, addUsersToSubscription } from '../../../Contexts/billingApi';
import { fetchGroupUsers, fetchBillingAccounts } from '../../../Contexts/supabaseService';
import { useParams } from 'react-router-dom';
import { showToast } from '../../../Toast';

interface SubscriptionPlan {
  plan_id: string;
  name: string;
  amount: number;
  billing_cycle: string;
}

interface Customer {
  user_id: string;
  first_name: string;
  last_name: string;
}

interface BillingAccount {
  billing_account_id: string;
  name: string;
  provider: string;
}

const AddToSubscription = () => {
  const [subscriptionPlans, setSubscriptionPlans] = useState<SubscriptionPlan[]>([]);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [selectedSubscription, setSelectedSubscription] = useState<string | null>(null);
  const [additionalUsers, setAdditionalUsers] = useState<string[]>([]);
  const [dueDate, setDueDate] = useState<string>('');
  const [billingDay, setBillingDay] = useState<number>(14);
  const [additionalCycles, setAdditionalCycles] = useState<string>('');
  const [billingAccounts, setBillingAccounts] = useState<BillingAccount[]>([]);
  const [selectedBillingAccount, setSelectedBillingAccount] = useState<string | null>(null);
  const { groupId } = useParams<{ groupId: string }>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (!groupId) return;

      const subscriptionPlansData = await fetchSubscriptionPlans(groupId);
      setSubscriptionPlans(subscriptionPlansData);

      const groupUsers = await fetchGroupUsers(groupId);
      setCustomers(groupUsers);

      const billingAccountsData = await fetchBillingAccounts(groupId);
      setBillingAccounts(billingAccountsData);
    };

    fetchData();
  }, [groupId]);

  const handleAddUsersToSubscription = async () => {
    try {
      if (!selectedSubscription || !selectedBillingAccount) return;

      setIsLoading(true);

      await addUsersToSubscription(
        selectedSubscription,
        additionalUsers,
        new Date(dueDate),
        billingDay,
        parseInt(additionalCycles, 10),
        groupId!,
        selectedBillingAccount
      );

      setIsLoading(false);
      setAdditionalUsers([]);
      setDueDate('');
      setBillingDay(14);
      setAdditionalCycles('');
      setSelectedSubscription(null);
      setSelectedBillingAccount(null);

      showToast('Users added to subscription successfully');
    } catch (error) {
      setIsLoading(false);
      console.error('Error adding users to subscription:', error);
      showToast('An error occurred while adding users to the subscription.', true);
    }
  };

  const handleAdditionalUserSelection = (userId: string) => {
    setAdditionalUsers((prev) =>
      prev.includes(userId) ? prev.filter((id) => id !== userId) : [...prev, userId]
    );
  };

  const handleSubscriptionSelection = (planId: string) => {
    setSelectedSubscription(planId);
  };

  const handleBillingAccountSelection = (accountId: string) => {
    setSelectedBillingAccount(accountId);
  };

  const isFormValid = () => {
    return (
      selectedSubscription &&
      additionalUsers.length > 0 &&
      dueDate &&
      billingDay > 0 &&
      additionalCycles &&
      selectedBillingAccount
    );
  };

  return (
    <div className="p-4 bg-componentBg border border-componentOutline rounded-lg shadow">
      <h3 className="mb-4 text-xl font-semibold text-primaryButtonBg">Add Members to Existing Subscription</h3>
      
      <div className="mb-4">
        <h4 className="mb-2 text-lg font-semibold text-primaryButtonBg">Select a Subscription</h4>
        <div className="flex flex-wrap">
          {subscriptionPlans.map((plan) => (
            <div
              key={plan.plan_id}
              className={`p-4 m-2 border border-componentOutline rounded-lg shadow cursor-pointer ${selectedSubscription === plan.plan_id ? 'bg-primaryButtonBg text-white' : 'bg-componentBg'}`}
              onClick={() => handleSubscriptionSelection(plan.plan_id)}
            >
              <h4 className="text-lg font-semibold">{plan.name}</h4>
              <p>${(plan.amount / 100).toFixed(2)} - {plan.billing_cycle}</p>
            </div>
          ))}
        </div>
      </div>

      {selectedSubscription && (
        <>
          <div className="mb-4">
            <label className="block mb-1 font-medium text-componentText">Due Date</label>
            <input
              type="date"
              value={dueDate}
              onChange={(e) => setDueDate(e.target.value)}
              className="w-full px-2 py-1 border border-inputBorder rounded"
            />
          </div>

          <div className="mb-4">
            <label className="block mb-1 font-medium text-componentText">Billing Lead Time (Days Before Due Date To Issue Bill)</label>
            <input
              type="number"
              placeholder="Example: 14"
              value={billingDay}
              onChange={(e) => setBillingDay(parseInt(e.target.value, 10))}
              className="w-full px-2 py-1 border border-inputBorder rounded"
            />
          </div>

          <div className="mb-4">
            <label className="block mb-1 font-medium text-componentText">Cycles Remaining</label>
            <input
              type="number"
              placeholder="Example: 5"
              value={additionalCycles}
              onChange={(e) => setAdditionalCycles(e.target.value)}
              className="w-full px-2 py-1 border border-inputBorder rounded"
            />
          </div>

          <div className="mb-4">
            <h4 className="mb-2 text-lg font-semibold text-primaryButtonBg">Select Members to Add</h4>
            {customers.map((customer) => (
              <div key={customer.user_id} className="mb-2">
                <input
                  type="checkbox"
                  id={`additional-user-${customer.user_id}`}
                  checked={additionalUsers.includes(customer.user_id)}
                  onChange={() => handleAdditionalUserSelection(customer.user_id)}
                  className="mr-2"
                />
                <label htmlFor={`additional-user-${customer.user_id}`} className="text-componentText">
                  {customer.first_name} {customer.last_name}
                </label>
              </div>
            ))}
          </div>

          <div className="mb-4">
            <h4 className="mb-2 text-lg font-semibold text-primaryButtonBg">Select Billing Account</h4>
            <div className="flex flex-wrap">
              {billingAccounts.map((account) => (
                <div
                  key={account.billing_account_id}
                  className={`p-4 m-2 border border-componentOutline rounded-lg shadow cursor-pointer ${selectedBillingAccount === account.billing_account_id ? 'bg-primaryButtonBg text-white' : 'bg-componentBg'}`}
                  onClick={() => handleBillingAccountSelection(account.billing_account_id)}
                >
                  <h4 className="text-lg font-semibold">{account.name}</h4>
                  <p>{account.provider}</p>
                </div>
              ))}
            </div>
          </div>

          <button
            onClick={handleAddUsersToSubscription}
            className="px-4 py-2 font-semibold text-primaryButtonText bg-primaryButtonBg rounded-lg hover:bg-secondaryButtonBg disabled:opacity-50 disabled:cursor-not-allowed"
            disabled={!isFormValid() || isLoading}
          >
            {isLoading ? (
              <>
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white inline-block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Adding Users...
              </>
            ) : (
              'Add Users to Subscription'
            )}
          </button>
        </>
      )}
    </div>
  );
};

export default AddToSubscription;