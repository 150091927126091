import React from 'react';
import { Link } from 'react-router-dom';
import LandingImage from '../../../../Assets/LandingImage.svg';
import Strand from '../../../../Assets/strand.svg';

const Hero: React.FC = () => {
  return (
    <section className="bg-[#ECEAFD] py-10 md:py-20 rounded-bl-[30px] md:rounded-bl-[80px] rounded-br-[30px] md:rounded-br-[80px] relative overflow-hidden">
      <div className="container mx-auto px-4 md:px-6 flex flex-col-reverse md:flex-row items-center relative z-10">
        <div className="w-full md:w-1/2 pr-0 md:pr-10 mt-6 md:mt-0">
          <h1 className="text-3xl md:text-5xl font-bold mb-4 leading-tight text-center md:text-left">
            Agora Empowers Your <span className="text-indigo-600 inline-block">Organization</span> to Focus on What Matters Most
          </h1>
          <p className="text-sm md:text-base text-gray-600 dark:text-gray-400 mb-6 text-center md:text-left">
            Agora is an ERP that automates and manages college organizations.
          </p>
          <div className="text-center md:text-left">
            <Link to="/signup">
                <button className="px-6 md:px-8 py-2 md:py-3 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition duration-300">
                Join Us
                </button>
            </Link>
          </div>
        </div>
        <div className="w-full md:w-1/2 relative">
          <div className="relative w-full h-0 pb-[100%]">
            <img src={LandingImage} alt="Landing" className="absolute top-0 left-0 w-full h-full object-contain" />
          </div>
          <div className="absolute -top-4 -right-4 bg-white rounded-full p-2 shadow-lg z-20 hidden md:block">
            <div className="bg-indigo-100 rounded-full p-2">
              <svg className="w-8 h-8 text-indigo-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 10h4.764a2 2 0 011.789 2.894l-3.5 7A2 2 0 0115.263 21h-4.017c-.163 0-.326-.02-.485-.06L7 20m7-10V5a2 2 0 00-2-2h-.095c-.5 0-.905.405-.905.905 0 .714-.211 1.412-.608 2.006L7 11v9m7-10h-2M7 20H5a2 2 0 01-2-2v-6a2 2 0 012-2h2.5" />
              </svg>
            </div>
          </div>
          <div className="absolute bottom-16 -left-4 bg-white rounded-lg p-3 shadow-lg z-20 hidden md:block">
            <div className="flex items-center">
              <svg className="w-8 h-8 text-indigo-600 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
              </svg>
              <span className="font-semibold">Applicants</span>
            </div>
          </div>
        </div>
      </div>
      <img 
        src={Strand} 
        alt="Strand" 
        className="absolute bottom-0 right-0 w-[200%] md:w-[150%] h-auto object-cover opacity-50 z-0" 
        style={{ transform: 'translateX(25%) translateY(50%) rotate(-30deg)' }} 
      />
    </section>
  );
};

export default Hero;
