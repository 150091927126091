import React, { createContext, useContext, ReactNode } from 'react';
import { Navigate, Outlet, useParams } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { useAdminMembership } from '../hooks/useAdminMembership';

const AdminRoleContext = createContext<string[]>([]);

export const useAdminRole = () => useContext(AdminRoleContext);

interface AdminPrivateRouteProps {
  requiredPermissions?: string[];
}

const AdminPrivateRoute: React.FC<AdminPrivateRouteProps> = ({ requiredPermissions = [] }) => {
  const { user } = useAuth();
  const { groupId } = useParams<{ groupId: string }>();
  const { isAdmin, userPermissions } = useAdminMembership(user?.id ?? '', groupId ?? '');

  if (!user) {
    return <Navigate to="/login" />;
  }

  if (!groupId) {
    return <Navigate to="/dashboard" />;
  }

  if (isAdmin === null) {
    return <div>Loading...</div>;
  }

  if (requiredPermissions.length === 0) {
    if (!isAdmin) {
      return <Navigate to={`/dashboard/${groupId}`} />;
    }
  } else {
    const hasPermission = requiredPermissions.some(permission => userPermissions.includes(permission));
    if (!isAdmin || !hasPermission) {
      return <Navigate to={`/dashboard/${groupId}`} />;
    }
  }

  return (
    <AdminRoleContext.Provider value={userPermissions}>
      <Outlet />
    </AdminRoleContext.Provider>
  );
};

export default AdminPrivateRoute;