import { useState, useEffect } from 'react';
import { supabase } from '../supabase';

const useHasCampaigns = (groupId: string) => {
  const [campaignId, setCampaignId] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchCampaignId = async () => {
      if (!groupId) {
        setCampaignId(null);
        setLoading(false);
        return;
      }

      try {
        const { data, error } = await supabase
          .from('campaigns')
          .select('id')
          .eq('organization_id', groupId)
          .order('created_at', { ascending: false })
          .limit(1)
          .single();

        if (error) throw error;

        setCampaignId(data?.id || null);
      } catch (error) {
        // console.error('Error fetching campaign ID:', error);
        setCampaignId(null);
      } finally {
        setLoading(false);
      }
    };

    fetchCampaignId();
  }, [groupId]);

  return { campaignId, loading };
};

export default useHasCampaigns;