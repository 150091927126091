import { supabase } from '../supabase';

export const fetchMembershipData = async (userId: string) => {
  try {
    const { data, error } = await supabase
      .from('memberships')
      .select('*')
      .eq('user_id', userId);

    if (error) throw error;
    console.log(data);
    return data;
  } catch (error) {
    if (error instanceof Error) {
      console.error('Error fetching membership data:', error.message);
    }
    throw error;
  }
};

export const createGroupInvite = async (
  organizationId: string,
  expiresAt: string | null,
  maxUses: number
) => {
  const { data, error } = await supabase.from('invites').insert({
    organization_id: organizationId,
    code: Math.random().toString(36).substr(2, 9),
    expires_at: expiresAt || null,
    max_uses: maxUses
  }).select();

  if (error) {
    throw new Error(error.message);
  }

  return data;
};

export const fetchUserData = async (userId: string) => {
  try {
    const { data, error } = await supabase
      .from('users')
      .select(`
        *,
        colleges (name)
      `)
      .eq('user_id', userId);

    if (error) throw error;
    return data;
  } catch (error) {
    if (error instanceof Error) {
      console.error('Error fetching user data:', error.message);
    }
    throw error;
  }
};

interface NamedEntity {
  name: string;
}

interface OrderDetail {
  item_id: string;
  quantity: number;
  amount: number;
  organization_id: string;
  organizations: NamedEntity | NamedEntity[];
  products: NamedEntity | NamedEntity[];
}

export const fetchUserOrderHistory = async (userId: string, page: number, itemsPerPage: number = 10) => {
  const from = (page - 1) * itemsPerPage;
  const to = from + itemsPerPage - 1;

  const { data, error, count } = await supabase
    .from('payments')
    .select('payment_id, amount, item_ids, created_at', { count: 'exact' })
    .eq('user_id', userId)
    .order('created_at', { ascending: false })
    .range(from, to);

  if (error) {
    throw error;
  }

  return { 
    orders: data,
    totalCount: count || 0
  };
};

export const fetchPaymentInformation = async (itemIdsText: string): Promise<OrderDetail[]> => {
  const itemIds = itemIdsText.split(',').map(id => id.trim());

  try {
    const { data, error } = await supabase
      .from('paidorganizationitems')
      .select(`
        item_id,
        quantity,
        amount,
        organization_id,
        organizations (name),
        products (name)
      `)
      .in('item_id', itemIds);

    if (error) {
      console.error('Supabase error:', error);
      throw new Error('Failed to fetch payment information');
    }

    if (!data || data.length === 0) {
      console.warn('No payment information found for item IDs:', itemIds);
      return [];
    }

    // Transform the data to match the expected OrderDetail structure
    return data.map(item => ({
      item_id: item.item_id,
      quantity: item.quantity,
      amount: item.amount,
      organization_id: item.organization_id,
      organizations: item.organizations,
      products: item.products,
    }));
  } catch (error) {
    console.error('Error in fetchPaymentInformation:', error);
    throw error;
  }
};

type Organization = {
  organization_id: string;
  role: string;
  organizations: {
    name: string;
  }[] | {
    name: string;
  };
};

export const fetchDashOrgs = async (userId: string) => {
  try {
    const { data, error } = await supabase
      .from('memberships')
      .select('organization_id, role, organizations(name)')
      .eq('user_id', userId);

    if (error) {
      throw error;
    }

    const transformedData = (data as Organization[])
      .map(({ organization_id, role, organizations }) => ({
        organization_id,
        name: Array.isArray(organizations) ? organizations[0]?.name || '' : organizations?.name || '',
        role,
      }));


    return transformedData;
  } catch (error) {
    console.error('Error fetching organizations:', error);
    throw error;
  }
};


// export const fetchAdminOrganizations = async (userId: string) => {
//   try {
//     const { data, error } = await supabase
//       .from('memberships')
//       .select('organization_id, organizations(name)')
//       .eq('user_id', userId)
//       .eq('role', 'admin');

//     if (error) throw error;

//     return data.map((org: { organization_id: string, organizations: { name: string } }) => ({
//       organization_id: org.organization_id,
//       name: org.organizations.name,
//     }));
//   } catch (error) {
//     if (error instanceof Error) {
//       console.error('Error fetching admin organizations:', error.message);
//     }
//     throw error;
//   }
// };


export const checkExistingAccount = async (organization_id: string) => {
  try {
    const { data, error } = await supabase
      .from('billingaccounts')
      .select('provider_account_id')
      .eq('organization_id', organization_id)
      .eq('provider', 'Stripe');

    if (error) {
      console.error('Error checking existing account:', error);
      throw error;
    }

    return data.length > 0;
  } catch (error) {
    if (error instanceof Error) {
      console.error('Error checking existing account:', error.message);
    }
    throw error;
  }
};

type GroupUser = {
  user_id: string;
  first_name: string;
  last_name: string;
};

export const fetchGroupUsers = async (organization_id: string): Promise<GroupUser[]> => {
  try {
    const { data, error } = await supabase
      .from('memberships')
      .select(`
        user_id,
        users (
          user_id,
          first_name,
          last_name
        )
      `)
      .eq('organization_id', organization_id)
      .order('user_id', { foreignTable: 'users', ascending: true });

    if (error) {
      console.error('Error fetching group users:', error.message);
      throw error;
    }

    const transformedData: GroupUser[] = data.map((item: any) => {
      const user = item.users;
      if (user) {
        return {
          user_id: user.user_id,
          first_name: user.first_name,
          last_name: user.last_name
        };
      }
      return null;
    }).filter((user): user is GroupUser => user !== null);


    return transformedData;
  } catch (error) {
    if (error instanceof Error) {
      console.error('Error fetching group users:', error.message);
    }
    throw error;
  }
};










export const fetchBillingAccounts = async (organization_id: string) => {
  try {
    const { data, error } = await supabase
      .from('billingaccounts')
      .select('*')
      .eq('organization_id', organization_id);

    if (error) {
      throw error;
    }
    return data;
  } catch (error) {
    if (error instanceof Error) {
      console.error('Error fetching billing accounts:', error.message);
    }
    return [];
  }
};

export const fetchPlans = async (organization_id: string) => {
  try {
    const { data, error } = await supabase
      .from('subscriptionplans')
      .select('*')
      .eq('organization_id', organization_id);

    if (error) {
      console.error('Error fetching plans:', error.message);
      throw error;
    }

    return data;
  } catch (error) {
    if (error instanceof Error) {
      console.error('Error fetching plans:', error.message);
    }
    return [];
  }
};

export const fetchSubscriptions = async (organization_id: string) => {
  try {
    const { data, error } = await supabase
      .from('subscriptionassignments')
      .select('*')
      .eq('organization_id', organization_id);

    if (error) {
      console.error('Error fetching subscriptions:', error.message);
      throw error;
    }

    return data;
  } catch (error) {
    if (error instanceof Error) {
      console.error('Error fetching subscriptions:', error.message);
    }
    throw error;
  }
};

export const fetchOneTimePayments = async (organization_id: string) => {
  try {
    const { data, error } = await supabase
      .from('invoices')
      .select('*')
      .eq('organization_id', organization_id)
      .eq('invoice_items.item_type', 'one_time');

    if (error) {
      console.error('Error fetching one-time payments:', error.message);
      throw error;
    }

    return data;
  } catch (error) {
    if (error instanceof Error) {
      console.error('Error fetching one-time payments:', error.message);
    }
    throw error;
  }
};

export const fetchOrganizationSettings = async (organization_id: string) => {
  try {
    const { data, error } = await supabase
      .from('organizations')
      .select('*')
      .eq('organization_id', organization_id)
      .single();

    if (error) {
      console.error('Error fetching organization settings:', error.message);
      throw error;
    }

    return data;
  } catch (error) {
    if (error instanceof Error) {
      console.error('Error fetching organization settings:', error.message);
    }
    throw error;
  }
};

export const updateOrganizationSettings = async (
  organization_id: string,
  updatedSettings: Record<string, any>
) => {
  try {
    const { data, error } = await supabase
      .from('organizations')
      .update(updatedSettings)
      .eq('organization_id', organization_id);

    if (error) {
      console.error('Error updating organization settings:', error.message);
      throw error;
    }

    return data;
  } catch (error) {
    if (error instanceof Error) {
      console.error('Error updating organization settings:', error.message);
    }
    throw error;
  }
};

export const fetchProducts = async (organization_id: string) => {
  try {
    const { data, error } = await supabase
      .from('products')
      .select('*')
      .eq('organization_id', organization_id);

    if (error) {
      console.error('Error fetching products:', error.message);
      throw error;
    }

    return data;
  } catch (error) {
    if (error instanceof Error) {
      console.error('Error fetching products:', error.message);
    }
    throw error;
  }
};

export const createProduct = async (
  organization_id: string,
  productData: Record<string, any>
) => {
  try {
    const { data, error } = await supabase
      .from('products')
      .insert({ organization_id: organization_id, ...productData });

    if (error) {
      console.error('Error creating product:', error.message);
      throw error;
    }

    return data;
  } catch (error) {
    if (error instanceof Error) {
      console.error('Error creating product:', error.message);
    }
    throw error;
  }
};

export const deleteProduct = async (productId: string) => {
  try {
    const { data, error } = await supabase
      .from('products')
      .delete()
      .eq('product_id', productId);

    if (error) {
      console.error('Error deleting product:', error.message);
      throw error;
    }

    return data;
  } catch (error) {
    if (error instanceof Error) {
      console.error('Error deleting product:', error.message);
    }
    throw error;
  }
};

export const fetchTransactionHistory = async (organization_id: string) => {
  try {
    const { data, error } = await supabase
      .from('payments')
      .select(`
        payment_id,
        invoices (
          invoice_id,
          due_date,
          invoiceitems (
            invoice_item_id,
            item_description,
            amount,
            currency
          )
        ),
        amount,
        currency,
        payment_method,
        status,
        created_at
      `)
      .eq('invoices.organization_id', organization_id)
      .order('created_at', { ascending: false });

    if (error) {
      console.error('Error fetching transaction history:', error.message);
      throw error;
    }

    return data;
  } catch (error) {
    if (error instanceof Error) {
      console.error('Error fetching transaction history:', error.message);
    }
    throw error;
  }
};

export const fetchUserInvoices = async (userId: string, groupId: string) => {
  try {
    const { data, error } = await supabase
      .from('invoices')
      .select(`
        invoice_id,
        amount,
        currency,
        status,
        due_date,
        invoiceitems (
          invoice_item_id,
          item_type,
          item_description,
          amount,
          currency
        )
      `)
      .eq('user_id', userId)
      .eq('organization_id', groupId);

    if (error) {
      console.error('Error fetching user invoices:', error.message);
      throw error;
    }

    return data;
  } catch (error) {
    if (error instanceof Error) {
      console.error('Error fetching user invoices:', error.message);
    }
    throw error;
  }
};

export const fetchUserOrganizationItems = async (
  userId: string,
  organizationId: string
) => {
  try {
    const { data, error } = await supabase
      .from('organizationitems')
      .select(`
        item_id,
        product_id,
        products!fk_organizationitems_product (
          name,
          stripe_price_id
        ),
        quantity,
        amount,
        currency,
        status,
        due_date
      `)
      .eq('user_id', userId)
      .eq('organization_id', organizationId);

    if (error) {
      console.error('Error fetching user organization items:', error.message);
      throw error;
    }

    return data;
  } catch (error) {
    if (error instanceof Error) {
      console.error('Error fetching user organization items:', error.message);
    }
    throw error;
  }
};

export const updateOrganizationItemStatus = async (
  itemId: string,
  status: string
) => {
  try {
    const { data, error } = await supabase
      .from('organizationitems')
      .update({ status })
      .eq('item_id', itemId);

    if (error) {
      console.error('Error updating organization item status:', error.message);
      throw error;
    }

    return data;
  } catch (error) {
    if (error instanceof Error) {
      console.error('Error updating organization item status:', error.message);
    }
    throw error;
  }
};

export const fetchStripeConnectedAccountId = async (organizationId: string) => {
  try {
    const { data, error } = await supabase
      .from('billingaccounts')
      .select('provider_account_id')
      .eq('organization_id', organizationId)
      .eq('provider', 'Stripe')
      .single();

    if (error) {
      console.error('Error fetching Stripe connected account ID:', error);
      throw error;
    }

    return data.provider_account_id;
  } catch (error) {
    if (error instanceof Error) {
      console.error('Error fetching Stripe connected account ID:', error.message);
    }
    throw error;
  }
};

export const getCollegeDescriptor = async (userId: string): Promise<string | null> => {
  try {
    const { data, error } = await supabase
      .from('users')
      .select('college_id')
      .eq('user_id', userId)
      .single();

    if (error) throw error;

    if (data && data.college_id) {
      const { data: collegeData, error: collegeError } = await supabase
        .from('colleges')
        .select('descriptor')
        .eq('college_id', data.college_id)
        .single();

      if (collegeError) throw collegeError;

      return collegeData?.descriptor || null;
    }

    return null;
  } catch (error) {
    console.error('Error fetching college descriptor:', error);
    return null;
  }
};

interface Event {
  id: string; 
  name: string;
  date_time: string;
  event_over: boolean;
  track_attendance: boolean;
  campaigns: {
      id: string; 
      name: string;
  } | null;
}

export const fetchCampaignEvents = async (organizationId: string): Promise<Event[]> => {
  const { data, error } = await supabase
    .from('events')
    .select(`
      id,
      name,
      date_time,
      event_over,
      track_attendance,
      campaigns!inner (
        id,
        name,
        organization_id
      )
    `)
    .eq('campaigns.organization_id', organizationId)
    .order('date_time', { ascending: true });

  if (error) {
    console.error('Error fetching campaign events:', error);
    throw error;
  }

  // If data is null or empty, return an empty array
  if (!data || data.length === 0) {
    return [];
  }

  const validatedData: Event[] = data.map((item: any) => ({
    id: item.id,
    name: item.name,
    date_time: item.date_time,
    event_over: item.event_over,
    track_attendance: item.track_attendance,
    campaigns: {
      id: item.campaigns.id,
      name: item.campaigns.name
    }
  }));

  return validatedData;
};

export const updateEventStatus = async (eventId: string, newStatus: boolean) => {
  const { data, error } = await supabase
    .from('events')
    .update({ event_over: newStatus })
    .eq('id', eventId);

  if (error) throw error;
  return data;
};