import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { supabase } from '../../../supabase';
import { showToast } from '../../../Toast';

interface ReviewPopupProps {
  isOpen: boolean;
  onClose: () => void;
  applicantId: number;
  reviewerId: string;
  reviewType: 'applicant' | 'interview';
}

const ReviewPopup: React.FC<ReviewPopupProps> = ({
  isOpen,
  onClose,
  applicantId,
  reviewerId,
  reviewType,
}) => {
  const [reviewText, setReviewText] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    setError(null);

    try {
      const { data, error } = await supabase
        .from('applicant_reviews')
        .insert({
          applicant_id: applicantId,
          reviewer_id: reviewerId,
          review_text: reviewText,
          review_type: reviewType,
        });

      if (error) throw error;

      showToast('Review Submitted Successfully!');
      setReviewText('');
      onClose();
    } catch (err) {
        showToast('Error submitting review:', true);
      setError('Failed to submit review. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex justify-center items-center z-50" onClick={onClose}>
      <div className="bg-white rounded-lg p-6 w-96 relative" onClick={(e) => e.stopPropagation()}>
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        >
          <FaTimes />
        </button>
        <h2 className="text-xl font-bold mb-4">
          {reviewType === 'applicant' ? 'Applicant Review' : 'Interview Review'}
        </h2>
        <textarea
          className="w-full h-32 p-2 border rounded-md mb-4"
          placeholder="Enter your review here..."
          value={reviewText}
          onChange={(e) => setReviewText(e.target.value)}
        />
        {error && <p className="text-red-500 mb-2">{error}</p>}
        <button
          onClick={handleSubmit}
          disabled={isSubmitting}
          className={`w-full bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 ${
            isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
          }`}
        >
          {isSubmitting ? 'Submitting...' : 'Submit Review'}
        </button>
      </div>
    </div>
  );
};

export default ReviewPopup;