import React, { useState, useEffect } from 'react';
import { createSubscription } from '../../../Contexts/billingApi';
import { fetchGroupUsers, fetchBillingAccounts } from '../../../Contexts/supabaseService';
import { useParams } from 'react-router-dom';
import { showToast } from '../../../Toast';

interface Customer {
  user_id: string;
  first_name: string;
  last_name: string;
}

interface BillingAccount {
  billing_account_id: string;
  name: string;
  provider: string;
}

const CreateSubscription = () => {
  const [newSubscription, setNewSubscription] = useState({
    name: '',
    amount: 0,
    formattedAmount: '',
    currency: 'USD',
    billing_cycle: 'Monthly',
    due_date: '',
    billing_days_before_due: 14,
    cycles_remaining: '',
  });
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [selectedCustomers, setSelectedCustomers] = useState<string[]>([]);
  const [billingAccounts, setBillingAccounts] = useState<BillingAccount[]>([]);
  const [selectedBillingAccount, setSelectedBillingAccount] = useState<string | null>(null);
  const [acknowledgment, setAcknowledgment] = useState<boolean>(false);
  const { groupId } = useParams<{ groupId: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [amtToReceive, setAmtToReceive] = useState<string>('0');

  useEffect(() => {
    const fetchData = async () => {
      if (!groupId) return;

      const groupUsers = await fetchGroupUsers(groupId);
      setCustomers(groupUsers);

      const billingAccountsData = await fetchBillingAccounts(groupId);
      setBillingAccounts(billingAccountsData);
    };

    fetchData();
  }, [groupId]);

  const handleCreateSubscription = async () => {
    try {
      if (!selectedBillingAccount) return;

      setIsLoading(true);

      const result = await createSubscription(
        groupId!,
        { ...newSubscription, billing_account_id: selectedBillingAccount },
        selectedCustomers
      );

      setIsLoading(false);

      if (result === 200) {
        setNewSubscription({
          name: '',
          amount: 0,
          formattedAmount: '',
          currency: 'USD',
          billing_cycle: 'Monthly',
          due_date: '',
          billing_days_before_due: 14,
          cycles_remaining: '',
        });
        setSelectedCustomers([]);
        setAcknowledgment(false);

        showToast('Subscription created successfully!');
      } else {
        console.error('Unexpected result from createSubscription:', result);
        showToast('An error occurred while creating the subscription. Please try again.', true);
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error creating subscription:', error);
      showToast('An error occurred while creating the subscription. Please try again.', true);
    }
  };

  const handleCustomerSelection = (userId: string) => {
    setSelectedCustomers((prev) =>
      prev.includes(userId) ? prev.filter((id) => id !== userId) : [...prev, userId]
    );
  };

  const handleAmountChange = (value: string) => {
    const cents = parseInt(value.replace(/[^0-9]/g, ''), 10) || 0;
    const formattedAmount = (cents / 100).toFixed(2);
    const receiveFormattedAmount = (((cents * .941)- 30) / 100).toFixed(2);
    setNewSubscription((prev) => ({ ...prev, amount: cents, formattedAmount }));
    setAmtToReceive(receiveFormattedAmount);
  };

  const handleBillingAccountSelection = (accountId: string) => {
    setSelectedBillingAccount(accountId);
  };

  const isFormValid = () => {
    const { name, amount, due_date, billing_days_before_due, cycles_remaining } = newSubscription;
    const dueDateValid = new Date(due_date) >= new Date() && new Date(due_date) <= new Date(Date.now() + 30 * 24 * 60 * 60 * 1000);
    const billingLeadTimeValid = billing_days_before_due > 0 && billing_days_before_due < 28;
    const cyclesRemainingValid = parseInt(cycles_remaining, 10) > 0 && parseInt(cycles_remaining, 10) < 10;
    return name && amount && due_date && dueDateValid && billingLeadTimeValid && cyclesRemainingValid && selectedCustomers.length > 0 && selectedBillingAccount && acknowledgment;
  };

  return (
    <div className="p-4 mt-4 border rounded-lg shadow bg-componentBg border-componentOutline">
      <h4 className="mb-2 text-lg font-semibold text-primaryButtonBg">Create Subscription</h4>

      <div className="mb-4">
        <label className="block mb-1 font-medium text-componentText">Subscription Name</label>
        <input
          type="text"
          placeholder="Example: Spring Semester Membership"
          value={newSubscription.name}
          onChange={(e) => setNewSubscription({ ...newSubscription, name: e.target.value })}
          className="w-full px-2 py-1 border rounded border-inputBorder"
        />
      </div>

      <div className="mb-4">
        <label className="block mb-1 font-medium text-componentText">Amount</label>
        <input
          type="text"
          placeholder="Example: 999 (for $9.99)"
          value={newSubscription.formattedAmount}
          onChange={(e) => handleAmountChange(e.target.value)}
          className="w-full px-2 py-1 text-right border rounded border-inputBorder"
        />
        <label className="block mb-1 font-small text-componentText"> Amount to be received: {amtToReceive}</label>
      </div>

      <div className="mb-4">
        <label className="block mb-1 font-medium text-componentText">Currency</label>
        <select
          value={newSubscription.currency}
          onChange={(e) => setNewSubscription({ ...newSubscription, currency: e.target.value })}
          className="w-full px-2 py-1 border rounded border-inputBorder"
        >
          <option value="USD">USD</option>
        </select>
      </div>

      <div className="mb-4">
        <label className="block mb-1 font-medium text-componentText">Billing Cycle</label>
        <select
          value={newSubscription.billing_cycle}
          onChange={(e) => setNewSubscription({ ...newSubscription, billing_cycle: e.target.value })}
          className="w-full px-2 py-1 border rounded border-inputBorder"
        >
          <option value="Monthly">Monthly</option>
        </select>
      </div>

      <div className="mb-4">
        <label className="block mb-1 font-medium text-componentText">Due Date</label>
        <input
          type="date"
          value={newSubscription.due_date}
          onChange={(e) => setNewSubscription({ ...newSubscription, due_date: e.target.value })}
          className="w-full px-2 py-1 border rounded border-inputBorder"
        />
      </div>

      <div className="mb-4">
        <label className="block mb-1 font-medium text-componentText">Billing Lead Time (Recommended: Send Bill 14 Days Before Due Date)</label>
        <input
          type="number"
          placeholder="Example: 14"
          value={newSubscription.billing_days_before_due}
          onChange={(e) => setNewSubscription({ ...newSubscription, billing_days_before_due: parseInt(e.target.value, 10) })}
          className="w-full px-2 py-1 border rounded border-inputBorder"
        />
      </div>

      <div className="mb-4">
        <label className="block mb-1 font-medium text-componentText">Cycles Remaining</label>
        <input
          type="number"
          placeholder="Example: 5"
          value={newSubscription.cycles_remaining}
          onChange={(e) => setNewSubscription({ ...newSubscription, cycles_remaining: e.target.value })}
          className="w-full px-2 py-1 border rounded border-inputBorder"
        />
      </div>

      <div className="mb-4">
        <h4 className="mb-2 text-lg font-semibold text-primaryButtonBg">Select Members</h4>
        {customers.map((customer) => (
          <div key={customer.user_id} className="mb-2">
            <input
              type="checkbox"
              id={`customer-${customer.user_id}`}
              checked={selectedCustomers.includes(customer.user_id)}
              onChange={() => handleCustomerSelection(customer.user_id)}
              className="mr-2"
            />
            <label htmlFor={`customer-${customer.user_id}`} className="text-componentText">
              {customer.first_name} {customer.last_name}
            </label>
          </div>
        ))}
      </div>

      <div className="mb-4">
        <h4 className="mb-2 text-lg font-semibold text-primaryButtonBg">Select Billing Account</h4>
        <div className="flex flex-wrap">
          {billingAccounts.map((account) => (
            <div
              key={account.billing_account_id}
              className={`p-4 m-2 border border-componentOutline rounded-lg shadow cursor-pointer ${selectedBillingAccount === account.billing_account_id ? 'bg-primaryButtonBg text-white' : 'bg-componentBg'}`}
              onClick={() => handleBillingAccountSelection(account.billing_account_id)}
            >
              <h4 className="text-lg font-semibold">{account.name}</h4>
              <p>{account.provider}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="mb-4">
        <h4 className="mb-2 text-lg font-semibold text-primaryButtonBg">Acknowledgment</h4>
        <p className="mb-2 text-sm">
          I acknowledge I am creating a subscription called <strong>{newSubscription.name}</strong>, this subscription will be for a total of <strong>${(newSubscription.amount / 100).toFixed(2)}</strong> before all fees, with the first due date set to <strong>{newSubscription.due_date}</strong>, and the bill for this date will be issued today. The due dates as well as billing date can vary by month based on the selected Due Date, per the cycles remaining specified there will be <strong>{newSubscription.cycles_remaining}</strong> bills that will continue to be created on <strong>{Array.from({ length: parseInt(newSubscription.cycles_remaining, 10) }, (_, i) => new Date(new Date(newSubscription.due_date).getTime() + (i + 1) * 30 * 24 * 60 * 60 * 1000)).map(date => date.toLocaleDateString()).join(', ')}</strong> for the selected members <strong>{selectedCustomers.map(customerId => {
            const customer = customers.find(customer => customer.user_id === customerId);
            return customer ? `${customer.first_name} ${customer.last_name}` : '';
          }).join(', ')}</strong>, and the funds upon their payments will be deposited into <strong>{billingAccounts.find(account => account.billing_account_id === selectedBillingAccount)?.name}</strong>, <strong>{billingAccounts.find(account => account.billing_account_id === selectedBillingAccount)?.provider}</strong> after the funds have settled.
        </p>
        <div>
          <input
            type="checkbox"
            id="acknowledgment"
            checked={acknowledgment}
            onChange={(e) => setAcknowledgment(e.target.checked)}
            className="mr-2"
          />
          <label htmlFor="acknowledgment" className="text-componentText">
            I have read and acknowledged the statement above.
          </label>
        </div>
      </div>

      <button
        onClick={handleCreateSubscription}
        className="px-4 py-2 font-semibold rounded-lg text-primaryButtonText bg-primaryButtonBg hover:bg-secondaryButtonBg disabled:opacity-50 disabled:cursor-not-allowed"
        disabled={!isFormValid() || isLoading}
      >
        {isLoading ? (
          <>
            <svg className="inline-block w-5 h-5 mr-3 -ml-1 text-white animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            Creating...
          </>
        ) : (
          'Create Subscription'
        )}
      </button>
    </div>
  );
};

export default CreateSubscription;