import React, { useState, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import { supabase } from '../../../supabase';

interface ApplicantDetailPopupProps {
  isOpen: boolean;
  onClose: () => void;
  applicantId: number;
}

interface ApplicantDetail {
  id: number;
  full_name: string;
  email: string;
  phone_number: string;
  major: string;
  graduation_year: string;
  stage: string;
  created_at: string;
  campaign_id: number;
}

interface Review {
  id: number;
  reviewer_id: string;
  review_text: string;
  review_type: 'applicant' | 'interview';
  created_at: string;
}

interface Event {
  id: string;
  name: string;
  date_time: string;
}

const ApplicantDetailPopup: React.FC<ApplicantDetailPopupProps> = ({ isOpen, onClose, applicantId }) => {
  const [applicant, setApplicant] = useState<ApplicantDetail | null>(null);
  const [reviews, setReviews] = useState<Review[]>([]);
  const [events, setEvents] = useState<Event[]>([]);

  useEffect(() => {
    if (isOpen && applicantId) {
      fetchApplicantDetails();
      fetchApplicantReviews();
    }
  }, [isOpen, applicantId]);

  useEffect(() => {
    if (applicant) {
      fetchApplicantEvents();
    }
  }, [applicant]);

  const fetchApplicantDetails = async () => {
    try {
      const { data, error } = await supabase
        .from('applicants')
        .select('*')
        .eq('id', applicantId)
        .single();

      if (error) throw error;
      setApplicant(data);
    } catch (error) {
      console.error('Error fetching applicant details:', error);
    }
  };

  const fetchApplicantReviews = async () => {
    try {
      const { data, error } = await supabase
        .from('applicant_reviews')
        .select('*')
        .eq('applicant_id', applicantId)
        .order('created_at', { ascending: false });

      if (error) throw error;
      setReviews(data);
    } catch (error) {
      console.error('Error fetching applicant reviews:', error);
    }
  };

  const fetchApplicantEvents = async () => {
    try {
      const { data, error } = await supabase
        .from('attendance')
        .select('event_id')
        .eq('applicant_id', applicantId)
        .eq('campaign_id', applicant!.campaign_id);

      if (error) throw error;

      const eventIds = data.map(item => item.event_id);

      const { data: eventsData, error: eventsError } = await supabase
        .from('events')
        .select('id, name, date_time')
        .in('id', eventIds)
        .order('date_time', { ascending: false });

      if (eventsError) throw eventsError;
      setEvents(eventsData);
    } catch (error) {
      console.error('Error fetching applicant events:', error);
    }
  };

  if (!isOpen || !applicant) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex justify-center items-center z-50" onClick={onClose}>
      <div className="bg-white rounded-lg shadow-xl p-8 w-11/12 max-w-4xl max-h-[90vh] overflow-y-auto" onClick={(e) => e.stopPropagation()}>
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-gray-600 transition-colors"
        >
          <FaTimes size={24} />
        </button>
        
        <h2 className="text-3xl font-bold mb-6 text-gray-800">{applicant.full_name}</h2>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
          <div className="bg-gray-50 p-4 rounded-lg shadow">
            <h3 className="text-xl font-semibold mb-3 text-gray-700">Personal Information</h3>
            <p><span className="font-medium text-gray-600">Email:</span> {applicant.email}</p>
            <p><span className="font-medium text-gray-600">Phone:</span> {applicant.phone_number || 'N/A'}</p>
            <p><span className="font-medium text-gray-600">Major:</span> {applicant.major || 'N/A'}</p>
          </div>
          <div className="bg-gray-50 p-4 rounded-lg shadow">
            <h3 className="text-xl font-semibold mb-3 text-gray-700">Application Details</h3>
            <p><span className="font-medium text-gray-600">Graduation Year:</span> {applicant.graduation_year || 'N/A'}</p>
            <p><span className="font-medium text-gray-600">Stage:</span> <span className="capitalize">{applicant.stage}</span></p>
            <p><span className="font-medium text-gray-600">Applied on:</span> {new Date(applicant.created_at).toLocaleDateString()}</p>
          </div>
        </div>

        <div className="mb-8">
          <h3 className="text-2xl font-semibold mb-4 text-gray-800">Events Attended</h3>
          {events.length > 0 ? (
            <ul className="space-y-2">
              {events.map(event => (
                <li key={event.id} className="bg-blue-50 p-3 rounded-md shadow-sm">
                  <span className="font-medium text-blue-700">{event.name}</span>
                  <span className="text-gray-600 ml-2">- {new Date(event.date_time).toLocaleDateString()}</span>
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-gray-600 italic">No events attended yet.</p>
          )}
        </div>

        <div className="space-y-6">
          <h3 className="text-2xl font-semibold mb-4 text-gray-800">Reviews</h3>
          
          <div>
            <h4 className="text-xl font-medium mb-3 text-gray-700">Applicant Reviews</h4>
            {reviews.filter(review => review.review_type === 'applicant').map(review => (
              <div key={review.id} className="bg-green-50 p-4 rounded-lg shadow-sm mb-3">
                <p className="text-sm text-gray-500 mb-2">
                  Reviewed on {new Date(review.created_at).toLocaleString()}
                </p>
                <p className="text-gray-700">{review.review_text}</p>
              </div>
            ))}
          </div>
          
          <div>
            <h4 className="text-xl font-medium mb-3 text-gray-700">Interview Reviews</h4>
            {reviews.filter(review => review.review_type === 'interview').map(review => (
              <div key={review.id} className="bg-yellow-50 p-4 rounded-lg shadow-sm mb-3">
                <p className="text-sm text-gray-500 mb-2">
                  Reviewed on {new Date(review.created_at).toLocaleString()}
                </p>
                <p className="text-gray-700">{review.review_text}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicantDetailPopup;