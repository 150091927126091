import React, { ChangeEvent } from 'react';

interface RoleFormProps {
  roleName: string;
  setRoleName: (name: string) => void;
  permissions: string[];
  setPermissions: (permissions: string[]) => void;
  handleAddOrUpdateRole: () => void;
}

const RoleForm: React.FC<RoleFormProps> = ({ roleName, setRoleName, permissions, setPermissions, handleAddOrUpdateRole }) => (
  <div>
    <input
      className="mb-2 p-2 border border-gray-300 w-full rounded"
      type="text"
      placeholder="Role Name"
      value={roleName}
      onChange={(e: ChangeEvent<HTMLInputElement>) => setRoleName(e.target.value)}
    />
    <div className="mb-2">
      <h3 className="font-semibold mb-2">General Permissions</h3>
      <div className="flex flex-col space-y-2">
        <div className="py-2 border-b border-gray-300">
          <label className="flex items-center justify-between">
            <span>Branding Access</span>
            <input
              type="checkbox"
              className="toggle-checkbox"
              checked={permissions.includes('Branding Access')}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                if (e.target.checked) {
                  setPermissions([...permissions, 'Branding Access']);
                } else {
                  setPermissions(permissions.filter(p => p !== 'Branding Access'));
                }
              }}
            />
          </label>
          <p className="text-sm text-gray-600">This is for any group related branding that is to be done</p>
        </div>
        <div className="py-2 border-b border-gray-300">
          <label className="flex items-center justify-between">
            <span>Create Invite Access</span>
            <input
              type="checkbox"
              className="toggle-checkbox"
              checked={permissions.includes('Create Invite Access')}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                if (e.target.checked) {
                  setPermissions([...permissions, 'Create Invite Access']);
                } else {
                  setPermissions(permissions.filter(p => p !== 'Create Invite Access'));
                }
              }}
            />
          </label>
          <p className="text-sm text-gray-600">This role is to allow users to create Invites for the organization</p>
        </div>
        <div className="py-2 border-b border-gray-300">
          <label className="flex items-center justify-between">
            <span>Recruitment Access</span>
            <input
              type="checkbox"
              className="toggle-checkbox"
              checked={permissions.includes('Recruitment Access')}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                if (e.target.checked) {
                  setPermissions([...permissions, 'Recruitment Access']);
                } else {
                  setPermissions(permissions.filter(p => p !== 'Recruitment Access'));
                }
              }}
            />
          </label>
          <p className="text-sm text-gray-600">This role is for an administrative access over all recruitment related activities</p>
        </div>
      </div>
      <h3 className="font-semibold mt-4 mb-2">Danger Zone</h3>
      <div className="flex flex-col space-y-2">
        <div className="py-2 border-b border-red-500">
          <label className="flex items-center justify-between">
            <span>Admin Access</span>
            <input
              type="checkbox"
              className="toggle-checkbox"
              checked={permissions.includes('Admin Access')}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                if (e.target.checked) {
                  setPermissions([...permissions, 'Admin Access']);
                } else {
                  setPermissions(permissions.filter(p => p !== 'Admin Access'));
                }
              }}
            />
          </label>
          <p className="text-sm text-gray-600">Access to all actions within the dashboard</p>
        </div>
        <div className="py-2 border-b border-red-500">
          <label className="flex items-center justify-between">
            <span>Billing Access</span>
            <input
              type="checkbox"
              className="toggle-checkbox"
              checked={permissions.includes('Billing Access')}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                if (e.target.checked) {
                  setPermissions([...permissions, 'Billing Access']);
                } else {
                  setPermissions(permissions.filter(p => p !== 'Billing Access'));
                }
              }}
            />
          </label>
          <p className="text-sm text-gray-600">This permission is very crucial and only very trusted individuals should have access to this be very careful</p>
        </div>
      </div>
    </div>
    <button className="mt-4 p-2 bg-blue-500 text-white rounded" onClick={handleAddOrUpdateRole}>
      {roleName ? 'Update Role' : 'Save Role'}
    </button>
  </div>
);

export default RoleForm;
