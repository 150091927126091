import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from './Misc/Sidebar';
import AppHeader from './Dashboard/Components/AppHeader';
import JoinGroupModal from './Misc/JoinGroupModal';

const Layout: React.FC = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const [showJoinGroupModal, setShowJoinGroupModal] = useState<boolean>(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  const closeJoinGroupModal = () => {
    setShowJoinGroupModal(false);
  };

  return (
    <div className="flex h-screen bg-white">
      <div
        className={`lg:block ${
          isSidebarOpen ? 'block' : 'hidden'
        } fixed inset-y-0 left-0 z-50 w-64 transition-transform duration-300 ease-in-out transform ${
          isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
        } lg:relative lg:translate-x-0`}
      >
        <Sidebar
          onClose={closeSidebar}
          onJoinGroup={() => setShowJoinGroupModal(true)}
        />
      </div>

      {isSidebarOpen && (
        <div
          className="fixed inset-0 z-40 bg-black bg-opacity-50 lg:hidden"
          onClick={closeSidebar}
        ></div>
      )}

      <div className="flex flex-col flex-grow overflow-hidden">
        <AppHeader toggleSidebar={toggleSidebar} />
        <div className="flex-grow overflow-auto">
          <Outlet />
        </div>
      </div>

      <JoinGroupModal
        isOpen={showJoinGroupModal}
        onClose={closeJoinGroupModal}
      />
    </div>
  );
};

export default Layout;