import React, { useEffect, useState } from 'react';
import { fetchUserData } from '../../../Contexts/supabaseService';

interface College {
  name: string;
}

interface UserData {
  first_name: string;
  last_name: string;
  colleges: College;
  email: string;
}

const SettingsComponent: React.FC = () => {
  const [userData, setUserData] = useState<UserData | null>(null);
  const userId = localStorage.getItem('userId');

  useEffect(() => {
    if (userId) {
      fetchUserData(userId)
        .then((data) => {
          setUserData(data[0]); 
        })
        .catch(console.error);
    }
  }, [userId]);

  if (!userData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="p-6 bg-gray-100 rounded-lg">
      <h2 className="mb-4 text-2xl text-purple-700">User Settings</h2>
      <form className="space-y-4">
        <div>
          <label className="block text-gray-700">First Name</label>
          <input
            type="text"
            name="first_name"
            value={userData.first_name}
            readOnly
            className="w-full p-2 text-gray-700 bg-gray-200 rounded"
          />
        </div>
        <div>
          <label className="block text-gray-700">Last Name</label>
          <input
            type="text"
            name="last_name"
            value={userData.last_name}
            readOnly
            className="w-full p-2 text-gray-700 bg-gray-200 rounded"
          />
        </div>
        {/* <div>
          <label className="block text-gray-700">College</label>
          <input
            type="text"
            name="college_name"
            value={userData.colleges.name}
            readOnly
            className="w-full p-2 text-gray-700 bg-gray-200 rounded"
          />
        </div> */}
        <div>
          <label className="block text-gray-700">Email</label>
          <input
            type="email"
            name="email"
            value={userData.email}
            readOnly
            className="w-full p-2 text-gray-700 bg-gray-200 rounded"
          />
        </div>
      </form>
    </div>
  );
};

export default SettingsComponent;
