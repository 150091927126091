import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { supabase } from '../../supabase';

interface Poll {
  id: string;
  current_question_index: number;
  ended_at: string | null;
}

const PollVoting: React.FC = () => {
  const { pollId } = useParams<{ pollId: string }>();
  const [hasVoted, setHasVoted] = useState(false);
  const [poll, setPoll] = useState<Poll | null>(null);
  const [error, setError] = useState<string | null>(null);
  const userId = localStorage.getItem('userId');

  useEffect(() => {
    const fetchPoll = async () => {
      if (!pollId) return;

      const { data, error } = await supabase
        .from('polls')
        .select('*')
        .eq('id', pollId)
        .single();

      if (error) {
        console.error('Error fetching poll:', error);
        setError('Failed to load poll. Please try again.');
      } else if (data) {
        setPoll(data as Poll);
        if (data.ended_at) setHasVoted(true);
      }
    };

    fetchPoll();

    const subscription = supabase
      .channel(`poll-${pollId}`)
      .on('postgres_changes', { event: 'UPDATE', schema: 'public', table: 'polls', filter: `id=eq.${pollId}` }, payload => {
        const updatedPoll = payload.new as Poll;
        setPoll(updatedPoll);
        if (updatedPoll.ended_at) {
          setHasVoted(true);
        } else if (updatedPoll.current_question_index !== poll?.current_question_index) {
          setHasVoted(false);
        }
      })
      .subscribe();

    return () => {
      subscription.unsubscribe();
    };
  }, [pollId, poll]);

  useEffect(() => {
    const checkPreviousVote = async () => {
      if (!pollId || !userId || !poll) return;

      const { data, error } = await supabase
        .from('votes')
        .select('*')
        .eq('poll_id', pollId)
        .eq('user_id', userId)
        .eq('question_index', poll.current_question_index)
        .single();

      if (error && error.code !== 'PGRST116') {
        console.error('Error checking previous vote:', error);
      } else if (data) {
        setHasVoted(true);
      } else {
        setHasVoted(false);
      }
    };

    checkPreviousVote();
  }, [pollId, userId, poll]);

  const vote = async (answer: 'yes' | 'no' | 'abstain') => {
    if (!pollId  || hasVoted || !poll) return;

    const { error } = await supabase
      .from('votes')
      .insert({
        poll_id: pollId,
        user_id: userId,
        answer: answer,
        question_index: poll.current_question_index
      });

    if (error) {
      console.error('Error submitting vote:', error);
      setError('Failed to submit vote. Please try again.');
    } else {
      setHasVoted(true);
      setError(null);
    }
  };

  if (error) {
    return (
      <div className="max-w-md p-6 mx-auto mt-10 bg-white rounded-lg shadow-md">
        <p className="text-red-600">{error}</p>
      </div>
    );
  }

  if (!poll) {
    return (
      <div className="max-w-md p-6 mx-auto mt-10 bg-white rounded-lg shadow-md">
        <p>Loading...</p>
      </div>
    );
  }

  if (poll.ended_at) {
    return (
      <div className="max-w-md p-6 mx-auto mt-10 bg-white rounded-lg shadow-md">
        <p className="text-lg text-center text-gray-700">This poll has ended. Thank you for participating.</p>
      </div>
    );
  }

  return (
    <div className="max-w-md p-6 mx-auto mt-10 bg-white rounded-lg shadow-md">
      <h2 className="mb-6 text-2xl font-semibold text-center">Poll Voting</h2>
      <p className="mb-4 text-center">Question {poll.current_question_index + 1}</p>
      {hasVoted ? (
        <div className="text-lg text-center text-gray-700">
          Thank you for voting. Please wait for the next question.
        </div>
      ) : (
        <div className="space-y-4">
          <button
            onClick={() => vote('yes')}
            disabled={hasVoted}
            className="w-full py-2 text-white transition-colors bg-green-600 rounded-md hover:bg-green-700 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Yes
          </button>
          <button
            onClick={() => vote('no')}
            disabled={hasVoted}
            className="w-full py-2 text-white transition-colors bg-red-600 rounded-md hover:bg-red-700 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            No
          </button>
          <button
            onClick={() => vote('abstain')}
            disabled={hasVoted}
            className="w-full py-2 text-white transition-colors bg-gray-600 rounded-md hover:bg-gray-700 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Abstain
          </button>
        </div>
      )}
    </div>
  );
};

export default PollVoting;